import type { MarketplaceOffersType } from "app/shared/bookings.ts";
import type { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters.tsx";
import type { MarketplaceSideFiltersQueryStatesProps } from "routes/_public+/solutions+/rechercher.tsx";
import { MarketplaceSideFilterWrapper } from "../MarketplaceSideFilters.tsx";
import { SideFilterCheckbox } from "./SideFilterCheckbox.tsx";

export const VolumeFilter = ({
  toggleVolume,
  availableVolumes,
  selectedVolumes,
  volumes,
  setSideFilters,
}: {
  toggleVolume: ReturnType<typeof useMarketplaceSideFilters>["toggleVolume"];
  availableVolumes: MarketplaceOffersType["availableVolumes"];
  volumes: MarketplaceOffersType["volumes"];
  selectedVolumes: ReturnType<
    typeof useMarketplaceSideFilters
  >["sideFilters"]["selectedVolumes"];
  setSideFilters: MarketplaceSideFiltersQueryStatesProps["setSideFilters"];
}) => {
  return (
    <MarketplaceSideFilterWrapper title="Volume benne (m3)">
      <div className="flex flex-col gap-4">
        <SideFilterCheckbox
          checked={selectedVolumes.length === 0}
          toggleOption={() => {
            setSideFilters({
              selectedVolumes: [],
            });
          }}
          optionName={`Tous les volumes`}
          optionGcId={"all"}
          isDisabled={selectedVolumes.length === 0}
        />
        {volumes
          .sort((a, b) => b - a)
          .map((option) => {
            const isAvailable = availableVolumes.includes(option);
            return (
              <SideFilterCheckbox
                checked={isAvailable && selectedVolumes.includes(option)}
                toggleOption={(valueId) => toggleVolume(Number(valueId))}
                optionName={`${option}m3`}
                optionGcId={option}
                key={`volume-${option}`}
                isDisabled={!isAvailable}
              />
            );
          })}
      </div>
    </MarketplaceSideFilterWrapper>
  );
};
