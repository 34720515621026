import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/shadcn-ui/accordion.tsx";
import { useLocalStorage } from "hooks/useLocalStorage.tsx";
import {
  Archive,
  BinHalf,
  BrainElectricity,
  CurveArray,
  Cycling,
  Globe,
  GraphUp,
  Group,
  JournalPage,
  MailOut,
  MediaImage,
  MoneySquare,
  Page,
  PasteClipboard,
  QuoteMessageSolid,
  Settings,
  ShareIos,
  SwipeTwoFingersRightGesture,
  Tools,
  Truck,
  User,
  UserBag,
  UserCircle,
  Wash,
} from "iconoir-react";
import { ScrollText } from "lucide-react";
import React from "react";
import { NavLink, useLocation } from "react-router";
import { cn } from "utils/utils.ts";

export type NavigationType = {
  name: string;
  href: string;
  icon: React.ReactNode;
  disabled?: boolean;
  sublinks?: {
    name: string;
    href: string;
    disabled?: boolean;
    icon?: React.ReactNode;
  }[];
  new?: boolean;
  restrictedAccess?: boolean;
  isSection?: boolean;
}[];

type NavbarTask = {
  href: string;
  completed: boolean;
};
export const SidebarItem = ({
  item,
  tasks = [],
  isConfigIncomplete = false,
  hide = false,
}: {
  item: NavigationType["0"];
  tasks?: NavbarTask[];
  isConfigIncomplete?: boolean;
  hide?: boolean;
}) => {
  const location = useLocation();
  const [openedSidebarDropdowns, setOpenedSidebarDropdowns] = useLocalStorage<
    string[]
  >("opened-sidebar-dropdowns", []);

  const isSublinkActive =
    item?.sublinks?.some((sublink) =>
      location.pathname.includes(sublink.href),
    ) ?? false;

  const [isExpanded, setIsExpanded] = React.useState(isSublinkActive);

  // if (hide && isExternalProvider) {
  // 	return (
  // 		<span className='text-xs font-semibold'>
  // 			La navigation est désactivée pour les prestataires externes.
  // 		</span>
  // 	);
  // }
  if (hide) {
    return null;
  }

  if (!item.sublinks) {
    const regex = new RegExp("/^/" + item.href + "/?$/");
    const isLinkActive = regex.test(location.pathname);

    if (item.disabled) {
      return (
        <div className="px-2 text-xs font-normal text-gray-400">
          <div className="flex items-start">
            <span
              className="mr-3 size-6 shrink-0 text-gray-400"
              aria-hidden="true"
            >
              {item.icon}
            </span>
            <span className="whitespace-nowrap">{item.name}</span>
          </div>
        </div>
      );
    }
    if (item.isSection) {
      return (
        <span className="text-midnightblue mb-2 mt-4 pl-2 text-xs font-semibold first:mt-0">
          {item.name}
        </span>
      );
    }
    return (
      <NavLink
        key={item.name}
        to={item.href}
        // prefetch="intent"
        className={`group relative mr-2 text-xs font-normal ${
          isLinkActive
            ? "text-midnightblue"
            : "hover:text-midnightblue text-gray-600"
        } `}
      >
        {({ isActive: isParentNavLinkActive }) => {
          return (
            <>
              <div className="relative px-2">
                <div className="flex items-start">
                  <span
                    className={`${
                      isParentNavLinkActive
                        ? "text-teal"
                        : "text-gray-400 group-hover:text-gray-500"
                    } mr-3 size-6 shrink-0`}
                    aria-hidden="true"
                  >
                    {item.icon}
                  </span>
                  <span className="whitespace-nowrap">{item.name}</span>
                </div>
                {item.new ? (
                  <div className="bg-red-500 animate-pulse absolute inset-y-0 right-0 top-1 size-2 rounded-full"></div>
                ) : null}
                {isConfigIncomplete && item.href.includes("config") ? (
                  <UncompletedTaskIcon className="inset-y-0" />
                ) : null}
              </div>
            </>
          );
        }}
      </NavLink>
    );
  }
  return (
    <Accordion
      type="single"
      className="mt-0 w-full pl-2 text-xs"
      defaultValue={
        isSublinkActive || openedSidebarDropdowns.includes(item.href)
          ? item.href
          : undefined
      }
      collapsible
      onValueChange={(newValue) => {
        setIsExpanded(newValue === item.href);
        setOpenedSidebarDropdowns((old) =>
          old.includes(item.href)
            ? old.filter((o) => o !== item.href)
            : [...old, item.href],
        );
      }}
      key={`${location.pathname}${JSON.stringify(isSublinkActive)}`}
    >
      <AccordionItem className="border-b-0" value={item.href}>
        <AccordionTrigger
          theme="nav"
          className={`relative flex w-full items-center justify-start border-b-0 border-b-transparent py-0 text-start font-normal transition-none hover:border-b-0 ${
            isExpanded
              ? "text-midnightblue"
              : "hover:text-midnightblue text-gray-600"
          }`}
        >
          <span
            className={`${
              isExpanded || isSublinkActive
                ? "text-teal"
                : "text-gray-400 group-hover:text-gray-500"
            } mr-3 shrink-0`}
            aria-hidden="true"
          >
            {item.icon}
          </span>
          <span className="whitespace-nowrap font-semibold">{item.name}</span>

          {isConfigIncomplete ? (
            <UncompletedTaskIcon className="inset-y-0 relative -right-0 ml-auto" />
          ) : null}
        </AccordionTrigger>

        <AccordionContent className="overflow-visible" asChild>
          <div className="ml-2 mt-2 flex flex-col text-xs">
            {item.sublinks?.map((slink) => {
              if (slink.disabled) {
                return (
                  <span
                    key={slink.name}
                    className={`border-l-light-iron text-midnightblue border-l-2 py-2 pl-3 opacity-50`}
                  >
                    {slink.name}
                  </span>
                );
              }
              return (
                <NavLink
                  key={slink.name}
                  to={slink.href}
                  // prefetch="intent"
                  className={({ isActive }) =>
                    `relative flex items-center gap-x-1 border-l-2 py-2 pl-3 ${
                      isActive
                        ? "border-l-teal text-midnightblue"
                        : "border-l-light-iron hover:text-midnightblue text-gray-600"
                    }`
                  }
                >
                  {slink.icon}
                  {slink.name}
                  {/* <JsonStringify data={tasks.filter(t => t.href === slink.href && !t.completed)}/> */}
                  {tasks
                    .filter((t) => t.href === slink.href && !t.completed)
                    .map((task) => (
                      <UncompletedTaskIcon
                        key={task.href}
                        className="inset-y-0 relative ml-auto -right-0 -translate-x-2"
                      />
                    ))}
                </NavLink>
              );
            })}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export const UncompletedTaskIcon = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        `absolute -right-2 my-auto size-2 translate-x-full rounded-full bg-[#FC5151]`,
        className,
      )}
    ></div>
  );
};

export const providerEnabledPlansSideNavigation: NavigationType = [
  {
    name: "Dashboard",
    icon: <GraphUp />,
    href: "/provider/dashboard",
  },

  {
    name: "Ma configuration",
    icon: <Settings />,
    href: "/config",

    sublinks: [
      {
        name: "Mes activités",
        href: "/provider/config/activities",
      },
      {
        name: "Ma zone d'activité",
        href: "/provider/config/activity-zone",
      },
      {
        name: "Mes forfaits",
        href: "/provider/config/plans",
      },
      {
        name: "Traçabilité",
        href: "/provider/config/plans-treatment-pricing",
      },

      {
        name: "Mes documents légaux",
        href: "/provider/config/legal-docs",
      },
      {
        name: "Simulateur de prix",
        href: "/provider/config/simulate-pricing",
      },
    ],
  },
  {
    name: "Comptes prestataires",
    icon: <Group />,
    href: "/provider/manage",

    restrictedAccess: true,
  },
];

export const providerDisabledPlansSideNavigation: NavigationType = [
  {
    name: "Dashboard",
    icon: <GraphUp />,
    href: "/provider/dashboard",
  },

  {
    name: "Ma configuration",
    icon: <Settings />,
    href: "/config",

    sublinks: [
      {
        name: "Mes activités",
        href: "/provider/config/activities",
      },
      {
        name: "Ma zone d'activité",
        href: "/provider/config/activity-zone",
      },
      {
        name: "Mes prix de location",
        href: "/provider/config/rent-pricing",
      },
      {
        name: "Mes prix de transport",
        href: "/provider/config/transport-pricing",
      },
      {
        name: "Mes prix de traitement",
        href: "/provider/config/treatment-pricing",
      },
      {
        name: "Mes documents légaux",
        href: "/provider/config/legal-docs",
      },
      {
        name: "Simulateur de prix",
        href: "/provider/config/simulate-pricing",
      },
    ],
  },
  {
    name: "Comptes prestataires",
    icon: <Group />,
    href: "/provider/manage",

    restrictedAccess: true,
  },
];

export const customerSideNavigation: NavigationType = [
  {
    name: "Dashboard",
    icon: <GraphUp />,
    href: "/dashboard",
  },
  {
    name: "Factures",
    icon: <Page />,
    href: "/invoices",
  },
  {
    name: "Bons de pesée",
    icon: <JournalPage />,
    href: "/weight-tickets",
  },
  {
    name: "Devis",
    icon: <Archive />,
    href: "/quotes",
  },
];

export const adminSideNavigation = (hasNewVersion: boolean): NavigationType => [
  {
    name: "Changelog",
    icon: <ScrollText />,
    href: "/admin/changelog",
    new: hasNewVersion,
  },
  {
    name: "Général",
    icon: null,
    href: "/admin/dashboard/general",

    isSection: true,
    sublinks: [
      {
        name: "Carte",
        icon: <Globe />,
        href: "/admin/dashboard/general",
      },
      {
        name: "Historique",
        icon: <Globe />,
        href: "/admin/dashboard/search-history",
      },
      {
        name: "Adresses",
        icon: <Globe />,
        href: "/admin/dashboard/cached-addresses",
      },
      {
        name: "Outils lié aux adresses",
        icon: <Globe />,
        href: "/admin/dashboard/address-tools",
      },
      {
        name: "Adresses erronées",
        icon: <Globe />,
        href: "/admin/dashboard/errored-addresses",
      },
    ],
  },

  {
    name: "CRM Leads",
    icon: <GraphUp />,
    href: "/admin/leads",
  },
  // {
  // 	name: 'CRM',
  // 	icon: <GraphUp />,
  // 	href: '/admin',

  // 	sublinks: [
  // 		{
  // 			href: '/admin/leads',
  // 			name: 'Opportunités',
  // 		},
  // 	],
  // 	isSection: true,
  // },

  {
    name: "Utilisateurs",
    icon: <User />,
    href: "/admin/users/accounts",

    isSection: true,
    sublinks: [
      {
        name: "Prestataires",
        icon: <User />,
        href: "/admin/users/providers",
      },
      {
        name: "Clients",
        icon: <UserBag />,
        href: "/admin/users/customers",
      },
      {
        name: "Utilisateurs",
        icon: <UserCircle />,
        href: "/admin/users/accounts",
      },
      {
        name: "Équipes",
        icon: <UserCircle />,
        href: "/admin/users/teams",
      },
      {
        name: "Administrateurs",
        icon: <UserCircle />,
        href: "/admin/users/administrators",
      },

      {
        name: "Documents légaux (prestataires)",
        icon: <User />,
        href: "/admin/users/provider-legal-docs",
      },
      {
        name: "Sessions actives",
        icon: <UserCircle />,
        href: "/admin/sessions",
      },
      // {
      // 	name: 'Prestataires externes',
      // 	icon: <UserScan />,
      // 	href: '/admin/users/external-providers',
      // },
    ],
  },

  {
    name: "Prestations",
    icon: <QuoteMessageSolid />,
    href: "/admin/bookings",

    isSection: true,
    sublinks: [
      {
        name: "Bookings",
        icon: <PasteClipboard />,
        href: "/admin/bookings",
      },
      {
        name: "Bookings récurrents",
        icon: <PasteClipboard />,
        href: "/admin/recurring-bookings",
      },
      {
        name: "Opérations",
        icon: <PasteClipboard />,
        href: "/admin/operations",
      },
      {
        name: "Devis internes",
        icon: <MediaImage />,
        href: "/admin/generated-quotes",
      },

      {
        name: "Devis externes",
        icon: <QuoteMessageSolid />,
        href: "/admin/quotes",
      },
      {
        name: "Registre",
        icon: <SwipeTwoFingersRightGesture />,
        href: "/admin/bookings-registry",
      },
      {
        name: "Rentabilité",
        icon: <MoneySquare />,
        href: "/admin/rentability",
      },
    ],
  },

  {
    name: "Configuration",
    icon: <Settings />,
    href: "/admin/waste-management",

    isSection: true,
    sublinks: [
      {
        name: "Déchets",
        icon: <BinHalf />,
        href: "/admin/waste-management",
      },
      {
        name: "Services",
        icon: <Truck />,
        href: "/admin/services",
      },
      {
        name: "Équipements",
        icon: <Truck />,
        href: "/admin/equipment-macro-types",
      },

      {
        name: "Catégories de déchets",
        icon: <Wash />,
        href: "/admin/waste-category-management",
      },
      {
        name: "Famille de déchets",
        icon: <CurveArray />,
        href: "/admin/waste-compatibility-management",
      },

      {
        name: "Types de traitement",
        icon: <Cycling />,
        href: "/admin/treatment-type-management",
      },
      {
        name: "Zones d'activités",
        icon: <Globe />,
        href: "/admin/activity-zones",
      },
    ],
  },

  {
    name: "SEO",
    icon: <ShareIos />,
    href: "/admin/media",

    isSection: true,
    sublinks: [
      {
        name: "Média",
        icon: <MediaImage />,
        href: "/admin/media",
      },
    ],
  },

  {
    name: "Autre",
    icon: <Tools />,
    href: "/admin/tools",

    isSection: true,
    sublinks: [
      {
        name: "Marge et TVA",
        icon: <MoneySquare />,
        href: "/admin/booking-fees",
      },

      {
        name: "Outils",
        icon: <Tools />,
        href: "/admin/tools",
      },
      {
        name: "Gérer les pages locales",
        icon: <BrainElectricity />,
        href: "/admin/local-pages",
      },
      {
        name: "Désactiver un département",
        icon: <Tools />,
        href: "/admin/disabled-departments",
      },
      {
        name: "Statuts de bookings",
        icon: <PasteClipboard />,
        href: "/admin/booking-statuses",
      },
    ],
  },

  {
    name: "Emails envoyés (leads)",
    icon: <MailOut />,
    href: "/admin/emails",
  },
  {
    name: "Logs",
    icon: <User />,
    href: "/admin/user-actions",
  },
];
