import type { MarketplaceOffersType } from "app/shared/bookings.ts";
import type { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters.tsx";
import { MarketplaceSideFilterWrapper } from "../MarketplaceSideFilters.tsx";
import { SideFilterCheckbox } from "./SideFilterCheckbox.tsx";

export const EquipmentTypeFilter = ({
  title,
  availableEquipmentTypes,
  equipmentTypes,
  selectedEquipmentTypeIds,
  setSideFilters,
  toggleEquipmentType,
}: {
  title: string;
  equipmentTypes: MarketplaceOffersType["equipmentTypes"];
  availableEquipmentTypes: MarketplaceOffersType["availableEquipmentTypes"];
  selectedEquipmentTypeIds: ReturnType<
    typeof useMarketplaceSideFilters
  >["sideFilters"]["selectedEquipmentTypeIds"];
  setSideFilters: ReturnType<
    typeof useMarketplaceSideFilters
  >["setSideFilters"];
  toggleEquipmentType: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleEquipmentType"];
}) => {
  return (
    <MarketplaceSideFilterWrapper title={title}>
      <div className="flex flex-col gap-4">
        <SideFilterCheckbox
          optionGcId={"all"}
          checked={selectedEquipmentTypeIds.length === 0}
          toggleOption={() =>
            setSideFilters({
              selectedEquipmentTypeIds: [],
            })
          }
          optionName="Tous les équipements"
          isDisabled={selectedEquipmentTypeIds.length === 0}
        />
        {equipmentTypes.map((option) => {
          const isAvailable = availableEquipmentTypes.some(
            (aet) => aet.id === option.id,
          );
          return (
            <SideFilterCheckbox
              checked={
                isAvailable &&
                selectedEquipmentTypeIds.some((so) => so === option.id)
              }
              toggleOption={(valueId) => toggleEquipmentType(Number(valueId))}
              optionName={option.name}
              optionGcId={option.id}
              key={`equipment-type-${option.id}`}
              isDisabled={!isAvailable}
            />
          );
        })}
      </div>
    </MarketplaceSideFilterWrapper>
  );
};
