import { useEffect } from "react";
import { Link, useFetcher } from "react-router";
import type { ToastType } from "utils/toast.server.ts";
import { buttonVariants } from "./button.tsx";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./toast.tsx";
import { useToast } from "./use-toast.ts";

export function Toaster({
  toastItem,
  hidden = false,
}: { toastItem: ToastType | null; hidden?: boolean }) {
  const { toasts, toast } = useToast();

  useEffect(() => {
    if (!toastItem) return;

    // For version updates, check if we already have one showing
    if (toastItem.id === "new-version-toast") {
      const hasUpdateToast = toasts.some((t) => t.id === "new-version-toast");
      if (hasUpdateToast) return;
    }

    toast({
      title: toastItem?.title,
      description: toastItem.description,
      variant: toastItem.type,
      // childrenOfToast: toastItem.childrenOfToast,
    });
  }, [toastItem, toast]);

  if (hidden) return null;

  const fetcher = useFetcher();
  return (
    <ToastProvider>
      {toasts.map(
        ({ id, title, description, action, childrenOfToast, ...props }) => (
          <Toast key={id} {...props} className="my-2">
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
              {childrenOfToast ? childrenOfToast : null}
              {title === "Nouvelle mise à jour !" ? (
                <div className="mt-1 flex items-center justify-between gap-2">
                  <Link
                    to="/admin/changelog"
                    className="text-xs text-teal hover:underline"
                  >
                    Voir les changements
                  </Link>
                  <fetcher.Form method="POST" action="/admin/changelog">
                    <button
                      className={buttonVariants({
                        variant: "primary",
                        size: "xs",
                        className: "p-1",
                      })}
                      type="submit"
                    >
                      J'ai compris 👍
                    </button>
                  </fetcher.Form>
                </div>
              ) : null}
            </div>
            {action}
            <ToastClose />
          </Toast>
        ),
      )}
      <ToastViewport />
    </ToastProvider>
  );
}
