import { useLocation } from "react-router";

export function useLogoutUrl({ loginPath }: { loginPath: string }) {
  const location = useLocation();
  const isLoginPage = location.pathname.startsWith(loginPath);

  if (isLoginPage) {
    return { redirectTo: "" };
  }

  return { redirectTo: location.pathname + location.search };
}
