import {
  getFieldsetProps,
  getFormProps,
  getInputProps,
  getTextareaProps,
  useForm,
} from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { useEnv, useWasteTypes } from "app/root";
import type { getBookingPrice } from "app/server/public/transactional-page.server";
import type { MarketplaceOfferType } from "app/shared/bookings";
import { GenerateBookingSchema } from "app/shared/schemas/customer/bookings.schema";
import { LoginSchema } from "app/shared/schemas/login.schema";
import { UserRoles } from "app/shared/schemas/user-roles.enum";
import { GenerateLeadQuoteSchema } from "app/shared/schemas/user.schema";
import { MarketplaceSideFilters } from "components/Bookings/MarketplaceSideFilters";
import { HomeArticle } from "components/Homepage/HomeArticle";
import { HomeSection } from "components/Homepage/HomeSection.tsx";
import { ArrowTriangle } from "components/Icons/ArrowTriangle";
import { RefreshCircle } from "components/Icons/RefreshCircle";
import AppTooltip from "components/Interface/App/AppTooltip";
import { GeneralCombobox } from "components/Interface/Forms/Inputs/GeneralCombobox";
import { HiddenInputsArray } from "components/Interface/Forms/Inputs/HiddenInputsArray";
import {
  CheckboxField,
  ErrorList,
  Field,
  PhoneField,
  TextareaField,
} from "components/Interface/Forms/forms";
import { LoadingSpinner } from "components/Interface/LoadingSpinner";
import { DatepickerInput } from "components/Interface/TransactionalPages/DatepickerInput";
import { ModalWrapper } from "components/Layout/Global/ModalWrapper";
import { TwoSidedFormInput } from "components/Layout/Global/TwoSidedFormInput";
import { MainPageWrapper } from "components/Layout/MainPageWrapper.tsx";
import { OptimizedImage } from "components/OptimizedImage";
import { Markdown } from "components/Sections/Markdown";
import { CardOffer, CheckedItem } from "components/UI/CardOffer";
import { DoubleSelect } from "components/UI/DoubleSelect";
import { Headline } from "components/UI/Headline";
import { Label } from "components/UI/Label";
import { MarketplacePanel } from "components/UI/MarketplacePanel";
import { Paragraph, paragraphVariants } from "components/UI/Paragraph";
import { PaymentMethodCard } from "components/UI/PaymentMethodCard";
import { PaymentMethodSepa } from "components/UI/PaymentMethodSepa";
import { SimpleSelect } from "components/UI/SimpleSelect";
import { SwitchBar } from "components/UI/SwitchBar";
import { Tag, TagsVariants } from "components/UI/Tag";
import { Skeleton } from "components/UI/skeleton";
import { ClintButton } from "components/shadcn-ui/button";
import { useIsSubmitting } from "hooks/useIsSubmitting";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useMarketplaceSearchBar } from "hooks/useMarketplaceSearchBar";
import { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters";
import { useTailwindScreenSizes } from "hooks/useTailwindScreenSizes";
import { useOptionalUser } from "hooks/useUser";
import {
  ArrowLeft,
  Check,
  Clock,
  InfoCircle,
  Mail,
  Phone,
  RefreshDouble,
  Sparks,
  Trash,
  Xmark,
} from "iconoir-react";
import type { UrlKeys } from "node_modules/nuqs/dist/_tsup-dts-rollup";
import {
  type SetValues,
  type Values,
  parseAsArrayOf,
  parseAsBoolean,
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
} from "nuqs";
import { useEffect, useMemo, useState } from "react";
import {
  type ActionFunctionArgs,
  Form,
  Link,
  type LoaderFunctionArgs,
  type MetaFunction,
  data,
  redirect,
  useActionData,
  useFetcher,
  useLocation,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router";
import { generateCanonicalUrl } from "routes/redirects.server";
import { AddressCombobox } from "routes/resources+/get-address";
import {
  checkIfUserExists,
  createAndSendCustomerQuote,
} from "server/api/auth/auth.server";
import {
  getCompanyBySiret,
  verifyVatNumberValidity,
} from "server/api/insee/insee.server";
import {
  authenticateUser,
  checkUserExists,
  getUserSession,
  logout,
} from "server/auth.server";
import { createBookingFrontend } from "server/public/bookings.server";
import { getUser } from "server/session.server";
import {
  getMarketplaceNewAccess,
  getMarketplaceOptionalOptions,
  type getOfferItemDescription,
} from "server/utils.server";
import { formatPriceWithCurrency } from "utils/format-price";
import { generateMetatags } from "utils/generateMetatags.ts";
import { createToastHeaders } from "utils/toast.server";
import { cn, formatSiret, removeStringSpaces } from "utils/utils";
import { z } from "zod";
import { usePublicLayout } from "../_layout";
import equipmentOfferImage from "./equipment-offer.png";
import equipmentImage from "./equipment.png";
import goodcollectVector from "./goodcollect-vector.png";
import { BookingPriceRecap } from "./payer.$bookingId";

export const marketplaceSearchParams = {
  step: parseAsStringEnum(["1", "2", "3", "4"]).withDefault("1").withOptions({
    clearOnDefault: false,
  }),
  isProfessional: parseAsStringEnum(["1", "0"]).withDefault("1").withOptions({
    clearOnDefault: false,
    shallow: false,
  }),
  endDate: parseAsString.withDefault("").withOptions({
    clearOnDefault: false,
    shallow: false,
  }),
  startDate: parseAsString.withDefault("").withOptions({
    clearOnDefault: false,
    shallow: false,
  }),
  service: parseAsInteger.withDefault(1).withOptions({
    clearOnDefault: false,
    shallow: false,
  }),
  address: parseAsString.withDefault("").withOptions({
    clearOnDefault: false,
    shallow: false,
  }),
  placeId: parseAsString.withDefault("").withOptions({
    clearOnDefault: false,
    shallow: false,
  }),
  waste: parseAsInteger,
  volume: parseAsString.withDefault("1"),
  isRecurring: parseAsStringEnum(["1", "0"]).withDefault("0").withOptions({
    shallow: false,
  }),
  immediatePickup: parseAsStringEnum(["1", "0"]).withDefault("0").withOptions({
    shallow: false,
  }),
  plans: parseAsStringEnum(["1", "0"]).withDefault("0").withOptions({
    shallow: false,
  }),
  userId: parseAsString.withDefault("").withOptions({
    clearOnDefault: false,
  }),
  equipmentPriceRuleId: parseAsInteger.withOptions({
    clearOnDefault: true,
  }),
  providerId: parseAsString.withDefault(""),
  stripeQuoteId: parseAsString.withDefault(""),
  zohoEstimateId: parseAsString.withDefault(""),
  simulate: parseAsStringEnum(["1", "0"]).withDefault("0"),
  treatmentType: parseAsString.withDefault(""),
  page: parseAsString.withDefault("1"),
  "show-quote": parseAsBoolean.withDefault(false),
  "search-modal": parseAsBoolean.withDefault(false),
};

export type MarketplaceSearchParamsKeyMap = typeof marketplaceSearchParams;

export type MarketplaceSearchParamsQueryStatesProps = {
  selectedFilters: Values<MarketplaceSearchParamsKeyMap>;
  setSelectedFilters: SetValues<MarketplaceSearchParamsKeyMap>;
};

export const marketplaceSideFiltersSearchParams = {
  selectedTreatmentTypeIds: parseAsArrayOf(parseAsInteger)
    .withDefault([])
    .withOptions({
      clearOnDefault: false,
    }),
  selectedEquipmentTypeIds: parseAsArrayOf(parseAsInteger).withDefault([]),
  selectedVolumes: parseAsArrayOf(parseAsInteger).withDefault([]),
  selectedProviderIds: parseAsArrayOf(parseAsString).withDefault([]),
  showHiddenOffers: parseAsBoolean.withDefault(false),
};

export type MarketplaceSideFiltersKeyMap =
  typeof marketplaceSideFiltersSearchParams;

export type MarketplaceSideFiltersQueryStatesProps = {
  sideFilters: Values<MarketplaceSideFiltersKeyMap>;
  setSideFilters: SetValues<MarketplaceSideFiltersKeyMap>;
};

export type MarketplaceSideFiltersUrlKeys = UrlKeys<
  typeof marketplaceSideFiltersSearchParams
>;

export const marketplaceSideFiltersUrlKeys: MarketplaceSideFiltersUrlKeys = {
  selectedTreatmentTypeIds: "treatment",
  selectedEquipmentTypeIds: "equipment",
  selectedVolumes: "volumes",
  selectedProviderIds: "providers",
  showHiddenOffers: "hidden",
};

const dynamicLinks = ({
  data,
}: {
  data: { href: string };
}): ReturnType<MetaFunction> => [
  {
    rel: "canonical",
    href: data.href,
  },
];

export const handle = {
  // 👇This is a function that will be called client side with a 'href' argument.
  dynamicLinks,
};

// Loader moved in file _layout.tsx
export const loader = ({ request }: LoaderFunctionArgs) => {
  return data({
    href: generateCanonicalUrl({ request }),
  });
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const { shouldBeRedirectedToNearestAvailableStep, searchUrl, options } =
    getMarketplaceNewAccess({
      request,
    });

  if (shouldBeRedirectedToNearestAvailableStep) {
    throw redirect(searchUrl);
  }

  if (options === null) {
    throw redirect("/");
  }

  const formData = await request.formData();

  // const { GOODCOLLECT_ENV } = serverEnv
  const user = await getUser({
    request,
  });
  const submission = await parseWithZod(formData, {
    async: true,
    schema: z
      .union([
        GenerateLeadQuoteSchema,
        GenerateBookingSchema,
        LoginSchema,
        z.object({
          action: z.literal("logout"),
        }),
      ])
      .superRefine(async (data, ctx) => {
        if (data.action === "logout") {
          return true;
        }
        if (data.action === "login") {
          const doesUserExist = await checkIfUserExists({
            withPassword: true,
            email: data.email,
            password: data.password,
          });

          if (doesUserExist.error) {
            for (const message of doesUserExist.message) {
              ctx.addIssue({
                code: "custom",
                path: ["email"],
                message: message,
              });
              return false;
            }
            return false;
          }
          if (doesUserExist.role !== UserRoles.CUSTOMER) {
            ctx.addIssue({
              code: "custom",
              path: ["email"],
              message: "Seuls les comptes client peuvent réserver.",
            });
          }
          return true;
        }
        if (data.action === "booking") {
          if (!data.hasAcceptedTerms) {
            ctx.addIssue({
              code: "custom",
              path: ["hasAcceptedTerms"],
              message:
                "Vous devez accepter les conditions générales pour continuer.",
            });
            return false;
          }
          if (user && user.role !== UserRoles.CUSTOMER) {
            ctx.addIssue({
              code: "custom",
              message: "Seuls les comptes clients peuvent réserver.",
            });
            return false;
          }

          if (data.status === "logged-in" && !user) {
            ctx.addIssue({
              code: "custom",
              message:
                "Vous devez être connecté ou soumettre les informations d'un compte client pour continuer.",
            });
          }
          if (data.status === "logged-out" && data.customer) {
            const doesUserExist = await checkUserExists({
              email: data.customer.email,
            });

            if (!doesUserExist.isProspect && doesUserExist.error === false) {
              for (const message of doesUserExist.message) {
                ctx.addIssue({
                  code: "custom",
                  path: ["customer", "email"],
                  message: message,
                });
              }
            }

            if (
              doesUserExist.error === false &&
              doesUserExist.role !== UserRoles.CUSTOMER
            ) {
              ctx.addIssue({
                code: "custom",
                path: ["customer", "email"],
                message: "Seuls les comptes client peuvent réserver.",
              });
            }

            if (data.customer.customerType === "professionnal") {
              const siret = removeStringSpaces(data.customer.siret);
              const inseeFeedback = await getCompanyBySiret({
                siret,
              });

              const { error, message } = inseeFeedback;

              if (error) {
                ctx.addIssue({
                  code: "custom",
                  path: ["customer.siret"],
                  message: message.join(", "),
                });
                return false;
              }

              if (data?.customer.tvaNumber) {
                const vatFeedback = await verifyVatNumberValidity({
                  vatNumber: data.customer.tvaNumber,
                });

                if (!vatFeedback.valid) {
                  ctx.addIssue({
                    code: "custom",
                    path: ["customer.tvaNumber"],
                    message: "Le numéro de TVA est invalide.",
                  });
                  return false;
                }
              }
            }
            // if (!data.customer?.captcha) {
            //   ctx.addIssue({
            //     code: "custom",
            //     path: ["captcha"],
            //     message: "Le captcha est requis.",
            //   });
            // }

            // // Don't test captcha in test environment. :)
            // if (GOODCOLLECT_ENV !== "test") {
            //   const { success } = await validateUserCaptcha({
            //     token: data.customer?.captcha ?? "",
            //   });

            //   if (!success) {
            //     ctx.addIssue({
            //       code: "custom",
            //       path: ["captcha"],
            //       message: "Le captcha vous a identifié en tant que robot.",
            //     });
            //   }
            // }
          }
          return false;
        }

        if (data.action === "quote") {
          if (data.placeId !== options.placeId) {
            ctx.addIssue({
              code: "custom",
              path: ["placeId"],
              message: "Le lieu de livraison n'est pas valide.",
            });
          }
        }

        if (data.action === "quote" && data.customerType === "professionnal") {
          const customerData = data;
          const siret = removeStringSpaces(customerData.siret);
          const inseeFeedback = await getCompanyBySiret({
            siret,
          });

          const { error, message } = inseeFeedback;

          if (error) {
            ctx.addIssue({
              code: "custom",
              path: ["siret"],
              message: message.join(", "),
            });
          }

          if (customerData?.tvaNumber) {
            const vatFeedback = await verifyVatNumberValidity({
              vatNumber: customerData.tvaNumber,
            });

            if (!vatFeedback.valid) {
              ctx.addIssue({
                code: "custom",
                path: ["tvaNumber"],
                message: "Le numéro de TVA est invalide.",
              });
            }
          }
        }
      })
      .transform((data) => {
        if (data.action === "login" || data.action === "logout")
          return { ...data };
        if (data.action === "quote") {
          return {
            ...data,
            ...(data.customerType === "professionnal" &&
              data.siret && {
                siret: removeStringSpaces(data.siret),
              }),
            email: data.email.toLowerCase(),
          };
        }
        if (data.action === "booking") {
          return {
            ...data,
            ...(data.status === "logged-out" && {
              customer: {
                ...data.customer,
                email: data.customer.email.toLowerCase(),
                ...(data.customer.customerType === "professionnal" &&
                  data.customer.siret && {
                    siret: removeStringSpaces(data.customer.siret),
                  }),
              },
            }),
          };
        }
        return data;
      }),
  });

  if (submission.status !== "success") {
    return data(
      {
        result: submission.reply(),
      },
      {
        status: 400,
      },
    );
  }

  if (options.step !== "3" && options.step !== "4") {
    return data({
      result: submission.reply({
        formErrors: [
          "Une erreur s'est produite lors de la soumission du formulaire.",
        ],
      }),
    });
  }

  const submissionData = submission.value;

  switch (submissionData.action) {
    case "logout": {
      const url = new URL(request.url);
      return await logout({ redirectTo: url.toString(), request });
    }
    case "login": {
      return await authenticateUser({
        data: submissionData,
        request,
        customerAuth: true,
      });
    }

    case "quote": {
      const { startDate, endDate } = getMarketplaceOptionalOptions({
        request,
        defaultStartDate: submissionData.startDate,
        defaultEndDate: submissionData.endDate || "",
        isAdmin: Boolean(user?.authOptions.isAdmin),
      });

      if (!submissionData.billingPlaceId) {
        submissionData.billingPlaceId = submissionData.placeId;
        submissionData.billingAddress = submissionData.address;
      }

      const feedback = await createAndSendCustomerQuote({
        customerComment: undefined,
        paramsData: { ...options, startDate, endDate },
        purchaseOrder: null,
        userData: submissionData,
        options: {
          leadId: null,
          shouldCreateCustomer: true,
          shouldCreateLead: true,
          existingBookingId: null,
          usePlanBilling: options.plans === "1",
        },
        contextPayload: await getUserSession({ request }),
      });

      return data(
        {
          message: feedback.message,
          error: feedback.error,
          result: submission.reply({
            resetForm: true,
          }),
        } as const,
        {
          headers: await createToastHeaders({
            description: feedback.message.join(", "),
            type: feedback.error ? "error" : "success",
          }),
        },
      );
    }
    case "booking": {
      const { startDate, endDate, stripeQuoteId } =
        getMarketplaceOptionalOptions({
          request,
          defaultStartDate: submissionData.startDate,
          defaultEndDate: submissionData.endDate || "",
          isAdmin:
            Boolean(user?.authOptions.isAdmin) ||
            Boolean(submissionData.startDate),
        });

      if (user?.role === UserRoles.CUSTOMER) {
        if (options.isProfessional === "0" && user.isProfessionalCustomer) {
          options.isProfessional = "1";
        }
        if (options.isProfessional === "1" && !user.isProfessionalCustomer) {
          options.isProfessional = "0";
        }
      }

      return await createBookingFrontend({
        request,
        additionalInfo: submissionData,
        params: {
          ...options,
          endDate,
          startDate,
          simulate: "0",
          stripeQuoteId,
        },
      });
    }
    default: {
    }
  }
};

export const meta: MetaFunction<typeof loader> = ({ location }) => {
  return generateMetatags({
    noIndex: true,
    pageUrl: location.pathname,
  });
};

export default function LocationDeBennesPage() {
  const { selectedFilters, setSelectedFilters } = useMarketplaceSearchBar();
  const { user, offersData, offer } = usePublicLayout() || {};

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (selectedFilters.step !== "4") {
      window.localStorage.removeItem("book-details");
    }
  }, [selectedFilters.step]);

  return (
    <MainPageWrapper color="white" marginTop="none">
      <HomeSection
        color={"gray100"}
        verticalPadding="default"
        horizontalPadding="none"
        topPadding="none"
        key={selectedFilters.step}
        className="pt-0 lg:pt-0 xl:pt-0"
      >
        <HomeArticle
          className="gap-0"
          horizontalPadding={"none"}
          verticalPadding={"none"}
          maxWidth={"1440"}
        >
          <OfferQuotePanel
            title="Demander un devis"
            open={selectedFilters["show-quote"]}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          {/* <OffersListSectionLoading /> */}
          {/* <JsonStringify data={offersData} /> */}
          {/* <OffersListSectionLoading /> */}
          {selectedFilters.step === "1" ? (
            <WasteSection
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          ) : null}
          {selectedFilters.step === "2" ? (
            offersData === null ? (
              <OffersListSectionLoading />
            ) : (
              <OffersListSection
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            )
          ) : null}
          {selectedFilters.step === "3" ? (
            offer === null ? (
              <OfferDetailSectionLoading />
            ) : (
              <OfferDetailSection
                setSelectedFilters={setSelectedFilters}
                selectedFilters={selectedFilters}
              />
            )
          ) : null}
          {selectedFilters.step === "4" ? (
            <OfferBookingSection
              key={JSON.stringify(user)}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
            />
          ) : null}

          <FiltersModal
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </HomeArticle>
      </HomeSection>
    </MainPageWrapper>
  );
}

const WasteSection = ({
  selectedFilters,
  setSelectedFilters,
}: {
  selectedFilters: MarketplaceSearchParamsQueryStatesProps["selectedFilters"];
  setSelectedFilters: MarketplaceSearchParamsQueryStatesProps["setSelectedFilters"];
}) => {
  const transition = useNavigation();
  const isLoading = transition.state === "loading";
  const wasteTypes = useWasteTypes();
  const prioritizedIds = [5, 16, 15, 35, 9];

  const orderedWasteTypes: typeof wasteTypes = [];
  for (const id of prioritizedIds) {
    const foundWasteType = wasteTypes.find((type) => type.gcId === id);
    if (foundWasteType) {
      orderedWasteTypes.push(foundWasteType);
    }
  }
  for (const wasteType of wasteTypes.filter(
    (type) => !prioritizedIds.includes(type.gcId),
  )) {
    orderedWasteTypes.push(wasteType);
  }

  // wasteTypes, but add the ID 5 first, id 16 second, id 15 third, id 35 fourth and id 9 fifth. other id order is unchanged

  const MobileContinueButton = () => {
    return (
      <div className="fixed bottom-0 z-20 flex w-screen items-center justify-center border-t border-gray-300 bg-gray-100 p-4 lg:hidden">
        <ClintButton
          as="Button"
          iconPosition="right"
          variant="primary"
          size="lg"
          isLoading={isLoading}
          disabled={isLoading || !selectedFilters.waste}
          className="w-full"
          type="button"
          onClick={() => {
            setSelectedFilters(
              {
                step: "2",
              },
              {
                shallow: false,
                history: "push",
              },
            );
          }}
        >
          Continuer
        </ClintButton>
      </div>
    );
  };

  return (
    <div className="relative flex h-full w-full flex-col-reverse bg-gray-100 lg:min-h-screen lg:flex-row">
      <MobileContinueButton />
      <NeedHelpSideSection />
      <div className="flex w-full flex-col">
        <div className="flex h-[88px] w-full items-center justify-between gap-4 border border-l-0 border-gray-200 bg-white px-10">
          <div className="flex items-center gap-4">
            <Tag
              type="Square"
              variant="Light"
              className="flex aspect-square items-center justify-center lg:hidden"
            >
              <ArrowLeft className="size-4" aria-hidden="true" />
            </Tag>
            <Headline size={"h6"} as="div">
              Que souhaitez-vous jeter ?
            </Headline>
          </div>
          <ClintButton
            as="Button"
            iconPosition="right"
            variant="primary"
            size="lg"
            isLoading={isLoading}
            disabled={isLoading || !selectedFilters.waste}
            className="hidden w-full max-w-[190px] lg:flex"
            type="button"
            onClick={() => {
              setSelectedFilters(
                {
                  step: "2",
                },
                {
                  shallow: false,
                  history: "push",
                },
              );
            }}
          >
            Continuer
          </ClintButton>
        </div>
        <div className="w-full p-4">
          <div className="lg:rounded-[8px] lg:bg-white lg:p-6">
            <SimpleSelect
              className="mx-auto max-w-[600px]"
              onChange={(selectedWaste) => {
                setSelectedFilters({
                  waste: Number(selectedWaste),
                });
              }}
              name="waste"
              options={orderedWasteTypes.map((wt) => ({
                id: `waste-${wt.gcId}`,
                name: wt.name,
                value: wt.gcId.toString(),
                subtitle: wt.description,
                title: wt.name,
                imageUrl: wt.image?.fileUrl || "",
              }))}
              defaultOption={selectedFilters.waste?.toString() || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const OffersListSection = ({
  selectedFilters,
  setSelectedFilters,
}: {
  selectedFilters: MarketplaceSearchParamsQueryStatesProps["selectedFilters"];
  setSelectedFilters: MarketplaceSearchParamsQueryStatesProps["setSelectedFilters"];
}) => {
  const { user, offersData } = usePublicLayout() || {};
  const wasteTypes = useWasteTypes();
  const { isLargeScreen } = useTailwindScreenSizes({});

  const {
    filteredOtherOffers,
    setSideFilters,
    toggleVolume,
    sideFilters,
    toggleTreatmentType,
    toggleEquipmentType,
    filteredBestPlanOffer,
    filteredBestTonOffer,
    filteredHiddenOffersCount,
    toggleProvider,
  } = useMarketplaceSideFilters(offersData);

  const onBookClick = (offer: MarketplaceOfferType) => {
    setSelectedFilters(
      {
        step: "3",
        providerId: offer.providerId,
        equipmentPriceRuleId: offer.equipmentPriceRuleId,
        waste: offer.waste.gcId,
        plans: offer.isPlan ? "1" : "0",
      },
      {
        shallow: false,
        history: "push",
      },
    );
  };

  const isProfessional = selectedFilters.isProfessional === "1";
  // type the openOfferDetails state , open:boolean and type : "ton" | "plan"
  const [openOfferDetails, setOpenOfferDetails] = useState<{
    open: boolean;
    type: "ton" | "plan";
  }>({
    open: false,
    type: "ton",
  });

  const hasOffers = offersData.allOffersCount > 0;
  const navigate = useNavigate();

  const bestOfferTonDisplay = useMemo(() => {
    if (filteredBestTonOffer) {
      return filteredBestTonOffer;
    }
    // Si filteredBestTonOffer est null, trouvez la meilleure offre parmi filteredOtherOffers
    return filteredOtherOffers.filter((offer) => !offer.isPlan).length > 0
      ? filteredOtherOffers
          .filter((offer) => !offer.isPlan)
          .reduce((best, current) =>
            current.priceTTC < best.priceTTC ? current : best,
          )
      : null;
  }, [filteredBestTonOffer, filteredOtherOffers]);
  const bestOfferPlanDisplay = useMemo(() => {
    if (filteredBestPlanOffer) {
      return filteredBestPlanOffer;
    }
    // Si filteredBestTonOffer est null, trouvez la meilleure offre parmi filteredOtherOffers qui est un plan
    if (filteredOtherOffers.filter((offer) => offer.isPlan).length > 0) {
      return filteredOtherOffers
        .filter((offer) => offer.isPlan)
        .reduce((best, current) =>
          current.priceTTC < best.priceTTC ? current : best,
        );
    }
    return null;
  }, [filteredBestPlanOffer, filteredOtherOffers]);

  if (!offersData) return null;

  return (
    <div className="relative mx-auto flex h-full w-full max-w-[1440px] flex-col bg-gray-100 lg:min-h-screen lg:flex-row">
      {isProfessional || user?.authOptions.isAdmin ? (
        <MarketplaceSideFilters
          setSideFilters={setSideFilters}
          availableProviders={offersData.availableProviders}
          className="hidden lg:flex"
          toggleProvider={toggleProvider}
          toggleVolume={toggleVolume}
          toggleTreatmentType={toggleTreatmentType}
          treatmentTypeData={offersData.treatmentTypeData}
          sideFilters={sideFilters}
          equipmentTypes={offersData.equipmentTypes}
          availableVolumes={offersData.availableVolumes}
          volumes={offersData.volumes}
          toggleEquipmentType={toggleEquipmentType}
          availableEquipmentTypes={offersData.availableEquipmentTypes}
        />
      ) : isLargeScreen && hasOffers ? (
        <NeedHelpSideSection />
      ) : null}
      <OfferInformationPanel
        closeMenu={() => {
          setOpenOfferDetails({ open: false, type: "ton" });
        }}
        open={openOfferDetails.open}
        type={openOfferDetails.type}
      />
      <div id="nprogress-bar" className="flex w-full flex-col gap-4 pb-8">
        <div className="flex flex-col">
          {hasOffers ? (
            <div className="z-10 flex h-[88px] w-full items-center gap-6 overflow-x-auto border border-l-0 border-gray-200 bg-white px-10">
              <ClintButton
                as="a"
                size={"medium"}
                variant={"secondary"}
                className="w-fit rounded-[8px] bg-green-600 px-5 py-2 text-white"
                labelClassName="text-white text-base"
                hideIcon
                anchorProps={{
                  href: isProfessional ? "#per-ton" : "#recommended",
                }}
              >
                {isProfessional
                  ? "Prix à la tonne"
                  : "Recommandation GoodCollect"}
              </ClintButton>
              <ClintButton
                as="a"
                className="w-fit rounded-[8px] border-none px-5 py-2"
                size={"medium"}
                hideIcon
                variant={"secondary"}
                labelClassName="text-base"
                anchorProps={{
                  href: "#per-plan",
                }}
              >
                {isProfessional ? "Prix forfaitaire" : "Offres supplémentaires"}
              </ClintButton>
              {isProfessional && filteredOtherOffers.length > 0 ? (
                <ClintButton
                  as="a"
                  className="w-fit rounded-[8px] border-none px-5 py-2"
                  size={"medium"}
                  hideIcon
                  variant={"secondary"}
                  labelClassName="text-base"
                  anchorProps={{
                    href: "#offers",
                  }}
                >
                  Offres supplémentaires
                </ClintButton>
              ) : null}
            </div>
          ) : null}

          {isProfessional ? (
            <MarketplaceSideFilters
              setSideFilters={setSideFilters}
              availableProviders={offersData.availableProviders}
              toggleProvider={toggleProvider}
              className="flex p-5 pt-0 lg:hidden"
              toggleVolume={toggleVolume}
              toggleTreatmentType={toggleTreatmentType}
              treatmentTypeData={offersData.treatmentTypeData}
              sideFilters={sideFilters}
              equipmentTypes={offersData.equipmentTypes}
              availableVolumes={offersData.availableVolumes}
              volumes={offersData.volumes}
              toggleEquipmentType={toggleEquipmentType}
              availableEquipmentTypes={offersData.availableEquipmentTypes}
            />
          ) : null}
        </div>

        <div className="flex flex-col gap-6 lg:px-6">
          <div
            id={isProfessional ? "per-ton" : "recommended"}
            className="flex w-full scroll-mt-60 flex-col gap-6 rounded-[8px] bg-white p-4"
          >
            {hasOffers ? (
              <div className="flex flex-col gap-1">
                <Headline
                  size={"h4"}
                  as="div"
                  variant={"secondary"}
                  className="flex flex-wrap items-center gap-2 whitespace-nowrap"
                >
                  <span>
                    {isProfessional
                      ? "Prix à la tonne"
                      : "GoodCollect vous recommande"}
                  </span>

                  {isProfessional ? (
                    <>
                      <Tag
                        type="Square"
                        variant="Light"
                        className="flex h-fit items-center justify-center px-2 py-1"
                      >
                        Recommandé
                      </Tag>
                      <ClintButton
                        variant={"tertiary"}
                        as="Button"
                        className="text-secondary ml-auto border-none px-0 underline"
                        onClick={() => {
                          setOpenOfferDetails({ open: true, type: "ton" });
                        }}
                      >
                        Comment ça fonctionne ?
                      </ClintButton>
                    </>
                  ) : null}
                </Headline>
                <Paragraph size="md" variant={"secondary"}>
                  Basé sur notre expérience, nous vous recommandons cette offre
                  pour une gestion efficace et écologique de vos déchets
                </Paragraph>
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                <Headline
                  size={"h4"}
                  as="div"
                  variant={"secondary"}
                  className="flex items-center gap-2"
                >
                  <span>Aucune offre réservable en ligne actuellement</span>

                  {isProfessional ? (
                    <ClintButton
                      variant={"tertiary"}
                      as="Button"
                      className="ml-auto"
                      onClick={() => {
                        setSelectedFilters({ "search-modal": true });
                      }}
                    >
                      Modifier mon adresse
                    </ClintButton>
                  ) : null}
                </Headline>
                <Paragraph size="md" variant={"secondary"}>
                  Nous n'avons pas trouvé d'offres correspondant à vos critères
                  de recherche : modifiez-les ou contactez-nous !
                </Paragraph>
              </div>
            )}
            {bestOfferTonDisplay ? (
              <CardOffer
                type="offer"
                providerName={bestOfferTonDisplay.businessName}
                equipmentWeight={bestOfferTonDisplay.equipment.equipmentWeight}
                priceHT={bestOfferTonDisplay.priceHT}
                isProfessional={isProfessional}
                priceTTC={bestOfferTonDisplay.priceTTC}
                key={`best-ton-${bestOfferTonDisplay.equipmentPriceRuleId}`}
                equipmentName={`${bestOfferTonDisplay.equipment.type.name} - ${bestOfferTonDisplay.equipment.volume}m3`}
                height={bestOfferTonDisplay.equipment.height}
                length={bestOfferTonDisplay.equipment.length}
                width={bestOfferTonDisplay.equipment.width}
                imageUrl={
                  bestOfferTonDisplay.equipment.imageUrl || equipmentImage
                }
                onBookClick={() =>
                  bestOfferTonDisplay && onBookClick(bestOfferTonDisplay)
                }
                onQuoteClick={() => {
                  if (!bestOfferTonDisplay) return;
                  setSelectedFilters({
                    equipmentPriceRuleId:
                      bestOfferTonDisplay?.equipmentPriceRuleId,
                    providerId: bestOfferTonDisplay?.providerId,
                    "show-quote": true,
                  });
                }}
              />
            ) : null}

            {!hasOffers ? (
              <CardOffer
                type="no-offer"
                isProfessional={isProfessional}
                equipmentName={``}
                height={80}
                length={560}
                width={255}
                imageUrl={equipmentImage}
                onQuoteClick={() => {
                  const startDate = selectedFilters.startDate ?? "";
                  const endDate = selectedFilters.endDate ?? "";
                  const volume = selectedFilters.volume ?? "";
                  const address = selectedFilters.address ?? "";
                  const waste = selectedFilters.waste;
                  const wasteType = wasteTypes.find((w) => w.gcId === waste);
                  if (!wasteType) {
                    throw new Error(
                      "Nous n'avons pas trouvé la typologie de déchet sélectionnée.",
                    );
                  }

                  const wasteName = wasteType.name;

                  const dateDescription = `${
                    startDate
                      ? `à partir du ${startDate} ${endDate ? `au ${endDate}` : ""}`
                      : ""
                  }`;
                  const defaultDescription =
                    `Bonjour, je recherche une solution pour ${volume}m3 de ${wasteName} au ${address} ${dateDescription}.`.trim();
                  const addDescriptionParam = (description: string) => {
                    const searchParams = new URLSearchParams(
                      Object.entries(selectedFilters).map(([key, value]) => [
                        key,
                        value?.toString(),
                      ]),
                    );
                    searchParams.set("description", description);
                    return searchParams;
                  };

                  const redirectUrl = `/contact?${addDescriptionParam(
                    defaultDescription +
                      ` Je souhaite recevoir un devis par mail.`,
                  )?.toString()}`;

                  navigate(redirectUrl);
                }}
              />
            ) : null}
          </div>
          {isProfessional && bestOfferPlanDisplay ? (
            <>
              <div className="my-2 h-[1px] w-full bg-gray-200"></div>
              <div
                id={"per-plan"}
                className="flex w-full scroll-mt-60 flex-col gap-6 rounded-[8px] bg-white p-6"
              >
                <div className="flex flex-col gap-1">
                  <Headline
                    size={"h4"}
                    as="div"
                    variant={"secondary"}
                    className="flex flex-wrap items-center gap-2 whitespace-nowrap"
                  >
                    Prix forfaitaire
                    <ClintButton
                      variant={"tertiary"}
                      as="Button"
                      className="text-secondary ml-auto border-none px-0 underline"
                      onClick={() => {
                        setOpenOfferDetails({ open: true, type: "plan" });
                      }}
                    >
                      Comment ça fonctionne ?
                    </ClintButton>
                  </Headline>
                  <Paragraph size="md" variant={"secondary"}>
                    Correspond à une offre comprenant le transport, la location
                    et le traitement des déchets.
                  </Paragraph>
                </div>
                {bestOfferPlanDisplay ? (
                  <CardOffer
                    providerName={bestOfferPlanDisplay.businessName}
                    type="offer"
                    equipmentWeight={
                      bestOfferPlanDisplay.equipment.equipmentWeight
                    }
                    priceHT={bestOfferPlanDisplay.priceHT}
                    isProfessional={isProfessional}
                    priceTTC={bestOfferPlanDisplay.priceTTC}
                    key={`best-plan-${bestOfferPlanDisplay.equipmentPriceRuleId}`}
                    equipmentName={`${bestOfferPlanDisplay.equipment.type.name} - ${bestOfferPlanDisplay.equipment.volume}m3`}
                    height={bestOfferPlanDisplay.equipment.height}
                    length={bestOfferPlanDisplay.equipment.length}
                    width={bestOfferPlanDisplay.equipment.width}
                    imageUrl={
                      bestOfferPlanDisplay.equipment.imageUrl || equipmentImage
                    }
                    onBookClick={() =>
                      bestOfferPlanDisplay && onBookClick(bestOfferPlanDisplay)
                    }
                    onQuoteClick={() => {
                      if (!bestOfferPlanDisplay) return;
                      setSelectedFilters({
                        equipmentPriceRuleId:
                          bestOfferPlanDisplay?.equipmentPriceRuleId,
                        providerId: bestOfferPlanDisplay?.providerId,
                        "show-quote": true,
                      });
                    }}
                  />
                ) : null}
              </div>
            </>
          ) : null}
          {filteredOtherOffers.length > 0 ? (
            <>
              <div className="my-2 h-[1px] w-full bg-gray-200"></div>
              <div
                id="offers"
                className="flex w-full scroll-mt-60 flex-col gap-6 rounded-[8px] bg-white p-6"
              >
                <div className="flex flex-col gap-1">
                  <Headline size={"h4"} as="div" variant={"secondary"}>
                    Offres supplémentaires
                  </Headline>
                </div>
                <div className="flex flex-col gap-6">
                  {filteredOtherOffers.map((offer) => (
                    <CardOffer
                      providerName={offer.businessName}
                      type="offer"
                      isPlan={offer.isPlan}
                      priceHT={offer.priceHT}
                      equipmentWeight={offer.equipment.equipmentWeight}
                      isProfessional={isProfessional}
                      priceTTC={offer.priceTTC}
                      key={`${offer.isPlan ? "plan" : "ton"}-${offer.equipmentPriceRuleId}-${offer.treatmentPriceRuleId}`}
                      equipmentName={`${offer.equipment.type.name} - ${offer.equipment.volume}m3`}
                      imageUrl={offer.equipment.imageUrl || equipmentImage}
                      height={offer.equipment.height}
                      length={offer.equipment.length}
                      width={offer.equipment.width}
                      onBookClick={() => onBookClick(offer)}
                      onQuoteClick={() => {
                        setSelectedFilters({
                          equipmentPriceRuleId: offer.equipmentPriceRuleId,
                          providerId: offer.providerId,
                          "show-quote": true,
                        });
                      }}
                    />
                  ))}
                </div>
                {filteredHiddenOffersCount > 0 ? (
                  <ClintButton
                    as="Button"
                    variant="tertiary"
                    size="medium"
                    type="button"
                    className="mr-auto"
                    onClick={() => {
                      setSideFilters({
                        showHiddenOffers: !sideFilters.showHiddenOffers,
                      });
                    }}
                  >
                    {!sideFilters.showHiddenOffers
                      ? `Afficher plus (${filteredHiddenOffersCount})`
                      : "Cacher les offres supplémentaires"}
                  </ClintButton>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
      {!isProfessional && !isLargeScreen && hasOffers ? (
        <NeedHelpSideSection />
      ) : null}
    </div>
  );
};

const OfferDetailSection = ({
  setSelectedFilters,
  selectedFilters,
}: {
  setSelectedFilters: MarketplaceSearchParamsQueryStatesProps["setSelectedFilters"];
  selectedFilters: MarketplaceSearchParamsQueryStatesProps["selectedFilters"];
}) => {
  const { offer, offerItemDescription } = usePublicLayout() || {};

  const MobileBookAndQuoteButtons = ({ className }: { className?: string }) => {
    return (
      <div className={cn("flex w-full flex-col gap-2.5", className)}>
        <div className="flex w-full flex-row items-center gap-2.5 lg:flex-col lg:items-start">
          <ClintButton
            as="Button"
            type="button"
            onClick={() => {
              setSelectedFilters(
                {
                  step: "4",
                },
                {
                  history: "push",
                },
              );
            }}
            iconPosition="right"
            variant={"primary"}
            className="w-full grow"
            labelSize="L"
          >
            Réserver
          </ClintButton>
          <ClintButton
            as="Button"
            hideIcon
            iconPosition="right"
            variant={"tertiary"}
            className="w-full grow"
            labelSize="L"
            onClick={() => {
              if (!offer) return;
              setSelectedFilters({
                equipmentPriceRuleId: offer?.offer.equipment.priceRuleId,
                providerId: offer?.offer.provider.id,
                "show-quote": true,
              });
            }}
          >
            Obtenir un devis
          </ClintButton>
        </div>

        {selectedFilters.plans === "1" ? null : (
          <Label
            size="XS"
            variant={"secondary"}
            className="text-center text-gray-500"
          >
            Traitement et location facturés en fin de prestation au réel
          </Label>
        )}
      </div>
    );
  };

  const { isProfessional } = selectedFilters;
  if (!offer || !offerItemDescription) return null;

  const {
    pricingDetails: {
      planPriceHT,
      planPriceTTC,
      tonPriceHT,
      tonPriceTTC,
      bookingPrices: { priceHT, priceTTC },
      transportData: {
        transportDeliveryPriceHT,
        transportDeliveryPriceTTC,
        transportPickupPriceHT,
        transportPickupPriceTTC,
      },
      treatmentData: {
        treatmentPricePerTonHT,
        equipmentWeight,
        treatmentPricePerTonTTC,
      },
      rentData: { rentPricePerDayHT, rentPricePerDayTTC },
    },
  } = offer;

  const isPro = isProfessional === "1";
  const displayedPrice = isPro ? priceHT : priceTTC;

  let displayedTransportPrice = 0;
  if (isPro) {
    displayedTransportPrice = offer.offer.provider.doubleRotationBennePrice //
      ? transportDeliveryPriceHT + transportPickupPriceHT
      : transportDeliveryPriceHT;
  } else {
    displayedTransportPrice = offer.offer.provider.doubleRotationBennePrice
      ? transportDeliveryPriceTTC + transportPickupPriceTTC
      : transportDeliveryPriceTTC;
  }

  const displayedTreatmentPricePerTon = isPro
    ? treatmentPricePerTonHT
    : treatmentPricePerTonTTC;
  const displayedRentPricePerDay = isPro
    ? rentPricePerDayHT
    : rentPricePerDayTTC;

  const displayedPlanPrice = isPro ? planPriceHT : planPriceTTC;
  const displayedTonPrice = isPro ? tonPriceHT : tonPriceTTC;

  useEffect(() => {
    setSelectedFilters({
      equipmentPriceRuleId: offer.offer.equipment.priceRuleId,
      providerId: offer.offer.provider.id,
    });
  }, [offer]);
  const user = useOptionalUser();
  const isLoading = useNavigation().state === "loading";

  return (
    <>
      <MobileBookAndQuoteButtons className="fixed bottom-0 z-20 flex w-full flex-col border-t border-gray-300 bg-gray-100 p-4 lg:hidden" />
      <div className="relative flex h-full w-full flex-col gap-4 bg-white pt-5 lg:min-h-screen lg:flex-row lg:bg-gray-100 lg:px-10">
        <div className="flex w-full max-w-full grow flex-col items-start gap-10 max-lg:px-5 lg:basis-[758px]">
          <div className="flex w-full flex-col gap-4">
            <ImageSlideShow
              key={offer.offer.equipment.priceRuleId}
              assets={offer.offer.assets}
            />

            <div className="flex flex-col gap-4">
              <SwitchBar
                options={
                  offer?.offer.otherEquipmentPriceRules.map((e) => ({
                    name: e.equipment.name,
                    value: e.id.toString(),
                  })) || []
                }
                defaultOption={offer?.offer.equipment.priceRuleId?.toString()}
                onSwitch={(selectedEquipmentPriceRuleId) => {
                  setSelectedFilters(
                    {
                      equipmentPriceRuleId: Number(
                        selectedEquipmentPriceRuleId,
                      ),
                    },
                    {
                      history: "push",
                      shallow: false,
                    },
                  );
                }}
              />
              <div className="flex gap-2 overflow-x-auto">
                <Tag
                  type="Square"
                  variant="Light"
                  className="whitespace-nowrap"
                >
                  {`Largeur : ${offer?.offer.equipment.width || 0}cm`}
                </Tag>
                <Tag
                  type="Square"
                  variant="Light"
                  className="whitespace-nowrap"
                >
                  {`Hauteur : ${offer?.offer.equipment.height || 0}cm`}
                </Tag>
                <Tag
                  type="Square"
                  variant="Light"
                  className="whitespace-nowrap"
                >
                  {`Longueur : ${offer?.offer.equipment.length || 0}cm`}
                </Tag>
              </div>
            </div>
          </div>
          <OfferInformation
            equipmentWeight={equipmentWeight}
            className="hidden lg:flex"
          />
        </div>
        <div className="flex h-fit grow flex-col gap-6 border-y border-gray-300 bg-white p-6 lg:basis-[586px] lg:rounded-[8px] lg:border">
          <div className="flex w-full items-center justify-between gap-2">
            <div className="flex flex-col">
              <Headline size="h4">{`${offer.offer.equipment.type.name} - ${offer.offer.equipment.volume}m3`}</Headline>
              <Label size="M" variant={"secondary"} className="text-gray-600">
                {offer.offer.waste.name}
              </Label>
              {user?.authOptions.isAdmin ? (
                <Paragraph size="lg" className="text-rouge py-1 font-bold">
                  {offer.offer.name}
                </Paragraph>
              ) : null}
            </div>
            <div className="flex flex-col">
              <Label size="M" variant={"secondary"}>
                À partir de
              </Label>
              <div className="flex items-center gap-2">
                <Headline
                  size={"h4"}
                  as="div"
                  className="whitespace-nowrap flex flex-row items-center"
                >
                  <LoadingSpinner loading={isLoading} />
                  {formatPriceWithCurrency(displayedPrice, true)}
                </Headline>
                <Label size="S" variant={"secondary"} className="text-gray-500">
                  {isPro ? "HT" : "TTC"}
                </Label>
              </div>
            </div>
          </div>

          {user?.authOptions.isAdmin ? (
            <>
              <SwitchBar
                options={[
                  { name: "Prestation récurrente", value: "1" },
                  { name: "Prestation ponctuelle", value: "0" },
                ]}
                defaultOption={selectedFilters.isRecurring}
                onSwitch={(newValue) => {
                  setSelectedFilters(
                    {
                      isRecurring: newValue === "1" ? "1" : "0",
                    },
                    {
                      shallow: false,
                    },
                  );
                }}
              />
              <SwitchBar
                options={[
                  { name: "Enlèvement immédiat", value: "1" },
                  { name: "Dépose et retrait", value: "0" },
                ]}
                defaultOption={selectedFilters.immediatePickup}
                onSwitch={(newValue) => {
                  setSelectedFilters(
                    {
                      immediatePickup: newValue === "1" ? "1" : "0",
                    },
                    {
                      shallow: false,
                    },
                  );
                }}
              />
            </>
          ) : null}

          <DoubleSelect
            onClick={(newValue) => {
              setSelectedFilters(
                {
                  plans: newValue === "1" ? "1" : "0",
                },
                {
                  shallow: false,
                },
              );
            }}
            firstOption={
              offer.offer.provider.enabledPlans
                ? null
                : {
                    recommanded: true,
                    id: "0",
                    name: "tons",
                    subtitle: "Payez le prix réel de votre chargement.",
                    title:
                      selectedFilters.immediatePickup === "1"
                        ? "Prix à la tonne (enlèvement immédiat)"
                        : "Prix à la tonne",
                    value: "0",
                    mainLabel: `À partir de ${formatPriceWithCurrency(displayedTonPrice, true)} ${isPro ? "HT" : "TTC"}`,
                    subLabel: `+ ${formatPriceWithCurrency(displayedTreatmentPricePerTon, true)} ${isPro ? "HT" : "TTC"} par tonne supplémentaire.`,
                  }
            }
            name="price"
            secondOption={{
              disabled: selectedFilters.immediatePickup === "1",
              id: "1",
              name: "plans",
              subtitle: `${equipmentWeight} tonne(s) comprise(s)`,
              title: "Offre forfaitaire",
              value: "1",
              mainLabel: `${formatPriceWithCurrency(displayedPlanPrice || 0, true)} ${isPro ? "HT" : "TTC"}`,
              subLabel: `+ ${formatPriceWithCurrency(displayedTreatmentPricePerTon, true)} ${isPro ? "HT" : "TTC"} par tonne supplémentaire.`,
            }}
            defaultOption={selectedFilters.plans}
          />
          <MobileBookAndQuoteButtons className="hidden lg:flex" />

          <div className="flex flex-col gap-2">
            <BookingPriceDetailItem
              tooltip="show"
              description={`Prix de traitement ${offer.offer.waste.name.toLowerCase()} à la tonne`}
              wrapperDescription={
                "Correspond au prix à la tonne qui sera facturé sur la facture finale après pesée de la benne en centre agrée. Le calcul se fera à la dizaine de Kg près."
              }
              wrapperTitle="Prix de traitement :"
              icon={
                <ArrowTriangle aria-hidden="true" className="size-5 shrink-0" />
              }
              subValue={`+ ${formatPriceWithCurrency(displayedTreatmentPricePerTon, true)} ${isPro ? "HT" : "TTC"} / tonne`}
            />
            <BookingPriceDetailItem
              tooltip="show"
              description={`Location benne prix journalier`}
              wrapperTitle="Prix de location :"
              wrapperDescription={
                "Correspond au prix à la journée d'immobilisation de la benne. Chaque journée entamée est due."
              }
              icon={<Clock aria-hidden="true" className="size-5 shrink-0" />}
              subValue={`+ ${formatPriceWithCurrency(displayedRentPricePerDay, true)} ${isPro ? "HT" : "TTC"} / Jour`}
            />
            <BookingPriceDetailItem
              tooltip="show"
              description={`Rotation supplémentaire`}
              wrapperTitle="Prix de rotation :"
              wrapperDescription={
                "Correspond au prix d'une rotation ou d'un transport supplémentaire entre le centre de traitement et votre site. Si vous souhaitez réserver des rotations supplémentaires : pensez à nous contacter 48h à l'avance pour organiser le transport. "
              }
              icon={
                <RefreshDouble aria-hidden="true" className="size-5 shrink-0" />
              }
              subValue={`+ ${formatPriceWithCurrency(displayedTransportPrice, true)} ${isPro ? "HT" : "TTC"} / Rotation`}
            />
          </div>
        </div>
        <OfferInformation
          equipmentWeight={equipmentWeight}
          className="flex pt-8 lg:hidden"
        />
      </div>
    </>
  );
};
const NeedHelpSideSection = () => {
  const { phoneDisplay, phoneLink, CONTACT_EMAIL } = useEnv() || {};
  return (
    <div className="flex w-full max-w-full flex-col border border-gray-200 bg-white lg:max-w-[280px]">
      <Headline
        size={"h6"}
        as="div"
        className="hidden h-[88px] items-center justify-start border-gray-200 pl-4 lg:flex lg:border-b"
      >
        Besoin d'aide ?
      </Headline>
      <div className="flex w-full flex-col gap-4 p-4">
        <ClintButton
          as="Link"
          iconPosition="left"
          linkProps={{
            to: phoneLink || "#",
            reloadDocument: true,
          }}
          variant="secondaryGray100"
          size="lg"
          Icon={Phone}
        >
          {phoneDisplay}
        </ClintButton>
        <ClintButton
          as="Link"
          iconPosition="left"
          linkProps={{
            to: `mailto:${CONTACT_EMAIL}` || "#",
            reloadDocument: true,
          }}
          variant="secondaryGray100"
          size="lg"
          Icon={Mail}
        >
          {CONTACT_EMAIL}
        </ClintButton>
      </div>
    </div>
  );
};

const DescriptionItem = ({
  item: { title, content },
  type = "good",
  action = null,
}: {
  item: ReturnType<typeof getOfferItemDescription>["howItWorks"];
  type?: "good" | "bad" | "warning" | "rotate";
  action?: React.ReactNode;
}) => {
  const IconComp =
    type === "good"
      ? Check
      : type === "bad"
        ? Xmark
        : type === "warning"
          ? InfoCircle
          : type === "rotate"
            ? RefreshCircle
            : Check;
  return (
    <div className="flex w-full flex-col gap-3 lg:gap-5 lg:rounded-[8px] lg:border lg:border-gray-300 lg:p-6">
      <div className="flex items-center gap-2">
        <Tag
          type="Square"
          variant="Light"
          className={cn(`flex aspect-square items-center justify-center`, {
            "border-none bg-[#FFE8ED] text-[#FF4874]": type === "bad",
            "border-none bg-[#FFE4BC] text-[#835A1D]": type === "warning",
          })}
        >
          <IconComp
            aria-hidden="true"
            className="size-6 shrink-0"
            strokeWidth={2}
          />
        </Tag>
        <Headline size="h6" variant={"secondary"}>
          {title}
        </Headline>
      </div>
      <Markdown
        content={content}
        className="prose prose-p:text-start prose-p:text-sm prose-li:text-sm mx-0 mt-0 w-fit list-outside list-disc items-start text-start"
      />
      {action}
    </div>
  );
};

export type DetailItemWithoutTooltip = {
  description: string;
  subValue?: React.ReactNode;
  icon?: React.ReactNode;
  hideIcon?: boolean;
  tooltip: "hide";
  valueClassName?: string;
  titleClassName?: string;
};

export type DetailItemWithTooltip = {
  description: string;
  wrapperTitle: string;
  wrapperDescription: string;
  subValue?: React.ReactNode;
  icon?: React.ReactNode;
  hideIcon?: boolean;
  tooltip: "show";
  valueClassName?: string;
  titleClassName?: string;
};
export const BookingPriceDetailItem = (
  props: DetailItemWithoutTooltip | DetailItemWithTooltip,
) => {
  const {
    description,
    subValue = null,
    icon = null,
    hideIcon = false,
    valueClassName,
    titleClassName,
    tooltip,
  } = props;
  const Wrapper = () => {
    if (tooltip === "hide") {
      return (
        <div className="flex w-full flex-wrap items-center justify-between gap-2">
          <Label
            size="S"
            variant="secondary"
            className={cn("whitespace-normal text-gray-800", titleClassName)}
          >
            {description}
          </Label>
          <Paragraph
            size="sm"
            fontWeight={"bold"}
            variant={"secondary"}
            className={cn("ml-auto", valueClassName)}
          >
            {subValue}
          </Paragraph>
        </div>
      );
    }
    const { wrapperTitle, wrapperDescription } = props;
    return (
      <div className="flex w-full flex-wrap items-center justify-between gap-2">
        <AppTooltip
          className="flex-shrink"
          triggerComponent={
            <Label
              size="S"
              variant="secondary"
              className={cn(
                "whitespace-normal text-gray-800 underline",
                titleClassName,
              )}
            >
              {description}
            </Label>
          }
          content={
            <div className="flex max-w-[28ch] flex-col gap-2">
              <Paragraph size="md" fontWeight={"bold"}>
                {wrapperTitle}
              </Paragraph>
              <Paragraph size="md">{wrapperDescription}</Paragraph>
            </div>
          }
        />

        <Paragraph
          size="sm"
          fontWeight={"bold"}
          variant={"secondary"}
          className={cn("lg:ml-auto", valueClassName)}
        >
          {subValue}
        </Paragraph>
      </div>
    );
  };
  return (
    <div className="flex w-full flex-row items-center gap-3">
      {hideIcon ? null : (
        <Tag
          variant="Light"
          type="Square"
          className="text-teal flex aspect-square shrink-0 items-center justify-center border-none bg-green-100 p-1.5 text-green-600"
        >
          {icon}
        </Tag>
      )}
      <Wrapper />
    </div>
  );
};

export const ImageSlideShow = ({
  assets,
}: {
  assets: Awaited<ReturnType<typeof getBookingPrice>>["offer"]["assets"];
}) => {
  const images = assets.map((as) => as.fileUrl);
  if (images.length === 0) {
    images.push(equipmentOfferImage);
  }

  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <div className="flex w-full flex-col-reverse gap-5 rounded-[8px] bg-gray-100 p-5 lg:flex-row lg:bg-white">
      <div className="flex flex-row justify-center gap-4 lg:flex-col">
        {images.map((image) => (
          <button
            key={image}
            type="button"
            onClick={() => {
              setSelectedImage(image);
            }}
          >
            <OptimizedImage
              source={image}
              maxWidth={96}
              alt="equipment"
              containerClassName={`border-2 rounded-[8px] overflow-hidden ${selectedImage === image ? "border-secondary" : "border-gray-400"}`}
              className={cn(
                `aspect-square h-auto w-full max-w-[48px] bg-white object-contain ${selectedImage === image ? "" : "opacity-50"}`,
              )}
              imageProps={{
                minHeight: "100%",
                objectFit: "contain",
              }}
            />
          </button>
        ))}
      </div>
      <div className="flex w-full items-center justify-center bg-gray-100">
        <OptimizedImage
          source={selectedImage}
          maxWidth={646}
          height={401}
          alt="equipment"
          className="h-auto w-full object-contain"
          imageProps={{
            minHeight: "100%",
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  );
};

const OfferInformation = ({
  className,
  equipmentWeight,
}: {
  className?: string;
  equipmentWeight: number;
}) => {
  const { offer, offerItemDescription } = usePublicLayout() || {};
  // useLoaderData<typeof loader>();
  if (!offer || !offerItemDescription) return null;
  return (
    <div className={cn("flex flex-col items-start gap-10", className)}>
      <div className="flex flex-col gap-10 px-5 lg:px-0">
        <div className="flex flex-col gap-2">
          <Headline size="h6" variant={"secondary"}>
            Prestation
          </Headline>
          <div className="flex flex-wrap gap-3">
            <CheckedItem title="Dépose d'une benne" />
            <CheckedItem title="Retrait d'une benne" />
            <CheckedItem title="Location de la benne" />
            <CheckedItem title="Traitement des déchets en centre agréé" />
            <CheckedItem title="Annulation gratuite 48h avant le jour de prestation" />
            <CheckedItem
              title={
                offer.offer.isPlan
                  ? `1 trajet & ${equipmentWeight} tonne(s) inclue(s)`
                  : "1 trajet"
              }
            />
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${goodcollectVector})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="bg-secondary relative flex w-full gap-2 overflow-hidden rounded-[8px] px-6 py-4 lg:px-24 lg:py-16"
        >
          <Sparks className="text-primary size-8 shrink-0" aria-hidden="true" />
          <div className="flex w-full flex-col gap-8">
            <Headline size="h4" variant={"white"} className="max-w-[15ch]">
              Nos clients ont choisi cette solution
            </Headline>
            <div className="flex w-full flex-wrap items-center gap-5">
              <div className="flex grow flex-col gap-2">
                <Label
                  size="L"
                  variant={"secondary"}
                  className="text-green-200"
                >
                  100%
                </Label>
                <Label size="S" variant={"white"} className="">
                  Traçabilité assurée
                </Label>
              </div>
              <div className="flex grow flex-col gap-2">
                <Label
                  size="L"
                  variant={"secondary"}
                  className="text-green-200"
                >
                  +400 agences
                </Label>
                <Label size="S" variant={"white"}>
                  Présence Nationale
                </Label>
              </div>
              <div className="flex grow flex-col gap-2">
                <Label
                  size="L"
                  variant={"secondary"}
                  className="text-green-200"
                >
                  4,95/5
                </Label>
                <Label size="S" variant={"white"}>
                  Moyenne avis google
                </Label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6 lg:gap-10">
        {/* <OfferInformationItemWrapper>
          <div className="flex w-full items-center gap-2">
            <Headline size="h5" variant={"secondary"}>
              Rotations
            </Headline>
            <Label size="S" variant="secondary" className="ml-auto underline">
              En savoir plus
            </Label>
          </div>
          <DescriptionItem
            type="rotate"
            item={{
              title: "Avez-vous besoin de faire des rotations ?",
              content:
                "Correspond au prix d'une rotation ou d'un transport supplémentaire entre le centre de traitement et votre site. Si vous souhaitez réserver des rotations supplémentaires : pensez à nous contacter 48h à l'avance pour organiser le transport. ",
            }}
            action={
              <ClintButton
                as="Button"
                type="button"
                variant={"tertiary"}
                isLoading={false}
                labelSize="M"
                labelClassName="w-full flex justify-between gap-3"
              >
                <div className="block grow basis-[100px]" />

                <span className="text-center">Ajouter rotations</span>
                <Paragraph
                  className="grow basis-[100px] whitespace-nowrap text-end text-green-700"
                  size="sm"
                  variant={"secondary"}
                  fontWeight={"bold"}
                >
                  {`+${formatPriceWithCurrency(offer.pricingDetails.transportData.transportDeliveryPriceHT, true)} HT par rotation`}
                </Paragraph>
              </ClintButton>
            }
          />
        </OfferInformationItemWrapper> */}
        <OfferInformationItemWrapper>
          <Headline size="h5" variant={"secondary"}>
            Déchets
          </Headline>
          <DescriptionItem
            type="good"
            item={offerItemDescription?.authorizedWaste}
          />
          <DescriptionItem
            type="bad"
            item={offerItemDescription?.forbiddenWaste}
          />
        </OfferInformationItemWrapper>
        <OfferInformationItemWrapper>
          <Headline size="h5" variant={"secondary"}>
            Informations importantes
          </Headline>
          <DescriptionItem
            type="good"
            item={offerItemDescription?.benneOnPublicStreet}
          />
          <DescriptionItem
            type="warning"
            item={offerItemDescription?.accessibility}
          />
        </OfferInformationItemWrapper>
        <div className="relative flex w-full flex-col gap-2 max-lg:px-5 lg:gap-6 lg:rounded-[8px] lg:border lg:border-gray-300 lg:p-6">
          <Headline size="h5" variant={"secondary"}>
            Paiement et annulations
          </Headline>
          <Markdown
            content={offerItemDescription?.paymentAndCancel.content}
            className="prose prose-p:text-start prose-p:text-sm prose-li:text-sm mx-0 mt-0 w-fit list-outside list-disc items-start text-start"
          />
        </div>
      </div>
    </div>
  );
};

const OfferInformationItemWrapper = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "relative flex w-full flex-col gap-6 rounded-[8px] bg-gray-100 p-4 max-lg:px-5 lg:bg-white lg:p-6",
        className,
      )}
    >
      {children}
    </div>
  );
};

const OfferInformationPanel = ({
  closeMenu,
  open,
  type,
}: {
  open: boolean;
  closeMenu: () => void;
  type: "plan" | "ton";
}) => {
  return (
    <MarketplacePanel
      title="Prix à la tonne"
      closeMenu={closeMenu}
      open={open}
      footerAction={
        <ClintButton
          variant="tertiary"
          as="Button"
          type="button"
          labelSize="L"
          className="w-full"
          onClick={() => {
            closeMenu();
          }}
        >
          Fermer
        </ClintButton>
      }
    >
      <div className="flex flex-col gap-6">
        {type === "ton" ? (
          <>
            <div className="flex flex-col gap-2">
              <Headline variant={"secondary"} size="h5">
                Comment fonctionne le prix à la tonne ?
              </Headline>
              <Paragraph size="md" className="text-gray-700">
                Le prix à la tonne est calculé en fonction du poids total des
                déchets collectés. Après la collecte, la benne est pesée dans un
                centre de traitement agréé, et un ticket de pesée vous est
                fourni. Le coût final est déterminé selon le tarif appliqué par
                tonne, assurant une facturation juste et précise qui reflète
                exactement la quantité de déchets traitée.
              </Paragraph>
            </div>
            <div className="flex flex-col gap-2">
              <Headline variant={"secondary"} size="h6">
                Estimer le tonnage de son chargement
              </Headline>
              <Paragraph size="md" className="text-gray-700">
                Pour estimer le tonnage de votre chargement, commencez par
                évaluer le type et la densité des déchets que vous avez. Les
                matériaux légers comme le papier ou le plastique pèsent moins
                par volume que les matériaux lourds comme le métal ou le béton.
                Estimez ensuite le volume en m³ et multipliez-le par la densité
                en kg/m³. Cette estimation vous aidera à prévoir les coûts et à
                optimiser l'utilisation de la benne. Si vous avez besoin d'aide,
                contactez nos experts.
              </Paragraph>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-2">
            <Headline variant={"secondary"} size="h5">
              Comment fonctionne le prix forfaitaire ?
            </Headline>
            <Paragraph size="md" className="text-gray-700">
              Le prix forfaitaire comprend plusieurs éléments : le transport
              pour la dépose et le retrait de la benne, la location pendant la
              période demandée, et le traitement des déchets. Ce forfait est
              basé sur un tonnage moyen, calculé en utilisant nos règles de
              densité moyenne et multiplié par la taille de la benne choisie. En
              cas de rotation supplémentaire, de durée d'immobilisation
              prolongée ou si le tonnage dépasse le forfait, des suppléments
              seront facturés. Cette approche assure une tarification
              transparente et simplifiée, couvrant tous les aspects de la
              gestion des déchets.
            </Paragraph>
          </div>
        )}
      </div>
    </MarketplacePanel>
  );
};

const OfferQuotePanel = ({
  open,
  title,
  selectedFilters,
  setSelectedFilters,
}: {
  open: boolean;
  title: string;
  selectedFilters: ReturnType<
    typeof useMarketplaceSearchBar
  >["selectedFilters"];
  setSelectedFilters: ReturnType<
    typeof useMarketplaceSearchBar
  >["setSelectedFilters"];
}) => {
  const getQuoteFetcher = useFetcher<typeof action>();
  const [form, fields] = useForm({
    id: "create-quote-form",
    constraint: getZodConstraint(GenerateLeadQuoteSchema),
    onValidate({ formData }) {
      const parsed = parseWithZod(formData, {
        schema: GenerateLeadQuoteSchema,
      });
      return parsed;
    },
    lastResult: getQuoteFetcher?.data?.result,
  });

  const isLoading = useIsSubmitting() || getQuoteFetcher.state !== "idle";

  const [phone, setPhone] = useState("");
  const [siret, setSiret] = useState("");

  const closeMenu = () => {
    setSelectedFilters({
      "show-quote": false,
    });
  };
  useEffect(() => {
    if (
      getQuoteFetcher.data?.result &&
      !getQuoteFetcher.data?.result.error &&
      getQuoteFetcher.state === "idle"
    ) {
      setTimeout(() => {
        getQuoteFetcher.data = undefined;
        closeMenu();
      }, 500);
    }
  }, [getQuoteFetcher.data, getQuoteFetcher.state, closeMenu]);

  if (!selectedFilters.providerId || !selectedFilters.equipmentPriceRuleId) {
    return null;
  }

  return (
    <>
      <MarketplacePanel
        title={title}
        closeMenu={closeMenu}
        open={open}
        footerAction={
          <div className="flex w-full flex-col gap-2">
            <ErrorList id={form.id} errors={form.errors} />
            <ErrorList
              id={fields.providerId.id}
              errors={fields.providerId.errors}
            />
            <ErrorList
              id={fields.equipmentPriceRuleId.id}
              errors={fields.equipmentPriceRuleId.errors}
            />
            <ErrorList
              id={fields.customerType.id}
              errors={fields.customerType.errors}
            />
            <ClintButton
              variant={"tertiary"}
              as="Button"
              type="button"
              onClick={() => {
                setSelectedFilters({ "search-modal": true });
              }}
            >
              Modifier ma recherche
            </ClintButton>
            <Paragraph size="xs">
              En cas de changement d'adresse, nous devons recalculer nos offres.
            </Paragraph>
            {/* <ClintButton
              variant="primary"
              as="Button"
              type="submit"
              labelSize="L"
              form={form.id}
              isLoading={isLoading}
              disabled={isLoading}
              className="w-full"
            >
              Recevoir le devis par mail
            </ClintButton> */}
          </div>
        }
      >
        <getQuoteFetcher.Form
          className="flex flex-col gap-6"
          method="POST"
          action={`?${new URLSearchParams(
            Object.entries(selectedFilters).map(([key, value]) => {
              return [key, key === "step" ? "3" : value?.toString()];
            }),
          ).toString()}`}
          {...getFormProps(form)}
        >
          <div className="flex flex-col gap-x-8 gap-y-4">
            <TwoSidedFormInput
              leftComponent={
                <DatepickerInput
                  inputProps={{
                    ...getInputProps(fields.startDate, { type: "text" }),
                  }}
                  onDateChange={(newDates) => {
                    setSelectedFilters({
                      startDate: newDates.startDate,
                    });
                  }}
                  selectedStartDate={selectedFilters.startDate}
                  selectedEndDate=""
                  labelProps={{
                    children: "Date de dépot",
                    className: "font-medium",
                  }}
                  showRecurring
                  errors={fields.startDate.errors}
                />
              }
              rightComponent={
                <DatepickerInput
                  minDate={selectedFilters.startDate}
                  onDateChange={(newDates) => {
                    setSelectedFilters({
                      endDate: newDates.startDate,
                    });
                  }}
                  selectedStartDate={selectedFilters.endDate}
                  selectedEndDate=""
                  labelProps={{
                    children: "Date de retrait (optionnelle)",
                    className: "font-medium",
                  }}
                  showRecurring
                  errors={fields.endDate.errors}
                />
              }
            />

            <div className="my-0 w-full border-t border-gray-200" />

            {/* <ClintButton as="Button" type="button" variant="tertiary">
              Ajouter une date de rotation supplémentaire
            </ClintButton> */}

            <ErrorList
              id={fields.customerType.errorId}
              errors={fields.customerType.errors}
            />
            <TwoSidedFormInput
              leftComponent={
                <Field
                  labelProps={{ children: "Prénom", className: "font-medium" }}
                  inputProps={{
                    ...getInputProps(fields.firstname, { type: "text" }),

                    autoComplete: "given-name",
                  }}
                  errors={fields.firstname.errors}
                />
              }
              rightComponent={
                <Field
                  labelProps={{ children: "Nom", className: "font-medium" }}
                  inputProps={{
                    ...getInputProps(fields.lastname, { type: "text" }),
                    autoComplete: "family-name",
                  }}
                  errors={fields.lastname.errors}
                />
              }
            />

            <TwoSidedFormInput
              leftComponent={
                <Field
                  labelProps={{ children: "Email", className: "font-medium" }}
                  inputProps={{
                    ...getInputProps(fields.email, { type: "email" }),
                    autoComplete: "email",
                  }}
                  errors={fields.email.errors}
                />
              }
              rightComponent={
                <PhoneField
                  labelProps={{
                    children: "Téléphone",
                    className: "font-medium",
                  }}
                  inputProps={{
                    ...getInputProps(fields.phone, { type: "tel" }),
                    autoComplete: "phone",
                    onChange: (newPhone: string) => {
                      setPhone(newPhone);
                    },
                    value: phone,
                  }}
                  errors={fields.phone.errors}
                />
              }
            />

            {selectedFilters.isProfessional === "1" ? (
              <>
                <Field
                  labelProps={{
                    children: "Nom de société",
                    className: "font-medium",
                  }}
                  inputProps={{
                    ...getInputProps(fields.companyName, { type: "text" }),
                    autoComplete: "company",
                  }}
                  errors={fields.companyName.errors}
                />

                <TwoSidedFormInput
                  leftComponent={
                    <Field
                      labelProps={{
                        children: "Numéro SIRET",
                        className: "font-medium",
                      }}
                      inputProps={{
                        ...getInputProps(fields.siret, { type: "text" }),
                        inputMode: "numeric",
                        pattern: "[0-9 ]*",

                        onChange: (e) => setSiret(formatSiret(e.target.value)),
                        value: siret,
                      }}
                      errors={fields.siret.errors}
                    />
                  }
                  rightComponent={
                    <Field
                      labelProps={{
                        children: "Numéro de TVA (optionnel)",
                        className: "font-medium",
                      }}
                      inputProps={{
                        ...getInputProps(fields.tvaNumber, { type: "text" }),
                        maxLength: 13,
                      }}
                      errors={fields.tvaNumber.errors}
                    />
                  }
                />
                <AddressCombobox
                  theme="default"
                  inputName={
                    getInputProps(fields.billingAddress, {
                      type: "text",
                    }).name
                  }
                  placeIdInputName={
                    getInputProps(fields.billingPlaceId, {
                      type: "text",
                    }).name
                  }
                  inputProps={{
                    ...getInputProps(fields.billingAddress, {
                      type: "text",
                    }),
                  }}
                  labelProps={{
                    children: "Adresse de facturation (optionnelle)",
                    className: "font-medium",
                  }}
                />
              </>
            ) : null}

            <HiddenInputsArray
              inputs={[
                {
                  name: getInputProps(fields.providerId, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.providerId,
                  errors: fields.providerId.errors,
                },
                {
                  name: getInputProps(fields.equipmentPriceRuleId, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.equipmentPriceRuleId?.toString(),
                  errors: fields.equipmentPriceRuleId.errors,
                },
                {
                  name: getInputProps(fields.address, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.address,
                  errors: fields.address.errors,
                },
                {
                  name: getInputProps(fields.placeId, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.placeId,
                  errors: fields.placeId.errors,
                },
                {
                  name: getInputProps(fields.startDate, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.startDate,
                  errors: fields.startDate.errors,
                },
                {
                  name: getInputProps(fields.endDate, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.endDate,
                  errors: fields.endDate.errors,
                },
                {
                  name: getInputProps(fields.customerType, {
                    type: "hidden",
                  }).name,
                  value:
                    selectedFilters.isProfessional === "1"
                      ? "professionnal"
                      : "individual",
                  errors: fields.customerType.errors,
                },
                {
                  name: "action",
                  value: "quote" as z.infer<
                    typeof GenerateLeadQuoteSchema
                  >["action"],
                  errors: fields.action.errors,
                },
              ]}
            />
            <div className="flex flex-col gap-2">
              <ClintButton
                variant="primary"
                as="Button"
                type="submit"
                labelSize="L"
                form={form.id}
                isLoading={isLoading}
                disabled={isLoading}
                className="w-full"
              >
                Recevoir le devis par mail
              </ClintButton>
              {/* <ClintButton
                variant={"tertiary"}
                as="Button"
                type="button"
                onClick={() => {
                  setIsSearchFiltersOpen(true);
                }}
              >
                Modifier ma recherche
              </ClintButton>
              <Paragraph size="xs">
                En cas de changement d'adresse, nous devons recalculer nos
                offres.
              </Paragraph> */}
            </div>
          </div>
        </getQuoteFetcher.Form>
      </MarketplacePanel>
    </>
  );
};
export const FiltersModal = ({
  selectedFilters,
  setSelectedFilters,
}: {
  selectedFilters: ReturnType<
    typeof useMarketplaceSearchBar
  >["selectedFilters"];
  setSelectedFilters: ReturnType<
    typeof useMarketplaceSearchBar
  >["setSelectedFilters"];
}) => {
  const wasteTypes = useWasteTypes();
  const isLoading = useNavigation().state === "loading";
  return (
    <ModalWrapper
      isOpen={selectedFilters["search-modal"]}
      childrenClassName="overflow-visible"
      className="overflow-visible"
      setIsOpen={() =>
        setSelectedFilters({ "search-modal": !selectedFilters["search-modal"] })
      }
      modalTitle="Modifier ma recherche"
      footer={
        <Form method="GET" action="/solutions/rechercher">
          <HiddenInputsArray
            inputs={[
              { name: "address", value: selectedFilters.address },
              { name: "placeId", value: selectedFilters.placeId },
              { name: "isProfessional", value: selectedFilters.isProfessional },
              { name: "waste", value: selectedFilters.waste?.toString() || "" },
              { name: "service", value: selectedFilters.service?.toString() },
              { name: "step", value: selectedFilters.waste ? "2" : "1" },
            ]}
          />
          <ClintButton
            as="Button"
            type="submit"
            variant="primary"
            isLoading={isLoading}
            disabled={isLoading}
            className="w-full"
          >
            Nouvelle recherche
          </ClintButton>
        </Form>
      }
    >
      <div className="flex flex-col gap-6">
        <SwitchBar
          options={[
            {
              name: "Professionnel",
              value: "1",
            },
            {
              name: "Particulier",
              value: "0",
            },
          ]}
          defaultOption={selectedFilters.isProfessional === "1" ? "1" : "0"}
          onSwitch={(newValue) => {
            setSelectedFilters(
              {
                isProfessional: newValue === "1" ? "1" : "0",
              },
              {
                shallow: true,
              },
            );
          }}
        />
        <GeneralCombobox
          type="single"
          options={wasteTypes.map((wt) => ({
            id: wt.gcId.toString(),
            name: wt.name,
          }))}
          icon={<Trash />}
          selectedOptionId={selectedFilters.waste?.toString()}
          onChange={(newWasteValue) => {
            setSelectedFilters(
              {
                waste: Number(newWasteValue),
              },
              {
                shallow: true,
              },
            );
          }}
        />
        <div className="flex flex-col gap-2">
          <AddressCombobox
            inputName="address"
            inputProps={{
              value: selectedFilters.address,
            }}
            labelProps={{
              children: "Adresse de livraison",
            }}
            onAddressChange={(newAddress) => {
              setSelectedFilters(
                {
                  address: newAddress.address,
                  placeId: newAddress.placeId,
                },
                {
                  shallow: true,
                },
              );
            }}
            placeIdInputName="placeId"
            defaultAddressValue={selectedFilters.address}
            defaultPlaceIdValue={selectedFilters.placeId}
          />
          <Paragraph size="xs" variant="secondaryDarker">
            En cas de changement d'adresse, nous devons recalculer nos offres.
          </Paragraph>
        </div>
      </div>
    </ModalWrapper>
  );
};

const OfferBookingSection = ({
  setSelectedFilters,
  selectedFilters,
}: {
  setSelectedFilters: MarketplaceSearchParamsQueryStatesProps["setSelectedFilters"];
  selectedFilters: MarketplaceSearchParamsQueryStatesProps["selectedFilters"];
}) => {
  const { offer, offerItemDescription, customerPaymentMethods } =
    usePublicLayout() || {};
  const [rerenderError, setRerenderError] = useState(false);

  useEffect(() => {
    if (!offer) return;
    setSelectedFilters({
      equipmentPriceRuleId: offer.offer.equipment.priceRuleId,
      providerId: offer.offer.provider.id,
    });
  }, [offer]);

  if (!offer || !offerItemDescription) return null;

  const {
    pricingDetails: {
      bookingPrices: { priceTTC },
    },
  } = offer;

  const user = useOptionalUser();
  const actionData = useActionData<typeof action>();

  const [localStorageData, setLocalStorageData] = useLocalStorage<{
    startDate: string;
    endDate: string;
    firstname: string;
    lastname: string;
    phone: string;
    purchaseOrder?: string;
    comment: string;
  }>("book-details", {
    comment: "",
    endDate: "",
    firstname: "",
    lastname: "",
    phone: "",
    startDate: "",
    purchaseOrder: "",
  });

  const hasEnabledAutomaticCharge = user?.isLargeAccount
    ? false
    : customerPaymentMethods?.creditCards.some((c) => c.isDefault) ||
      customerPaymentMethods?.sepaDebits.some((c) => c.isDefault) ||
      false;
  const [form, fields] = useForm({
    id: "create-booking-form",
    lastResult: actionData?.result,
    constraint: getZodConstraint(GenerateBookingSchema),
    onValidate({ formData }) {
      const parsed = parseWithZod(formData, {
        schema: GenerateBookingSchema,
      });
      if (parsed.status === "error") {
        setRerenderError((value) => !value);
      }

      return parsed;
    },
    defaultValue: {
      automaticCharge: hasEnabledAutomaticCharge,
      comment: localStorageData.comment,
      contactFirstName: localStorageData.firstname,
      contactLastName: localStorageData.lastname,
      contactPhone: localStorageData.phone,
      startDate: localStorageData.startDate,
      endDate: localStorageData.endDate,
      purchaseOrder: localStorageData.purchaseOrder,
    },
  });

  const isLoading = useIsSubmitting();
  const [sameDeliveryAddressForBilling, setSameDeliveryAddressForBilling] =
    useState(true);

  const [phone, setPhone] = useState(localStorageData.phone || "");
  const [siret, setSiret] = useState("");

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const customerFields = fields.customer.getFieldset();
  const submitLogout = useSubmit();
  const MobileBookAndQuoteButtons = ({ className }: { className?: string }) => {
    return (
      <div
        className={cn("flex w-full flex-col gap-2.5 rounded-t-lg", className)}
      >
        <div className="flex justify-between gap-2 pb-3.5 lg:py-4 lg:pb-6">
          <Paragraph
            size="md"
            variant="secondaryDarker"
            className="font-medium"
          >
            À régler maintenant
          </Paragraph>
          <Headline size="h5" variant="secondaryDarker">
            {formatPriceWithCurrency(priceTTC)}
          </Headline>
        </div>

        <ErrorList errors={form.errors} />
        <div className="flex w-full flex-row items-center gap-2.5">
          <ClintButton
            disabled={isLoading}
            isLoading={isLoading}
            as="Button"
            variant="primary"
            name="action"
            value="booking"
            type="submit"
            // className="h-12 grow lg:h-[52px]"
            className="grow"
            labelSize="L"
            form={form.id}
            onClick={() => {
              if (typeof window === "undefined") return;
              window.localStorage.removeItem("book-details");
            }}
          >
            Finaliser
          </ClintButton>
          <Paragraph
            size="sm"
            className="hidden lg:block"
            variant={"secondary"}
          >
            ou
          </Paragraph>
          <ClintButton
            disabled={isLoading}
            isLoading={isLoading}
            as="Button"
            variant="tertiary"
            type="button"
            onClick={() => {
              setSelectedFilters({
                equipmentPriceRuleId: offer.offer.equipment.priceRuleId,
                providerId: offer.offer.provider.id,
                "show-quote": true,
              });
            }}
            name="action"
            value="quote"
            className="h-12 grow lg:h-[52px]"
            labelSize="L"
          >
            Obtenir un devis
          </ClintButton>
        </div>

        {selectedFilters.plans === "1" ? null : (
          <Label
            size="XS"
            variant={"secondary"}
            className="text-center text-gray-500"
          >
            Traitement et location facturés en fin de prestation au réel
          </Label>
        )}
      </div>
    );
  };

  return (
    <>
      <MobileBookAndQuoteButtons className="fixed bottom-0 z-20 flex w-full flex-col border-t border-gray-300 bg-gray-100 p-4 lg:hidden" />

      <LoginModal isOpen={isLoginOpen} setIsOpen={setIsLoginOpen} />

      <div className="flex items-center gap-4 px-5 py-4 lg:px-10">
        <button
          type="button"
          onClick={() => {
            setSelectedFilters({
              step: "3",
            });
          }}
          className={TagsVariants({
            type: "Square",
            variant: "Light",
            className: "flex aspect-square items-center justify-center",
          })}
        >
          <ArrowLeft className="size-4" aria-hidden="true" />
        </button>
        <Headline size={"h6"} as="div">
          Demande de réservation
        </Headline>
      </div>
      <div className="relative flex h-full w-full flex-col-reverse gap-4 bg-gray-100 px-0 lg:min-h-screen lg:flex-row lg:px-10">
        <div className="flex w-full max-w-full flex-1 grow flex-col items-start gap-10 lg:basis-[750px]">
          <div className="flex w-full flex-col gap-4">
            <Form
              {...getFormProps(form)}
              method="POST"
              className="flex flex-col gap-6 bg-white p-5 lg:rounded-[8px] lg:p-6"
            >
              <button type="submit" className="hidden" />
              <div className="flex flex-col gap-4">
                <Headline size="h5" variant={"secondary"}>
                  À quelle date ?
                </Headline>
                <Paragraph size="md" variant={"secondary"}>
                  Merci de bien fournir les dates réelles de votre réservation.
                </Paragraph>
              </div>
              <TwoSidedFormInput
                leftComponent={
                  <DatepickerInput
                    key={rerenderError ? "error" : "noError"}
                    inputProps={getInputProps(fields.startDate, {
                      type: "text",
                    })}
                    onDateChange={(newDates) => {
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        startDate: newDates.startDate,
                      }));
                      setSelectedFilters(
                        {
                          startDate: newDates.startDate,
                        },
                        {
                          history: "push",
                          shallow: false,
                        },
                      );
                    }}
                    selectedStartDate={selectedFilters.startDate}
                    selectedEndDate=""
                    labelProps={{ children: "Date de dépot" }}
                    showRecurring
                    errors={fields.startDate.errors}
                  />
                }
                rightComponent={
                  <DatepickerInput
                    inputProps={getInputProps(fields.endDate, { type: "text" })}
                    minDate={selectedFilters.startDate}
                    onDateChange={(newDates) => {
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        endDate: newDates.startDate,
                      }));
                      setSelectedFilters(
                        {
                          endDate: newDates.startDate,
                        },
                        {
                          history: "push",
                          shallow: false,
                        },
                      );
                    }}
                    selectedStartDate={selectedFilters.endDate}
                    selectedEndDate=""
                    labelProps={{ children: "Date de retrait (optionnelle)" }}
                    showRecurring
                    errors={fields.endDate.errors}
                  />
                }
              />
              {/* <div className="my-0 w-full border-t border-gray-200" /> */}
              {/* <div className="flex flex-col gap-2"> */}
              {/* <DatepickerInput
                  minDate={selectedDates.endDate}
                  onDateChange={(newDates) => {
                    setSelectedDates((oldDates) => ({
                      ...oldDates,
                      additionalDate: newDates.startDate,
                    }));
                  }}
                  selectedStartDate={selectedDates.additionalDate}
                  selectedEndDate=""
                  labelProps={{ children: "Date de rotation" }}
                  showRecurring
                // errors={fields.endDate.errors}
                /> */}
              {/* <Paragraph
                  size="xs"
                  variant={"secondary"}
                  className="text-gray-500 underline"
                >
                  Supprimer
                </Paragraph>
                <ClintButton as="Button" type="button" variant="tertiary">
                  Ajouter une date de rotation supplémentaire
                </ClintButton> */}
              {/* </div> */}
              <div className="my-2 w-full border-t border-gray-200" />
              <div className="flex flex-col gap-4">
                <Headline size="h5" variant={"secondary"}>
                  Adresse de livraison
                </Headline>

                <Field
                  labelProps={{ children: "Adresse" }}
                  inputProps={{
                    ...getInputProps(fields.address, { type: "text" }),
                    disabled: true,
                    value: selectedFilters.address,
                    autoComplete: "given-name",
                  }}
                  errors={fields.address.errors}
                />

                <TwoSidedFormInput
                  leftComponent={
                    <Field
                      labelProps={{ children: "Prénom" }}
                      inputProps={{
                        ...getInputProps(fields.contactFirstName, {
                          type: "text",
                        }),

                        autoComplete: "given-name",
                        onChange(newValue) {
                          setLocalStorageData((oldData) => ({
                            ...oldData,
                            firstname: newValue.target.value,
                          }));
                        },
                      }}
                      errors={fields.contactFirstName.errors}
                    />
                  }
                  rightComponent={
                    <Field
                      labelProps={{ children: "Nom" }}
                      inputProps={{
                        ...getInputProps(fields.contactLastName, {
                          type: "text",
                        }),
                        autoComplete: "family-name",
                        onChange(newValue) {
                          setLocalStorageData((oldData) => ({
                            ...oldData,
                            lastname: newValue.target.value,
                          }));
                        },
                      }}
                      errors={fields.contactLastName.errors}
                    />
                  }
                />

                <PhoneField
                  labelProps={{ children: "Téléphone" }}
                  inputProps={{
                    ...getInputProps(fields.contactPhone, { type: "tel" }),
                    autoComplete: "phone",
                    onChange: (newPhone: string) => {
                      setPhone(newPhone);
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        phone: newPhone,
                      }));
                    },
                    value: phone,
                  }}
                  errors={fields.contactPhone.errors}
                />

                <Field
                  labelProps={{ children: "Bon de commande (facultatif)" }}
                  inputProps={{
                    ...getInputProps(fields.purchaseOrder, { type: "text" }),
                    onChange(newValue) {
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        purchaseOrder: newValue.target.value,
                      }));
                    },
                  }}
                  errors={fields.purchaseOrder.errors}
                />
                <TextareaField
                  textareaProps={{
                    ...getTextareaProps(fields.comment),
                    rows: 5,
                    placeholder:
                      "Merci de nous préciser toute information que vous jugerez importante en terme d'accessibilité du lieu de dépose de la benne, déchet que vous souhaitez mettre dans la benne, contrainte horaire de dépose de la benne. ",
                    onChange(newValue) {
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        comment: newValue.target.value,
                      }));
                    },
                  }}
                  labelProps={{ children: "Commentaire libre" }}
                  errors={fields.comment.errors}
                />

                <div className="my-2 w-full border-t border-gray-200 lg:my-2" />

                <div className="flex flex-col gap-4">
                  <Headline size="h5" variant={"secondary"}>
                    Adresse de facturation
                  </Headline>

                  {user ? (
                    <>
                      <div className="flex items-center gap-2">
                        <Paragraph size="md">
                          {user.firstname} {user.lastname}
                        </Paragraph>
                        <Paragraph
                          size="md"
                          variant={"secondary"}
                          className="text-gray-600"
                        >
                          ({user.email})
                        </Paragraph>
                      </div>
                      <div className="flex items-center gap-1">
                        <Paragraph size="md" variant={"secondary"}>
                          Mauvais compte ?
                        </Paragraph>
                        <button
                          type="button"
                          name="action"
                          value="logout"
                          onClick={() => {
                            submitLogout(
                              {
                                action: "logout",
                              },
                              {
                                method: "POST",
                              },
                            );
                          }}
                          className={cn(
                            paragraphVariants({ size: "md" }),
                            "font-bold underline",
                          )}
                        >
                          Se déconnecter
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center gap-1">
                        <Paragraph size="md" variant={"secondary"}>
                          Vous êtes déjà client ?
                        </Paragraph>
                        <button
                          type="button"
                          onClick={() => {
                            setIsLoginOpen(true);
                          }}
                          className={cn(
                            paragraphVariants({ size: "md" }),
                            "font-bold underline",
                          )}
                        >
                          Se connecter
                        </button>
                      </div>
                      <SwitchBar
                        itemClassName="grow"
                        className="w-full"
                        options={[
                          {
                            name: "Professionnel",
                            value: "1",
                          },
                          {
                            name: "Particulier",
                            value: "0",
                          },
                        ]}
                        defaultOption={
                          selectedFilters.isProfessional === "1" ? "1" : "0"
                        }
                        onSwitch={(newIsProfessional) => {
                          setSelectedFilters({
                            isProfessional:
                              newIsProfessional === "1" ? "1" : "0",
                          });
                        }}
                      />
                    </>
                  )}

                  <CheckboxField
                    key={sameDeliveryAddressForBilling ? "true" : "false"}
                    buttonProps={{
                      defaultChecked: sameDeliveryAddressForBilling,
                      form: form.id,
                      name: "sameDeliveryAddressForBilling",
                      onCheckedChange: () => {
                        setSameDeliveryAddressForBilling(
                          (oldValue) => !oldValue,
                        );
                      },
                    }}
                    labelProps={{
                      children:
                        "L'adresse de facturation est la même que l'adresse de livraison",
                      className:
                        "text-secondary peer-data-[state=checked]:text-black",
                    }}
                  />

                  {sameDeliveryAddressForBilling ? (
                    <>
                      <HiddenInputsArray
                        inputs={[
                          {
                            name: getInputProps(fields.billingAddress, {
                              type: "hidden",
                            }).name,
                            value: selectedFilters.address,
                          },
                          {
                            name: getInputProps(fields.billingPlaceId, {
                              type: "hidden",
                            }).name,
                            value: selectedFilters.placeId,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <AddressCombobox
                      theme="default"
                      inputName={
                        getInputProps(fields.billingAddress, {
                          type: "text",
                        }).name
                      }
                      placeIdInputName={
                        getInputProps(fields.billingPlaceId, {
                          type: "text",
                        }).name
                      }
                      inputProps={{
                        ...getInputProps(fields.billingAddress, {
                          type: "text",
                        }),
                      }}
                      labelProps={{
                        children: "Adresse de facturation",
                      }}
                    />
                  )}

                  {customerPaymentMethods &&
                  (customerPaymentMethods.creditCards.filter((c) => c.isDefault)
                    .length > 0 ||
                    customerPaymentMethods.sepaDebits.filter((c) => c.isDefault)
                      .length > 0) &&
                  !user?.isLargeAccount ? (
                    <div className="flex flex-col flex-wrap gap-2">
                      <CheckboxField
                        labelProps={{
                          children: `Utiliser le moyen de paiement par défaut`,
                          className:
                            "text-secondary peer-data-[state=checked]:text-black",
                        }}
                        buttonProps={{
                          ...getInputProps(fields.automaticCharge, {
                            type: "checkbox",
                          }),
                        }}
                        errors={fields.automaticCharge.errors}
                      />
                      {customerPaymentMethods?.creditCards
                        .filter((c) => c.isDefault)
                        .map((c) => (
                          <PaymentMethodCard
                            className="w-fit"
                            key={c.id}
                            creditCard={c}
                          />
                        ))}
                      {customerPaymentMethods?.sepaDebits
                        .filter((c) => c.isDefault)
                        .map((c) => (
                          <PaymentMethodSepa
                            className="w-fit"
                            key={c.id}
                            sepaDebit={c}
                          />
                        ))}
                    </div>
                  ) : null}

                  {user ? null : (
                    <fieldset
                      {...getFieldsetProps(fields.customer)}
                      className="flex flex-col gap-4"
                    >
                      <ErrorList errors={fields.customer.errors} />

                      <input
                        {...getInputProps(customerFields.customerType, {
                          type: "hidden",
                        })}
                        value={
                          selectedFilters.isProfessional === "1"
                            ? "professionnal"
                            : "individual"
                        }
                      />
                      <TwoSidedFormInput
                        leftComponent={
                          <Field
                            labelProps={{ children: "Prénom" }}
                            inputProps={{
                              ...getInputProps(customerFields.firstname, {
                                type: "text",
                              }),

                              autoComplete: "given-name",
                            }}
                            errors={customerFields.firstname.errors}
                          />
                        }
                        rightComponent={
                          <Field
                            labelProps={{ children: "Nom" }}
                            inputProps={{
                              ...getInputProps(customerFields.lastname, {
                                type: "text",
                              }),
                              autoComplete: "family-name",
                            }}
                            errors={customerFields.lastname.errors}
                          />
                        }
                      />

                      <Field
                        labelProps={{ children: "Email" }}
                        inputProps={{
                          ...getInputProps(customerFields.email, {
                            type: "email",
                          }),
                          autoComplete: "email",
                        }}
                        errors={customerFields.email.errors}
                      />

                      <PhoneField
                        labelProps={{ children: "Téléphone" }}
                        inputProps={{
                          ...getInputProps(customerFields.phone, {
                            type: "tel",
                          }),
                          autoComplete: "phone",
                          onChange: (newPhone: string) => {
                            setPhone(newPhone);
                          },
                          value: phone,
                        }}
                        errors={customerFields.phone.errors}
                      />

                      {selectedFilters.isProfessional === "1" ? (
                        <>
                          <Field
                            labelProps={{ children: "Société" }}
                            inputProps={{
                              ...getInputProps(customerFields.companyName, {
                                type: "text",
                              }),
                              autoComplete: "company",
                            }}
                            errors={customerFields.companyName.errors}
                          />

                          <Field
                            labelProps={{ children: "SIRET" }}
                            inputProps={{
                              ...getInputProps(customerFields.siret, {
                                type: "text",
                              }),
                              inputMode: "numeric",
                              pattern: "[0-9 ]*",

                              onChange: (e) =>
                                setSiret(formatSiret(e.target.value)),
                              value: siret,
                            }}
                            errors={customerFields.siret.errors}
                          />

                          <Field
                            labelProps={{ children: "Numéro de TVA" }}
                            inputProps={{
                              ...getInputProps(customerFields.tvaNumber, {
                                type: "text",
                              }),
                              maxLength: 13,
                            }}
                            errors={customerFields.tvaNumber.errors}
                          />
                        </>
                      ) : null}
                    </fieldset>
                  )}
                </div>

                <div
                  className={cn(`w-full border-t border-gray-200`, {
                    "my-2": user,
                    "my-5 lg:my-8": !user,
                  })}
                />

                {user ? (
                  <input
                    {...getInputProps(fields.hasAcceptedTerms, {
                      type: "hidden",
                    })}
                    value={"on"}
                  />
                ) : (
                  <>
                    <div className="flex flex-col gap-4">
                      <Label size="M" variant={"secondary"}>
                        Un compte GoodCollect sera créé avec vos informations
                        afin de suivre votre commande.
                      </Label>
                      <CheckboxField
                        buttonProps={{
                          ...getInputProps(fields.hasAcceptedTerms, {
                            type: "checkbox",
                          }),
                        }}
                        labelProps={{
                          children:
                            "J'ai lu et j'accepte les informations de location, les conditions générales, et la politique de confidentialité, et j'ai connaissance que je réserve avec un montant prépayé, impliquant que le prix total de la location soit déduit immédiatement de la carte de crédit ou de débit que j'ai fournie.",
                          className: "font-normal",
                        }}
                        errors={fields.hasAcceptedTerms.errors}
                      />
                    </div>
                  </>
                )}

                <MobileBookAndQuoteButtons
                  className={cn("hidden lg:flex", {
                    "pt-0": user,
                    "pt-5": !user,
                  })}
                />

                <HiddenInputsArray
                  inputs={[
                    {
                      name: getInputProps(fields.status, {
                        type: "hidden",
                      }).name,
                      value: user
                        ? "logged-in"
                        : ("logged-out" as z.infer<
                            typeof GenerateBookingSchema
                          >["status"]),
                    },
                    {
                      name: getInputProps(fields.providerId, {
                        type: "hidden",
                      }).name,
                      value: offer.offer.provider.id,
                    },
                    {
                      name: getInputProps(fields.equipmentPriceRuleId, {
                        type: "hidden",
                      }).name,
                      value: offer.offer.equipment.priceRuleId.toString(),
                    },
                    {
                      name: getInputProps(fields.address, {
                        type: "hidden",
                      }).name,
                      value: selectedFilters.address,
                    },
                    {
                      name: getInputProps(fields.placeId, {
                        type: "hidden",
                      }).name,
                      value: selectedFilters.placeId,
                    },
                    {
                      name: getInputProps(fields.startDate, {
                        type: "hidden",
                      }).name,
                      value: selectedFilters.startDate,
                    },
                    {
                      name: getInputProps(fields.endDate, {
                        type: "hidden",
                      }).name,
                      value: selectedFilters.endDate,
                    },
                  ]}
                />
              </div>
            </Form>
          </div>
        </div>
        <BookingPriceRecap
          transportRotationPriceHT={
            offer.pricingDetails.transportData.transportRotationPriceHT
          }
          transportRotationPriceTTC={
            offer.pricingDetails.transportData.transportRotationPriceTTC
          }
          selectedFilters={{
            address: selectedFilters.address,
            immediatePickup: selectedFilters.immediatePickup,
            isProfessional: selectedFilters.isProfessional,
            plans: selectedFilters.plans,
            isRecurring: selectedFilters.isRecurring,
          }}
          providerName={offer.offer.provider.name}
          setSelectedFilters={setSelectedFilters}
          equipment={offer.offer.equipment}
          waste={offer.offer.waste}
          priceHT={offer.pricingDetails.bookingPrices.priceHT}
          priceTTC={offer.pricingDetails.bookingPrices.priceTTC}
          tvaFee={offer.pricingDetails.bookingPrices.tvaFee}
          transportDeliveryPriceHT={
            offer.pricingDetails.transportData.transportDeliveryPriceHT
          }
          transportDeliveryPriceTTC={
            offer.pricingDetails.transportData.transportDeliveryPriceTTC
          }
          transportPickupPriceHT={
            offer.pricingDetails.transportData.transportPickupPriceHT
          }
          transportPickupPriceTTC={
            offer.pricingDetails.transportData.transportPickupPriceTTC
          }
          treatmentPricePerTonHT={
            offer.pricingDetails.treatmentData.treatmentPricePerTonHT
          }
          treatmentPricePerTonTTC={
            offer.pricingDetails.treatmentData.treatmentPricePerTonTTC
          }
          rentPricePerDayHT={offer.pricingDetails.rentData.rentPricePerDayHT}
          rentPriceTTC={offer.pricingDetails.rentData.rentPriceTTC}
          rentDays={offer.pricingDetails.rentData.rentDays}
          treatmentPriceHT={offer.pricingDetails.treatmentData.treatmentPriceHT}
          treatmentPriceTTC={
            offer.pricingDetails.treatmentData.treatmentPriceTTC
          }
          billedHours={offer.pricingDetails.rentData.billedHours}
          doubleRotationBennePrice={
            offer.offer.provider.doubleRotationBennePrice
          }
          equipmentWeight={offer.offer.equipmentWeight}
          pricePerHourHT={offer.pricingDetails.rentData.pricePerHourHT}
          pricePerHourTTC={offer.pricingDetails.rentData.pricePerHourTTC}
          rentPriceHT={offer.pricingDetails.rentData.rentPriceHT}
          rentPricePerDayTTC={offer.pricingDetails.rentData.rentPriceTTC}
        />
      </div>
    </>
  );
};

const LoginModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const location = useLocation();
  const fullUrl = `${location.pathname}${location.search}`;
  const actionData = useActionData<typeof action>();

  const [form, fields] = useForm({
    id: "login-form",
    constraint: getZodConstraint(LoginSchema),
    onValidate({ formData }) {
      const parsed = parseWithZod(formData, {
        schema: LoginSchema,
      });

      return parsed;
    },
    lastResult: actionData?.result,
  });

  const isLoading = useIsSubmitting();
  return (
    <ModalWrapper
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      modalTitle="Connexion"
      footer={
        <div className="flex w-full items-center justify-center">
          <ClintButton
            as="Button"
            form={form.id}
            disabled={isLoading}
            isLoading={isLoading}
            type="submit"
            variant="primary"
            name="action"
            value="login"
            className="w-full"
          >
            Se connecter
          </ClintButton>
        </div>
      }
    >
      <Form
        {...getFormProps(form)}
        method="POST"
        className="flex flex-col gap-4"
      >
        <Field
          inputProps={{ ...getInputProps(fields.email, { type: "email" }) }}
          labelProps={{
            children: "Email",
          }}
          errors={fields.email.errors}
        />

        <Field
          inputProps={{
            ...getInputProps(fields.password, { type: "password" }),
          }}
          labelProps={{
            children: "Mot de passe",
          }}
          errors={fields.password.errors}
        />
        <input type="hidden" name="redirectTo" value={fullUrl} />
        <Link to={`/forgot-password?redirectTo${fullUrl}`}>
          <Paragraph size="md" fontWeight={"bold"} className="underline">
            Mot de passe oublié ?
          </Paragraph>
        </Link>
      </Form>
    </ModalWrapper>
  );
};

const OffersListSectionLoading = () => {
  return (
    <div className="relative flex h-full w-full flex-col-reverse bg-gray-100 lg:min-h-screen lg:flex-row">
      {/* Side Filters Loading */}
      <div className="hidden lg:block lg:w-[280px]">
        <div className="flex h-[88px] w-full items-center border border-l-0 border-gray-200 bg-white px-10">
          <Skeleton className="h-6 w-32" />
        </div>
        <div className="flex flex-col gap-6 p-6">
          {Array.from({ length: 3 }).map((_, i) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <div key={i} className="flex flex-col gap-3">
              <Skeleton className="h-5 w-40" />
              {Array.from({ length: 4 }).map((_, j) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <div key={j} className="flex items-center gap-2">
                  <Skeleton className="h-4 w-4" />
                  <Skeleton className="h-4 w-32" />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Main Content Loading */}
      <div className="flex w-full flex-col">
        <div className="flex h-[88px] w-full items-center gap-6 border border-l-0 border-gray-200 bg-white px-10">
          {Array.from({ length: 3 }).map((_, i) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <Skeleton key={i} className="h-10 w-32" />
          ))}
        </div>

        <div className="flex flex-col gap-6 p-6">
          {/* Best Offer Section */}
          <div className="flex w-full flex-col gap-6 rounded-[8px] bg-white p-6">
            <div className="flex flex-col gap-2">
              <Skeleton className="h-7 w-64" />
              <Skeleton className="h-5 w-96" />
            </div>

            {/* Offer Card Loading */}
            <div className="flex flex-col gap-4 rounded-lg border border-gray-200 p-6">
              <div className="flex items-start justify-between">
                <div className="flex flex-col gap-2">
                  <Skeleton className="h-6 w-48" />
                  <Skeleton className="h-5 w-32" />
                </div>
                <Skeleton className="h-20 w-20" />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex flex-col gap-1">
                  <Skeleton className="h-6 w-24" />
                  <Skeleton className="h-4 w-16" />
                </div>
                <div className="flex gap-3">
                  <Skeleton className="h-10 w-32" />
                  <Skeleton className="h-10 w-32" />
                </div>
              </div>
            </div>
          </div>

          {/* Additional Offers Loading */}
          {Array.from({ length: 2 }).map((_, i) => (
            <div
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={i}
              className="flex w-full flex-col gap-6 rounded-[8px] bg-white p-6"
            >
              <div className="flex flex-col gap-4 rounded-lg border border-gray-200 p-6">
                <div className="flex items-start justify-between">
                  <div className="flex flex-col gap-2">
                    <Skeleton className="h-6 w-48" />
                    <Skeleton className="h-5 w-32" />
                  </div>
                  <Skeleton className="h-20 w-20" />
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-1">
                    <Skeleton className="h-6 w-24" />
                    <Skeleton className="h-4 w-16" />
                  </div>
                  <div className="flex gap-3">
                    <Skeleton className="h-10 w-32" />
                    <Skeleton className="h-10 w-32" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const OfferDetailSectionLoading = () => {
  return (
    <div className="relative flex h-full w-full flex-col-reverse bg-gray-100 lg:min-h-screen lg:flex-row">
      {/* Main Content Loading */}
      <div className="flex w-full flex-col">
        <div className="flex h-[88px] w-full items-center gap-4 px-5 py-4 lg:px-10">
          <Skeleton className="h-10 w-10" />
          <Skeleton className="h-6 w-48" />
        </div>

        <div className="flex flex-col gap-6 p-6">
          {/* Offer Details Card */}
          <div className="flex w-full flex-col gap-6 rounded-[8px] bg-white p-6">
            <div className="flex items-start justify-between">
              <div className="flex flex-col gap-4">
                <Skeleton className="h-8 w-64" />
                <Skeleton className="h-6 w-96" />
                <div className="flex gap-3">
                  {Array.from({ length: 3 }).map((_, i) => (
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    <Skeleton key={i} className="h-8 w-24" />
                  ))}
                </div>
              </div>
              <Skeleton className="h-32 w-32" />
            </div>

            {/* Price Details */}
            <div className="flex flex-col gap-4 rounded-lg border border-gray-200 p-6">
              <Skeleton className="h-7 w-48" />
              {Array.from({ length: 4 }).map((_, i) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <div key={i} className="flex items-center justify-between">
                  <Skeleton className="h-5 w-32" />
                  <Skeleton className="h-5 w-24" />
                </div>
              ))}
            </div>

            {/* Action Buttons */}
            <div className="flex gap-3">
              <Skeleton className="h-12 w-40" />
              <Skeleton className="h-12 w-40" />
            </div>
          </div>
        </div>
      </div>

      {/* Side Panel Loading */}
      <div className="hidden lg:block lg:w-[400px]">
        <div className="flex h-[88px] w-full items-center border-l border-gray-200 bg-white px-10">
          <Skeleton className="h-6 w-32" />
        </div>
        <div className="flex flex-col gap-6 p-6">
          <Skeleton className="h-40 w-full rounded-lg" />
          <div className="flex flex-col gap-3">
            {Array.from({ length: 3 }).map((_, i) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <Skeleton key={i} className="h-6 w-full" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
