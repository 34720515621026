import z from "zod";

export const PaginationSchema = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  totalItems: z.number(),
  itemsPerPage: z.number(),
  maxItemsPerPage: z.number(),
});

// export enum CustomerTypes {
//   INDIVIDUAL = 10,
//   PROFESSIONAL = 20,
// }

export enum ProviderDocumentTypes {
  BOOKING_DOWNGRADING_DOCUMENT = 0,
  BOOKING_WEIGHT_TICKET = 10,
}

export enum CustomerDocumentTypes {
  BOOKING_DOWN_PAYMENT_INVOICE = 0, // Facture d'acompte
  BOOKING_FINAL_PAYMENT_INVOICE = 10, // Facture finale
  BOOKING_QUOTE = 20, // Devis
  BOOKING_DOWNGRADING_INVOICE = 40, // Facture finale avec  déclassement
  BOOKING_PLAN_INVOICE = 50, // Facture de forfait
  BOOKING_PLAN_DOWNGRADING_INVOICE = 60, // Facture de forfait complémentaire
}

//! Ces données sont tirées du fichier Excel "DB Nomenclature"

export const bookingStatuses = {
  EXTERNAL_BOOKING_DRAFT: {
    id: -10,
    name: "Brouillon (booking externe)",
    short: "Brouillon",
  },
  EXTERNAL_BOOKING_QUOTE: {
    id: -9,
    name: "Devis (booking externe)",
    short: "Devis",
  },
  EXTERNAL_BOOKING_QUOTE_REFUSED: {
    id: -8,
    name: "Devis refusé (booking externe)",
    short: "Devis refusé",
  },
  EXTERNAL_BOOKING_QUOTE_ACCEPTED: {
    id: -7,
    name: "Devis accepté (booking externe)",
    short: "Devis accepté",
  },
  STRIPE_PENDING_PAYMENT: {
    id: 0,
    name: "Booking placé en attente de paiement",
    short: "En attente paiement",
  },
  INVOICE_DOWN_PAYMENT_PAID: {
    id: 5,
    name: "Facture d'acompte payée",
    short: "Acompte payé",
  },

  PENDING_PROVIDER_CONFIRMATION: {
    id: 10,
    name: "Booking placé en attente de confirmation prestataire",
    short: "En attente confirmation",
  },
  CONFIRMED: {
    id: 20,
    name: "Booking confirmé par le prestataire",
    short: "Confirmé",
  },
  IN_PROGRESS: {
    id: 40,
    name: "Booking en cours",
    short: "Booking en cours",
  },

  FINALIZED_PENDING_PROVIDER_CONFIRMATION: {
    id: 51,
    name: "Booking finalisé en attente de confirmation du prestataire",
    short: "Booking finalisé, en attente",
  },
  FINALIZED_PENDING_CUSTOMER_CONFIRMATION: {
    id: 52,
    name: "Booking finalisé en attente de confirmation du client",
    short: "Booking finalisé, en attente",
  },
  FINALIZED_PENDING_CUSTOMER_CONFIRMATION_AND_DOWNGRADING: {
    id: 53,
    name: "Booking finalisé en attente de confirmation du client et déclassement",
    short: "Booking finalisé, en attente",
  },
  FINALIZED_DOWNGRADING_IN_PROGRESS: {
    id: 54,
    name: "Booking finalisé, déclassement en cours de validation",
    short: "Booking finalisé, en attente",
  },
  FINALIZED_DOWNGRADING_VALIDATED_PENDING_CUSTOMER_CONFIRMATION: {
    id: 55,
    name: "Booking finalisé, déclassement validé par un administrateur",
    short: "Booking finalisé, en attente",
  },
  CLOSED_PENDING_FINAL_INVOICE_PAYMENT: {
    id: 60,
    name: "Booking clôturé, paiement en cours",
    short: "Booking cloturé",
  },
  CLOSED_FINAL_INVOICE_PAID: {
    id: 61,
    name: "Booking clôturé, payé",
    short: "Booking clôturé, payé",
  },
  REFUSED_BY_PROVIDER: {
    id: 90,
    name: "Booking refusé par le prestataire",
    short: "Booking refusé",
  },
  CANCELLED_BY_CUSTOMER: {
    id: 91,
    name: "Booking annulé par le client",
    short: "Booking annulé",
  },
  CANCELLED_BY_PROVIDER: {
    id: 92,
    name: "Booking annulé par le prestataire",
    short: "Booking annulé",
  },
  CANCELLED_BY_ADMIN: {
    id: 93,
    name: "Booking annulé par un administrateur",
    short: "Booking annulé",
  },
  DELETED_BY_ADMIN: {
    id: 900,
    name: "Booking supprimé par un administrateur",
    short: "Booking supprimé",
  },
} as const;

export enum RecurringBookingStatuses {
  IN_PROGRESS = 0,
  // CLOSED = 90,
}
