import type { MarketplaceOffersType } from "app/shared/bookings.ts";
import type { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters.tsx";
import { MarketplaceSideFilterWrapper } from "../MarketplaceSideFilters.tsx";
import { SideFilterCheckbox } from "./SideFilterCheckbox.tsx";
export const ProviderFilter = ({
  selectedProviders,
  toggleProvider,
  availableProviders,
  setSideFilters,
}: {
  availableProviders: MarketplaceOffersType["availableProviders"];
  selectedProviders: ReturnType<
    typeof useMarketplaceSideFilters
  >["sideFilters"]["selectedProviderIds"];

  toggleProvider: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleProvider"];
  setSideFilters: ReturnType<
    typeof useMarketplaceSideFilters
  >["setSideFilters"];
}) => {
  if (!availableProviders) return null;
  return (
    <MarketplaceSideFilterWrapper title="★ Prestataires">
      <div className="flex flex-col gap-4">
        <SideFilterCheckbox
          checked={selectedProviders.length === 0}
          toggleOption={() => {
            setSideFilters({
              selectedProviderIds: [],
            });
          }}
          optionName={"Tous les prestataires"}
          optionGcId={"all"}
          isDisabled={selectedProviders.length === 0}
        />
        {availableProviders
          // .sort((a, b) => b - a)
          .map((option) => {
            return (
              <SideFilterCheckbox
                checked={selectedProviders.includes(option.id)}
                toggleOption={() => {
                  toggleProvider(option.id);
                }}
                optionName={option.providerName}
                optionLabel={`(${option.count})`}
                optionGcId={option.providerName}
                key={`provider-${option.providerName}`}
                // isDisabled={!isAvailable}
              />
            );
          })}
      </div>
    </MarketplaceSideFilterWrapper>
  );
};
