import type { MarketplaceOffersType } from "app/shared/bookings.ts";
import type { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters.tsx";
import { MarketplaceSideFilterWrapper } from "../MarketplaceSideFilters.tsx";
import { SideFilterCheckbox } from "./SideFilterCheckbox.tsx";

export const TreatmentTypeFilter = ({
  toggleTreatmentType,
  title,
  disabledTreatmentTypes,
  selectedTreatmentTypeIds,
  treatmentTypes,
  setSideFilters,
}: {
  title: string;
  treatmentTypes: MarketplaceOffersType["treatmentTypeData"]["treatmentTypes"];
  disabledTreatmentTypes: MarketplaceOffersType["treatmentTypeData"]["disabledTreatmentTypes"];
  selectedTreatmentTypeIds: ReturnType<
    typeof useMarketplaceSideFilters
  >["sideFilters"]["selectedTreatmentTypeIds"];
  toggleTreatmentType: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleTreatmentType"];
  setSideFilters: ReturnType<
    typeof useMarketplaceSideFilters
  >["setSideFilters"];
}) => {
  return (
    <MarketplaceSideFilterWrapper title={title}>
      <div className="flex flex-col gap-4">
        <SideFilterCheckbox
          checked={selectedTreatmentTypeIds.length === 0}
          toggleOption={() =>
            setSideFilters({
              selectedTreatmentTypeIds: [],
            })
          }
          optionName={"Toutes les valorisations"}
          optionGcId={"all"}
          isDisabled={selectedTreatmentTypeIds.length === 0}
        />
        {treatmentTypes.map((option) => {
          const isAvailable = !disabledTreatmentTypes.some(
            (aet) => aet.gcId === option.gcId,
          );
          return (
            <SideFilterCheckbox
              checked={
                isAvailable &&
                selectedTreatmentTypeIds.some((so) => so === option.gcId)
              }
              toggleOption={(valueId) => toggleTreatmentType(Number(valueId))}
              optionName={option.name}
              optionGcId={option.gcId}
              key={`treatment-type-${option.gcId}`}
              isDisabled={!isAvailable}
            />
          );
        })}
      </div>
    </MarketplaceSideFilterWrapper>
  );
};
