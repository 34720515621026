import { classed } from "@tw-classed/react";

export const AppWrapper = classed("div", "bg-white mb-6 w-full relative", {
  variants: {
    width: {
      full: "w-full",
      fit: "w-fit",
    },
    flex: {
      row: "flex flex-row",
      col: "flex flex-col",
    },
  },
  defaultVariants: {
    width: "full",
    flex: "col",
  },
});
