export enum UserRoles {
  NEW_USER = 0,
  CUSTOMER = 1,
  PROVIDER = 2,
  EXTERNAL_PROVIDER = 3,
  PROVIDER_MANAGER = 20,

  ADMINISTRATOR = 9,
}

export enum CustomerTypes {
  INDIVIDUAL = 10,
  PROFESSIONAL = 20,
}
