import React from "react";
import { Image, MimeType, type ResponsiveSize } from "remix-image";
import { cn } from "utils/utils.ts";

// Reduce height to preserve aspect ratio
const getMaxDimensions = ({
  width,
  height,
  maxWidth = width,
  maxHeight,
}: {
  maxWidth?: number;
  maxHeight?: number;
  width: number;
  height: number;
}) => {
  const ratio = width / height;
  if (maxHeight) {
    const calculatedWidth = Math.round(maxHeight * ratio);
    if (calculatedWidth <= maxWidth) {
      return { width: calculatedWidth, height: maxHeight };
    }
  }
  const isImageLargerThanMaxWidth = width > maxWidth;
  // It is not larger, return current dimensions

  if (!isImageLargerThanMaxWidth) {
    return { width, height };
  }
  // It is larger than max width. We want to convert the initial dimensions to match the aspect ratio, and match the max width.

  // get matched height from maxWidth
  const calculatedHeight = Math.round(maxWidth / ratio);

  return { width: maxWidth, height: calculatedHeight };
};

export const getAspectRatio = ({
  width,
  height,
  newWidth,
}: {
  width: number;
  height: number;
  newWidth: number;
}) => {
  const ratio = width / height;
  const newHeight = Math.round(newWidth / ratio);
  return { ratio, newWidth, newHeight };
};
const generateResponsiveSizes = ({
  baseHeight,
  baseWidth,
}: {
  baseWidth: number;
  baseHeight: number;
}): ResponsiveSize[] => {
  const ratio = baseWidth / baseHeight;

  const size1 = Math.round(baseWidth); // 100% of baseWidth
  const size2 = Math.round(baseWidth * 0.75); // 66% of baseWidth
  const size3 = Math.round(baseWidth * 0.65); // 50% of baseWidth
  const size4 = Math.round(baseWidth * 0.5); // 33% of baseWidth

  // We want image from smallest to largest
  const widths = [size4, size3, size2, size1];
  const heights = widths.map((width) => Math.round(width / ratio));

  const responsiveSizes = widths.map((width, index) => {
    const size = {
      width,
      height: heights[index],
    };

    const maxWidth = index !== widths.length - 1 ? width : undefined;

    return {
      size,
      maxWidth,
    };
  });

  return responsiveSizes;
};

export const OptimizedImage = React.memo(function OptimizedImageMemoized({
  source,
  imageProps = {
    minHeight: "auto",
    objectFit: "cover",
  },
  alt,
  className,
  loading = "eager",
  height = 310,
  width = 830,
  maxWidth = width,
  containerClassName = "w-full",
  responsiveSizes = generateResponsiveSizes({
    baseWidth: getMaxDimensions({
      height,
      width,
      maxWidth,
    }).width,
    baseHeight: getMaxDimensions({
      height,
      width,
      maxWidth,
    }).height,
  }),
  children = null,
}: {
  source: string;
  alt: string;
  loading?: "lazy" | "eager";
  responsiveSizes?: ResponsiveSize[];
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  className?: string;
  containerClassName?: string;
  imageProps?: {
    minHeight: "100%" | "auto";
    objectFit: "contain" | "cover";
  };
  children?: React.ReactNode;
}) {
  // const dimensions = getMaxDimensions({
  //   height,
  //   width,
  //   maxWidth,
  //   maxHeight,
  // });
  return (
    <div
      className={cn("relative mx-auto", containerClassName)}
      style={{
        maxWidth: "100%",
        // maxWidth: dimensions.width < maxWidth ? `${dimensions.width}px` : "100%"
      }}
    >
      {children}
      <Image
        loaderUrl={"/api/image"}
        src={source}
        alt={alt}
        className={className}
        loading={loading}
        options={{
          contentType: MimeType.WEBP,
          fit: imageProps.objectFit,
        }}
        placeholder="empty"
        style={{
          minWidth: "100%",
          minHeight: imageProps.minHeight,
          objectFit: imageProps.objectFit,
        }}
        width={width}
        height={height}
        responsive={responsiveSizes}
        dprVariants={[1]}
      />
    </div>
  );
});
