import { type RenderableTreeNodes, renderers } from "@markdoc/markdoc";
import { useEnv } from "app/root";
import { OptimizedImage } from "components/OptimizedImage.tsx";
import { Headline } from "components/UI/Headline";
import * as React from "react";
import { cn } from "utils/utils.ts";

type Props = {
  className?: string;
  content: RenderableTreeNodes;
  disableHeaderSelfAnchor?: boolean;
  imageProps?: {
    minHeight: "100%" | "auto";
    objectFit: "contain" | "cover";
  };
};
function generateIdFromTitle(title) {
  return title
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/\*\*(.*?)\*\*/g, "$1") // Supprimer le gras
    .replace(/\[(.*?)\]\(.*?\)/g, "$1") // Supprimer les liens markdown
    .replace(/`(.*?)`/g, "$1") // Supprimer le code inline
    .replace(/[^\w\s-]/g, "") // Supprimer les caractères spéciaux
    .replace(/\s+/g, "-") // Remplacer les espaces par des tirets
    .replace(/^-+|-+$/g, ""); // Supprimer les tirets au début et à la fin
}

function addIdsToHeadings(node) {
  if (node.$$mdtype === "Tag" && /^h[2-3]$/.test(node.name)) {
    if (
      node.attributes.id === "" ||
      !node.attributes.id ||
      // specific case for "et" and "ou" for strasbourg
      node.attributes.id === "et" ||
      node.attributes.id === "ou"
    ) {
      // Extraire le texte des enfants pour générer l'ID

      const titleText = node.children
        .map((child) =>
          typeof child === "string" ? child : child.children || "idou",
        )
        .join("");

      node.attributes.id = generateIdFromTitle(titleText);
    }
  }

  // Parcourir les enfants récursivement
  if (node.children && Array.isArray(node.children)) {
    node.children.forEach(addIdsToHeadings);
  }
}
export function Markdown({
  content,
  className,
  disableHeaderSelfAnchor = false,
  imageProps = {
    minHeight: "100%",
    objectFit: "cover",
  },
}: Props) {
  addIdsToHeadings(content);

  return (
    <div
      className={cn(
        "prose prose-li:items-baseline prose-hr:my-8 prose-headings:font-medium prose-headings:mb-4 prose-headings:scroll-mt-20 prose-headings:text-start prose-headings:text-midnightblue prose-p:text-justify prose-p:text-midnightblue prose-li:my-1 prose-li:gap-2 marker:prose-ul:text-midnightblue prose-ul:pl-4 prose-a:text-teal prose-a:underline first:prose-p:mt-0 mx-auto mt-8 max-w-full",
        className,
      )}
    >
      {renderers.react(content, React, {
        components: {
          Heading: ({
            size,
            children,
            id,
          }: {
            children: string;
            size: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
            id: string;
          }) => {
            // const id = generateIdFromTitle(children);

            return (
              <Headline
                as={size}
                size={size}
                enableAnchor={!disableHeaderSelfAnchor}
                id={id}
                className={cn({
                  "text-[1.5rem] lg:text-[1.5rem] leading-[130%]":
                    size === "h1",
                  "text-[1.375rem] lg:text-[1.375rem] leading-[130%]":
                    size === "h2",
                  "text-[1.25rem] lg:text-[1.25rem] leading-[130%]":
                    size === "h3",
                  "text-[1.125rem] lg:text-[1.125rem] leading-[130%]":
                    size === "h4",
                  "text-[1rem] lg:text-[1rem] leading-[130%]": size === "h5",
                  "text-[0.875rem] lg:text-[0.875rem] leading-[130%]":
                    size === "h6",
                })}
              >
                {children}
              </Headline>
            );
          },
          Image: ({ alt, source }: { source: string; alt: string }) => {
            return (
              <OptimizedImage
                className="rounded-clint my-0 border border-gray-200"
                source={source}
                alt={alt}
                imageProps={imageProps}
              />
            );
          },
          GoodcollectPhone: (props) => {
            const { phoneDisplay, phoneLink } = useEnv() || {};

            return (
              <>
                <a className={"text-secondary underline"} href={phoneLink}>
                  {phoneDisplay}
                </a>
                {props.children}
              </>
            );
          },
          MarketplaceCityOffers: (props: {
            cityName: string;
            /*  offers: Awaited<
              ReturnType<typeof getMarketplaceCityFromDatabase>
            >["marketplaceCity"]["cityOffers"]; */
          }) => {
            if (props) {
              return null;
            }
            return null;
            /* if (props.offers.length === 0) {
              return null;
            }
            return null;
             return <>
               <Headline size='h3'>Tarification des bennes à {props.cityName}</Headline>
            <Paragraph>Transport à partir de <b>{formatPriceWithCurrency(props.offers[0].priceHT, true)}&nbsp;HT</b></Paragraph> */
            /*  { */ /* {props.offers.map((offer) => (
               <div key={offer.id}>
                <Headline size='h4'>{offer.equipmentName}</Headline>
               <p>{offer.wasteName}</p>
              </div>
            ))} */ /* } */
            /*  </> */
          },
        },
      })}
    </div>
  );
}
