import { useCombobox } from "downshift";
import { useCallback, useEffect, useId, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useFetcher, useSearchParams } from "react-router";
import type { AddressComboboxType } from "routes/resources+/get-address";
import type { loader as feedbackLoader } from "routes/resources+/get-address-by-place-id";
import { useSpinDelay } from "spin-delay";

function useDebounce<T>(callback: (args: T) => void, delay: number) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  return useCallback(
    (args: T) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(args);
      }, delay);
    },
    [callback, delay],
  );
}

export const useAddressCombobox = ({
  defaultAddress = null,
  defaultPlaceId = null,
  latLngLoading = false,
  onAddressChange,
  enableVerification = false,
  withLatLng = false,
  debounceMs,
}: {
  defaultAddress?: string | null;
  defaultPlaceId?: string | null;
  enableVerification?: boolean;
  latLngLoading?: boolean;
  withLatLng?: boolean;
  debounceMs: number;
  onAddressChange?: ({
    address,
    placeId,
    latitude,
    longitude,
  }: {
    address: string;
    placeId: string;
    latitude?: number;
    longitude?: number;
  }) => void;
}) => {
  const addressFetcher = useFetcher<AddressComboboxType>();
  const addressAvailabilityFetcher = useFetcher<typeof feedbackLoader>();

  const id = useId();
  const addresses = addressFetcher.data?.addresses ?? [];
  type AddressType = (typeof addresses)[number];
  const [selectedAddress, setSelectedAddress] = useState<
    | null
    | undefined
    | (AddressType & {
        latitude?: number;
        longitude?: number;
      })
  >({
    address: defaultAddress ?? "",
    id: defaultPlaceId ?? "",
  });
  // An errored address is an address that was suggested by Google, but that is not associated with a zipcode.
  const [searchParams] = useSearchParams();

  const debouncedSubmit = useDebounce<{ inputValue?: string }>(
    (changes) => {
      addressFetcher.submit(
        { search: changes.inputValue ?? "" },
        { method: "get", action: "/resources/get-address" },
      );
    },
    addresses.length > 0 ? debounceMs : 0, // Adjust the delay to what you need
  );

  const cb = useCombobox<AddressType>({
    id,
    onSelectedItemChange: ({ selectedItem }) => {
      setSelectedAddress(selectedItem);
      // With this feature, we only change the address once the lat and lng has been retrieved.
      if (!withLatLng) {
        onAddressChange?.({
          address: selectedItem?.address ?? "",
          placeId: selectedItem?.id ?? "",
        });
      }
      // n Lat & Lon
    },
    items: addresses,
    itemToString: (item) => (item ? item.address : ""),
    defaultInputValue:
      defaultAddress ??
      searchParams.get("address") ??
      searchParams.get("search") ??
      "",
    onInputValueChange: (changes) => {
      debouncedSubmit({ inputValue: changes.inputValue ?? "" });
      // addressFetcher.submit(
      //   { search: changes.inputValue ?? "" },
      //   { method: "get", action: "/resources/get-address" },
      // );
    },
  });

  const busy = addressFetcher.state !== "idle" || latLngLoading;
  const showSpinner = useSpinDelay(busy, {
    delay: 150,
    minDuration: 500,
  });
  const displayMenu = cb.isOpen && addresses.length > 0;

  useEffect(() => {
    if (!enableVerification) {
      return;
    }
    if (!selectedAddress || !selectedAddress.id) {
      return;
    }
    if (selectedAddress.id === addressAvailabilityFetcher.data?.placeId) {
      // This means that the address is already verified
      return;
    }
    addressAvailabilityFetcher.submit(
      {
        placeId: selectedAddress.id,
      },
      {
        method: "GET",
        action: "/resources/get-address-by-place-id",
      },
    );
  }, [selectedAddress]);

  useEffect(() => {
    if (!addressAvailabilityFetcher.data) return;
    if (!withLatLng) return; // This logic only runs if we want to get the lat and lng
    const { latitude, longitude, placeId } =
      addressAvailabilityFetcher.data || {};
    if (latitude && longitude && selectedAddress.id === placeId) {
      setSelectedAddress((oldAddress) => ({
        ...oldAddress,
        latitude,
        longitude,
      }));
      onAddressChange?.({
        address: selectedAddress?.address ?? "",
        placeId: selectedAddress?.id ?? "",
        latitude,
        longitude,
      });
    }
  }, [addressAvailabilityFetcher]);

  return {
    displayMenu,
    showSpinner,
    selectedAddress,
    cb,
    addressFetcher,
    addresses,
    verifiedAddress: addressAvailabilityFetcher.data,
    isVerificationLoading: addressAvailabilityFetcher.state !== "idle",
  };
};

export const useAddressComboboxControlled = ({
  value,
  placeId,
  latLngLoading = false,
  onAddressChange,
}: {
  value: string;
  placeId: string;
  latLngLoading?: boolean;
  onAddressChange: ({
    address,
    placeId,
  }: {
    address: string;
    placeId: string;
  }) => void;
}) => {
  const addressFetcher = useFetcher<AddressComboboxType>();
  const id = useId();
  const addresses = addressFetcher.data?.addresses ?? [];
  type AddressType = (typeof addresses)[number];
  const [selectedAddress, setSelectedAddress] = useState<
    null | undefined | AddressType
  >({
    address: value ?? "",
    id: placeId ?? "",
  });

  const cb = useCombobox<AddressType>({
    id,

    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setSelectedAddress(selectedItem);
        flushSync(() => {
          onAddressChange({
            address: selectedItem?.address,
            placeId: selectedItem?.id,
          });
        });
      }
    },
    items: addresses,
    itemToString: (item) => (item ? item.address : ""),
    inputValue: value,
    onInputValueChange: ({ inputValue, selectedItem }) => {
      if (selectedItem) return;
      addressFetcher.submit(
        { search: inputValue ?? "" },
        { method: "get", action: "/resources/get-address" },
      );
      onAddressChange({
        address: inputValue ?? "",
        placeId: placeId,
      });
    },
  });

  const busy = addressFetcher.state !== "idle" || latLngLoading;
  const showSpinner = useSpinDelay(busy, {
    delay: 150,
    minDuration: 500,
  });
  const displayMenu = cb.isOpen && addresses.length > 0;

  return {
    displayMenu,
    showSpinner,
    selectedAddress,
    cb,
    addressFetcher,
    addresses,
  };
};
