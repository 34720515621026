import {
  addDays,
  differenceInDays,
  format,
  formatDistanceToNow,
  isDate,
  isValid,
  parse,
  set,
} from "date-fns";

import { fr } from "date-fns/locale";

export {
  addDays,
  differenceInDays,
  format,
  formatDistanceToNow,
  isDate,
  isValid,
  parse,
  set,
};

const SATURDAY = 6;
const SUNDAY = 0;

export const isDateOnWeekend = (date: Date) => {
  if (!date) return true;
  return date.getDay() === SUNDAY || date.getDay() === SATURDAY;
};
// Liste des jours fériés français (à mettre à jour chaque année)
const FRENCH_HOLIDAYS: Record<number, string[]> = {
  2024: [
    "2024-01-01", // Jour de l'an
    "2024-04-01", // Lundi de Pâques
    "2024-05-01", // Fête du Travail
    "2024-05-08", // Victoire 1945
    "2024-05-09", // Ascension
    "2024-05-20", // Lundi de Pentecôte
    "2024-07-14", // Fête Nationale
    "2024-08-15", // Assomption
    "2024-11-01", // Toussaint
    "2024-11-11", // Armistice
    "2024-12-25", // Noël
  ],
  2025: [
    "2025-01-01", // Jour de l'an
    "2025-04-21", // Lundi de Pâques
    "2025-05-01", // Fête du Travail
    "2025-05-08", // Victoire 1945
    "2025-05-29", // Ascension
    "2025-06-09", // Lundi de Pentecôte
    "2025-07-14", // Fête Nationale
    "2025-08-15", // Assomption
    "2025-11-01", // Toussaint
    "2025-11-11", // Armistice
    "2025-12-25", // Noël
  ],
  // Ajouter d'autres années...
};

const isHoliday = (date: Date) => {
  const year = date.getFullYear();
  const dateString = date.toISOString().split("T")[0];

  // Si on n'a pas les jours fériés pour cette année, on considère qu'il n'y en a pas
  if (!FRENCH_HOLIDAYS[year]) {
    console.warn(`Pas de jours fériés définis pour l'année ${year}`);
    return false;
  }

  return FRENCH_HOLIDAYS[year].includes(dateString);
};
/**
 *  Helper function to add business days
 * */
export const addBusinessDays = ({
  date,
  daysToAdd,
}: {
  date: Date;
  daysToAdd: number;
}) => {
  let count = 0;
  let newDate = new Date(date);

  while (count < daysToAdd) {
    newDate = new Date(newDate.setDate(newDate.getDate() + 1));
    // Si ce n'est ni un weekend ni un jour férié, on incrémente le compteur
    if (!isDateOnWeekend(newDate) && !isHoliday(newDate)) {
      count++;
    }
  }
  return newDate;
};

type DateFormat =
  | "dd/MM/yyyy à HH:mm:ss"
  | "dd/MM/yyyy"
  | "dd MMMM yyyy"
  | "MM/yyyy"
  | "dd/MM"
  | "dd MMMM yyyy à HH:mm:ss"
  | "EEEE dd MMMM yyyy"
  | "EEEE dd MMMM yyyy à HH:mm:ss";
export const formatDate = ({
  date,
  dateFmt = "dd/MM/yyyy",
}: {
  date: string | Date;
  dateFmt?: DateFormat;
}) => {
  let initialDate = new Date(date);
  if (!isValid(initialDate)) {
    console.log("invalid date", initialDate);
    initialDate = parse(date.toString(), "dd/MM/yyyy", new Date(), {
      locale: fr,
    });
  }

  const formattedDate = format(initialDate, dateFmt, {
    locale: fr,
  });
  return formattedDate;
};

export const formatDateFromNow = ({
  date,
  maxDays = 2,
  dateFmt = "dd/MM/yyyy",
  showFullDate = true,
}: {
  date: string | Date;
  maxDays?: number;
  dateFmt?: DateFormat;
  showFullDate?: boolean;
}) => {
  // check if there is 7days between now and then
  const initialDate = new Date(date);
  const now = new Date();
  const diff = Math.abs(now.getTime() - initialDate.getTime());
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  if (diffDays > maxDays) {
    return formatDate({ date, dateFmt });
  }

  return `${formatDistanceToNow(new Date(date), {
    locale: fr,
    addSuffix: true,
  })} ${showFullDate ? formatDate({ date, dateFmt: "dd/MM/yyyy à HH:mm:ss" }) : ""}`;
};

export const parseStringToDate = (date: string): Date => {
  const parsedDate = parse(date, "dd/MM/yyyy", new Date(), {
    locale: fr,
  });

  return new Date(parsedDate);
};
