import type { MarketplaceOffersType } from "app/shared/bookings";
import { useQueryStates } from "nuqs";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router";
import {
  marketplaceSideFiltersSearchParams,
  marketplaceSideFiltersUrlKeys,
} from "routes/_public+/solutions+/rechercher";
import type { getTreatmentTypes } from "server/api/booking-pricing/services/provider-bookings-queries.server";
import { useOptionalUser } from "./useUser";
export type MarketplaceSidebarFilters = {
  showHiddenOffers: boolean;
  selectedEquipmentTypes: MarketplaceOffersType["availableEquipmentTypes"];
  selectedVolumes: MarketplaceOffersType["availableVolumes"];
  selectedProviders: {
    providerName: string;
    count: number;
    checked: boolean;
  }[];

  selectedTreatmentTypes: Awaited<
    ReturnType<typeof getTreatmentTypes>
  >["treatmentTypes"];
};

export const useMarketplaceSideFilters = ({
  otherOffers,
  treatmentTypeData,
  availableEquipmentTypes,
  hiddenOffers,
  bestPlanOffer,
  bestTonOffer,
}: Pick<
  MarketplaceOffersType,
  | "otherOffers"
  | "hiddenOffers"
  | "treatmentTypeData"
  | "availableEquipmentTypes"
  | "bestPlanOffer"
  | "bestTonOffer"
>) => {
  const user = useOptionalUser();
  const [searchParams] = useSearchParams();

  const [sideFilters, setSideFilters] = useQueryStates(
    marketplaceSideFiltersSearchParams,
    { urlKeys: marketplaceSideFiltersUrlKeys },
  );

  const filterOffersByMaxPrice = useCallback(
    (offers: MarketplaceOffersType["otherOffers"]) => {
      const preferredVolumes = [10, 15];
      const preferredType = "Benne (Ampliroll)";

      return offers
        .filter((offer) => {
          const isTreatmentTypeSelected =
            sideFilters.selectedTreatmentTypeIds.length === 0 ||
            sideFilters.selectedTreatmentTypeIds.some(
              (type) => type === offer.treatmentTypeId,
            );
          const isOfferFromSelectedEquipmentType =
            sideFilters.selectedEquipmentTypeIds.length === 0 ||
            sideFilters.selectedEquipmentTypeIds?.some(
              (type) => type === offer.equipment.type.id,
            );
          const isOfferVolumeSelected =
            sideFilters.selectedVolumes.length === 0 ||
            sideFilters.selectedVolumes?.includes(offer.equipment.volume);

          const isProviderSelected = user?.authOptions.isAdmin
            ? sideFilters.selectedProviderIds?.some(
                (providerId) => providerId === offer.providerId,
              ) || sideFilters.selectedProviderIds.length === 0
            : true;

          return (
            isTreatmentTypeSelected &&
            isOfferFromSelectedEquipmentType &&
            isOfferVolumeSelected &&
            isProviderSelected
          );
        })
        .sort((a, b) => {
          if (a.priceTTC !== b.priceTTC) {
            return a.priceTTC - b.priceTTC;
          }

          // 2. If prices are equal, prioritize "Benne (Ampliroll)" with 10m3 or 15m3
          const aIsPreferred =
            a.equipment.equipmentTypeName === preferredType &&
            preferredVolumes.includes(a.equipment.volume);
          const bIsPreferred =
            b.equipment.equipmentTypeName === preferredType &&
            preferredVolumes.includes(b.equipment.volume);

          if (aIsPreferred && !bIsPreferred) return -1; // a is preferred
          if (!aIsPreferred && bIsPreferred) return 1; // b is preferred

          // 3. If both are equal in preference (or neither is preferred), keep the original order
          return 0;
        });
    },
    [
      sideFilters.selectedTreatmentTypeIds,
      sideFilters.selectedEquipmentTypeIds,
      sideFilters.selectedVolumes,
      sideFilters.selectedProviderIds,
    ],
  );

  const toggleEquipmentType = (equipmentTypeId: number) => {
    setSideFilters((oldFilterData) => {
      const { selectedEquipmentTypeIds } = oldFilterData;
      // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
      const isSelected = selectedEquipmentTypeIds.some(
        (type) => type === equipmentTypeId,
      );
      const selectedEquipmentType = availableEquipmentTypes.find(
        (et) => et.id === equipmentTypeId,
      );

      if (!selectedEquipmentType) {
        throw new Error("Equipment type id is required");
      }

      return {
        selectedEquipmentTypeIds: isSelected
          ? // Déselection
            selectedEquipmentTypeIds.filter((type) => type !== equipmentTypeId)
          : [...selectedEquipmentTypeIds, equipmentTypeId],
      };
    });
  };

  const toggleProvider = (providerId: string) => {
    setSideFilters((oldFilterData) => {
      const { selectedProviderIds } = oldFilterData;
      if (selectedProviderIds.includes(providerId)) {
        return {
          selectedProviderIds: selectedProviderIds.filter(
            (id) => id !== providerId,
          ),
        };
      }

      return {
        selectedProviderIds: [...selectedProviderIds, providerId],
      };
    });
  };

  const toggleVolume = (volume: number) => {
    setSideFilters((oldFilterData) => {
      const { selectedVolumes } = oldFilterData;
      // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
      const isSelected = selectedVolumes.includes(volume);

      const selectedVolumesSet = new Set<number>(selectedVolumes);
      if (isSelected) {
        selectedVolumesSet.delete(volume);
      } else {
        selectedVolumesSet.add(volume);
      }

      return {
        selectedVolumes: Array.from(selectedVolumesSet),
      };
    });
  };

  const toggleTreatmentType = useCallback(
    (treatmentTypeId: number | string) => {
      treatmentTypeId = Number(treatmentTypeId);
      const toggledTreatmentType = treatmentTypeData.treatmentTypes.find(
        (t) => t.gcId === treatmentTypeId,
      );
      if (!toggledTreatmentType) {
        throw new Error("Treatment type id is required");
      }

      setSideFilters((oldFilterData) => {
        const { selectedTreatmentTypeIds } = oldFilterData;
        // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
        const isSelected = selectedTreatmentTypeIds.some(
          (typeId) => typeId === treatmentTypeId,
        );

        return {
          selectedTreatmentTypeIds: isSelected
            ? // Déselection
              selectedTreatmentTypeIds.filter(
                (typeId) => typeId !== treatmentTypeId,
              )
            : [...selectedTreatmentTypeIds, toggledTreatmentType.gcId],
        };
      });
    },
    [treatmentTypeData?.treatmentTypes],
  );

  const {
    bestPlanOffer: filteredBestPlanOffer,
    bestTonOffer: filteredBestTonOffer,
    otherOffers: filteredOtherOffers,
    hiddenOffersCount,
  } = useMemo(() => {
    const offersArray = [
      ...(otherOffers ? otherOffers.concat(hiddenOffers) : []),
      bestPlanOffer,
      bestTonOffer,
    ].filter(Boolean);

    const filteredOffers = filterOffersByMaxPrice(offersArray);

    const filteredBestPlanOffer = filteredOffers.find((f) => f.isPlan);
    const filteredBestTonOffer = filteredOffers.find((f) => !f.isPlan);

    const filteredOffersWithoutBestOffers = filteredOffers.filter(
      (f) =>
        (f.equipmentPriceRuleId !==
          filteredBestPlanOffer?.equipmentPriceRuleId &&
          f.isPlan === filteredBestPlanOffer?.isPlan) ||
        (f.equipmentPriceRuleId !==
          filteredBestTonOffer?.equipmentPriceRuleId &&
          f.isPlan === filteredBestTonOffer?.isPlan),
    );

    const filteredOtherOffers = filteredOffersWithoutBestOffers.slice(0, 4);
    const fileredHiddenOffers = filteredOffersWithoutBestOffers.slice(4);

    return {
      bestPlanOffer: filteredBestPlanOffer,
      bestTonOffer: filteredBestTonOffer,
      otherOffers: sideFilters.showHiddenOffers
        ? filteredOffersWithoutBestOffers
        : filteredOtherOffers,
      hiddenOffersCount: fileredHiddenOffers.length,
    };
  }, [
    filterOffersByMaxPrice,
    otherOffers,
    hiddenOffers,
    bestPlanOffer,
    bestTonOffer,
    sideFilters.showHiddenOffers,
  ]);

  return {
    filteredOtherOffers,
    filteredBestPlanOffer,
    filteredBestTonOffer,
    toggleTreatmentType,
    setSideFilters,
    sideFilters,
    searchParams,
    toggleEquipmentType,
    toggleVolume,
    filteredHiddenOffersCount: hiddenOffersCount,
    toggleProvider,
  };
};
