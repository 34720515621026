import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import type { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { ClintButton } from "components/shadcn-ui/button";
import { useOptionalUser } from "hooks/useUser";
import { useState } from "react";
import { useNavigate } from "react-router";

const PaymentForm = ({
  onError,
  bookingId,
}: {
  onError: (error: string) => void;
  bookingId: number;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    setErrorMessage("");

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message ?? "Une erreur est survenue");
        onError(submitError.message ?? "Une erreur est survenue");
        return;
      }

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error) {
        setErrorMessage(error.message ?? "Une erreur est survenue");
        onError(error.message ?? "Une erreur est survenue");
      } else if (paymentIntent) {
        // Check payment intent status
        switch (paymentIntent.status) {
          case "succeeded":
          case "processing": {
            setIsProcessing(true);
            let delay = 2000;
            if (paymentIntent.status === "processing") {
              delay = 10_000;
            }
            await new Promise((resolve) => setTimeout(resolve, delay));
            navigate(`/dashboard/${bookingId}`);
            break;
          }
          // case "processing": {
          //   setErrorMessage("Votre paiement est en cours de traitement.");
          //   break;
          // }
          case "requires_payment_method": {
            setErrorMessage("Votre paiement a échoué, veuillez réessayer.");
            break;
          }
          default: {
            setErrorMessage("Une erreur inattendue est survenue.");
            break;
          }
        }
      }
    } catch (_e) {
      setErrorMessage("Une erreur inattendue est survenue");
      onError("Une erreur inattendue est survenue");
    } finally {
      setIsProcessing(false);
    }
  };

  const user = useOptionalUser();

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <PaymentElement
        options={{
          layout: {
            type: "tabs",
            defaultCollapsed: false,
          },
          business: {
            name: "GoodCollect",
          },
          paymentMethodOrder: ["card"],
          defaultValues: {
            billingDetails: {
              name: user ? `${user.firstname} ${user.lastname}` : "",
              email: user ? user.email : "",
            },
          },
        }}
      />

      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}

      <ClintButton
        as="Button"
        type="submit"
        variant="primary"
        size="sm"
        isLoading={isProcessing}
        disabled={isProcessing || !stripe || !elements}
      >
        {isProcessing ? "Paiement en cours..." : "Payer maintenant"}
      </ClintButton>
    </form>
  );
};

export const StripePaymentWrapper = ({
  clientSecret,
  onError,
  stripePromise,
  bookingId,
}: {
  clientSecret: string;
  onError: (error: string) => void;
  stripePromise: Promise<Stripe | null>;
  bookingId: number;
}) => {
  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: "stripe",
    },
    loader: "always",
    // customerSessionClientSecret: clientSecret,
    locale: "fr",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm
        bookingId={bookingId}
        // clientSecret={clientSecret}
        onError={onError}
      />
    </Elements>
  );
};
