import { AppWrapper } from "components/Interface/App/wrappers";
import { Headline } from "components/UI/Headline";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "components/shadcn-ui/dialog.tsx";
import type React from "react";
import { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router";
import { cn } from "utils/utils";
import { UncompletedTaskIcon } from "./SidebarItem";
type TabSearchParams = Record<string, string>;
export type Tab = {
  name: React.ReactNode;
  url: string;
  enabled: boolean;
  searchParams?: TabSearchParams;
  uncomplete?: boolean;
  onNavigate?: () => void;
  active?: boolean;
};
const createTabUrl = ({
  baseUrl,
  params,
}: { baseUrl: string; params?: TabSearchParams }): string => {
  if (!params) return baseUrl;

  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined && value !== null && value !== "") {
      searchParams.append(key, value);
    }
  }

  const urlParams = searchParams.toString();
  return urlParams ? `${baseUrl}?${urlParams}` : baseUrl;
};

export const ModalWrapper = ({
  isOpen = true,
  closeUrl = null,
  children,
  modalTitle,
  triggerComponent,
  childrenClassName,
  setIsOpen,
  tabs = [],
  footer,
  className,
}: {
  isOpen?: boolean;
  childrenClassName?: string;
  modalTitle: string | React.ReactNode;
  closeUrl?: string | null;
  children: React.ReactNode;
  className?: string;
  triggerComponent?: React.ReactNode;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  tabs?: Tab[];
  footer?: React.ReactNode;
}) => {
  const handleClose = () => {
    if (typeof window === "undefined") return;
    if (closeUrl) {
      window.location.href = closeUrl;
    }
    setIsOpen?.(false);
  };
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // ComponentDidMount lifecycle only on client-side
    setIsClient(true);
  }, []);

  if (!isClient) {
    // Or return a loading state or similar here if desired
    return null;
  }
  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      {triggerComponent}

      <DialogContent
        className={cn(
          "h-fit max-h-[95dvh] max-w-[96%] p-0 lg:w-[800px] overflow-auto lg:max-w-[800px]",
          className,
        )}
      >
        <DialogHeader>
          <TabsWrapper
            modalTitle={modalTitle}
            className="!mb-0 lg:py-0 "
            tabs={tabs}
            childrenClassName="size-full max-h-[80vh] overflow-y-auto"
          ></TabsWrapper>
        </DialogHeader>
        <div
          className={cn(
            "max-h-[80vh] overflow-auto flex flex-col p-5 lg:px-10 lg:pt-6",
            childrenClassName,
          )}
        >
          {children}
        </div>
        {footer ? (
          <DialogFooter className="flex w-full items-center justify-center p-5 lg:p-10">
            {footer}
          </DialogFooter>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

const isTabActive = ({
  searchParams,
  tab,
}: { tab: Tab; searchParams: URLSearchParams }) => {
  if (!tab.searchParams) return false;

  return Object.entries(tab.searchParams).every(
    ([key, value]) => searchParams.get(key) === value,
  );
};

export const TabsWrapper = ({
  tabs,
  children,
  modalTitle,
  className,
  subTabSection,
  childrenClassName,
}: {
  tabs: Tab[];
  modalTitle?: string | React.ReactNode;
  children?: React.ReactNode;
  subTabSection?: React.ReactNode;
  className?: string;
  childrenClassName?: string;
}) => {
  const [searchParams] = useSearchParams();
  return (
    <AppWrapper className={cn("px-2 py-1.5 lg:px-4 lg:py-3 h-full", className)}>
      {modalTitle ? (
        <Headline
          size="h4"
          as="span"
          className="text-lg font-semibold leading-none tracking-tight self-start text-start lg:py-4"
        >
          {modalTitle}
        </Headline>
      ) : null}
      <div className="border-b-iron flex flex-row flex-wrap gap-x-4 lg:gap-x-6 border-b-2 text-sm">
        {tabs.map((tab) => {
          if (!tab.enabled) {
            return (
              <span
                key={tab.url}
                className="border-b-iron cursor-default border-b px-1.5 py-3 opacity-50"
              >
                {tab.name}
              </span>
            );
          }

          const tabUrl = createTabUrl({
            baseUrl: tab.url,
            params: tab.searchParams,
          });

          if (tab.onNavigate) {
            return (
              <button
                key={tabUrl}
                onClick={tab.onNavigate}
                className={cn(
                  "relative px-1.5 py-2 whitespace-nowrap",
                  isTabActive({ tab, searchParams }) || tab.active
                    ? "border-b-teal border-b-2"
                    : "border-b-iron border-b",
                )}
              >
                {tab.name}
                {tab.uncomplete && (
                  <UncompletedTaskIcon className="right-1 top-0" />
                )}
              </button>
            );
          }
          return (
            <NavLink
              key={tabUrl}
              to={tabUrl}
              prefetch="intent"
              preventScrollReset
              className={({ isActive }) => {
                const activeFromSearchParams = isTabActive({
                  tab,
                  searchParams,
                });
                const isActiveTab = activeFromSearchParams ?? isActive;

                return cn(
                  `whitespace-nowrap relative px-1.5 py-2 ${
                    isActiveTab
                      ? "border-b-teal border-b-2 font-semibold"
                      : "border-b-iron border-b"
                  }`,
                );
              }}
            >
              {tab.name}
              {tab.uncomplete && (
                <UncompletedTaskIcon className="right-1 top-0" />
              )}
            </NavLink>
          );
        })}
      </div>
      {subTabSection}
      {/* <div className="px-0 py-4">{children}</div> */}
      {children ? (
        <div className={cn("pt-2 lg:pt-4", childrenClassName)}>{children}</div>
      ) : null}
    </AppWrapper>
  );
};
