import { NuqsAdapter } from "nuqs/adapters/react-router/v7";

import { GoogleAnalytics } from "components/GoogleAnalytics.tsx";
import { Navigation } from "components/Layout/Navigation.tsx";
import { GeneralErrorBoundary } from "components/error-boundary.tsx";
import rdp from "react-day-picker/style.css?url";
import rpi from "react-phone-number-input/style.css?url";

import { CookieBanner } from "components/CookieConsentBanner.tsx";
import Footer from "components/Layout/Footer.tsx";
import { Toaster } from "components/shadcn-ui/toaster.tsx";
import React from "react";
import {
  type LinkDescriptor,
  Links,
  type LinksFunction,
  type LoaderFunctionArgs,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  data,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteLoaderData,
} from "react-router";
import remixImages from "remix-image/remix-image.css?url";
import { getMarketplaceWasteTypes } from "server/public/transactional-page.server.ts";
import { formatPhone } from "utils/format-phone.ts";
import { getToast } from "utils/toast.server.ts";
import { combineHeaders } from "utils/utils.ts";
import { getUser, serverEnv } from "./server/session.server.ts";
import fontStyles from "./styles/fonts.css?url";
import tailwindCss from "./styles/global.css?url";
import resetStyles from "./styles/reset.css?url";
import { useNonce } from "./utils/nonce-provider.ts";

export function ErrorBoundary() {
  // const error = useRouteError();
  const locationKey = useLocation().key;
  const nonce = useNonce();

  // captureRemixErrorBoundaryError(error);

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.
  return (
    <Document nonce={nonce}>
      <Navigation key={locationKey} />
      <GeneralErrorBoundary />
      <Footer />
    </Document>
  );
  // the nonce doesn't rely on the loader so we can access that
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const {
    GOOGLE_ANALYTICS_TRACKING_ID,
    GOODCOLLECT_ENV,
    GTM_ID,
    FRONTEND_URL,
    WEBSOCKET_URL,
    GOODCOLLECT_PHONE,
    GOOGLE_MAPS_API_KEY,
    SENTRY_DSN,
    NODE_ENV,
    CONTACT_EMAIL,
    ENABLE_HOTJAR,
    STRIPE_PUBLISHABLE_KEY,
  } = serverEnv;

  const isDev = GOODCOLLECT_ENV !== "production";
  const [user, wasteTypes] = await Promise.all([
    getUser({ request }),
    getMarketplaceWasteTypes(),
  ]);

  // throw new Error('bam')
  const { toast, headers: toastHeaders } = await getToast(request);
  return data(
    {
      isDev,
      user,
      wasteTypes,

      toast,
      env: {
        STRIPE_PUBLISHABLE_KEY,
        GOOGLE_ANALYTICS_TRACKING_ID,
        GTM_ID,
        WEBSOCKET_URL,
        FRONTEND_URL,
        GOODCOLLECT_ENV,
        GOODCOLLECT_PHONE,
        GOOGLE_MAPS_API_KEY,
        SENTRY_DSN,
        NODE_ENV,
        CONTACT_EMAIL,
        ENABLE_HOTJAR,
      },
    },
    {
      headers: combineHeaders(toastHeaders),
    },
  );
};

export const useEnv = () => {
  const loaderData = useRouteLoaderData<typeof loader>("root");
  if (!loaderData) {
    return null;
    // throw new Error("No loader data found, but env is required by useEnv.");
  }

  const { GOODCOLLECT_PHONE } = loaderData.env || {};
  let phoneLink = "";

  if (GOODCOLLECT_PHONE) {
    phoneLink = `tel:+33${[...GOODCOLLECT_PHONE].slice(1).join("")}`;
  }
  const phoneDisplay = GOODCOLLECT_PHONE ? formatPhone(GOODCOLLECT_PHONE) : "";

  return { ...loaderData.env, phoneLink, phoneDisplay };
};

export const useWasteTypes = () => {
  const loaderData = useRouteLoaderData<typeof loader>("root");
  if (!loaderData?.wasteTypes) {
    return [];
    // throw new Error("No loader data found, but env is required by useEnv.");
  }

  return loaderData.wasteTypes;
};

export const links: LinksFunction = () => {
  // 👉 Style is generated in public/assets/css directory
  // ! Check build:css command in package.json
  return [
    { rel: "preconnect", href: "https://www.google.com" },
    { rel: "preconnect", href: "https://cms.goodcollect.co" },
    { rel: "preconnect", href: resetStyles, as: "style" },
    { rel: "preconnect", href: fontStyles, as: "style" },
    { rel: "preconnect", href: remixImages, as: "style" },
    { rel: "preconnect", href: tailwindCss, as: "style" },
    { rel: "preconnect", href: rpi, as: "style" },
    { rel: "preconnect", href: rdp, as: "style" },
    ...(process.env.NODE_ENV === "production"
      ? [{ rel: "preconnect", href: "https://api.goodcollect.co" }]
      : [{ rel: "preconnect", href: "https://e574bad1.goodcollect.co" }]),

    { rel: "preconnect", href: "https://www.termsfeed.com" },
    { rel: "preconnect", href: "https://www.googletagmanager.com" },
    { rel: "stylesheet", href: resetStyles },
    { rel: "stylesheet", href: fontStyles },
    { rel: "stylesheet", href: remixImages },
    { rel: "stylesheet", href: tailwindCss },
    { rel: "stylesheet", href: rpi },
    { rel: "stylesheet", href: rdp },
    { rel: "icon", href: "/favicon.ico" },

    {
      rel: "preconnect",
      href: "https://goodcollect-strapi.s3.eu-west-3.amazonaws.com",
    },
  ].filter(Boolean);
};

function Document({
  children,
  nonce,
}: {
  children: React.ReactNode;
  nonce: string;
}) {
  const env = useEnv();

  return (
    <html lang="fr" className="h-full overflow-x-hidden">
      <head>
        {/* {env.GOODCOLLECT_ENV === "local" ? (
          <script src="https://unpkg.com/react-scan/dist/auto.global.js"></script>
        ) : null} */}
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <DynamicLinks />
        {/* {shouldEnablePartyTown ? (
          <Partytown debug={true} forward={["dataLayer.push"]} />
        ) : null} */}
        <Links />
      </head>

      <body className="relative">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />

        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

export function App() {
  const env = useEnv();
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  return (
    <NuqsAdapter>
      <Document nonce={nonce}>
        <Outlet />

        <CookieBanner />
        <GoogleAnalytics nonce={nonce} GTM_ID={env?.GTM_ID || ""} />
        <ScrollRestoration />
        <Scripts />
        <Toaster toastItem={data.toast} />
      </Document>
    </NuqsAdapter>
  );
}

type ENV = ReturnType<typeof useEnv>;

declare global {
  var ENV: ENV;
  interface Window {
    ENV: ENV;
  }
}

export default App;
// export default withSentry(App);

function DynamicLinks() {
  const links: LinkDescriptor[] = useMatches()
    .flatMap((match) => {
      // @ts-expect-error
      const fn = match.handle?.dynamicLinks;
      if (typeof fn !== "function") {
        return [];
      }
      if (!match.data) {
        return [];
      }
      const data = match.data as { href: string | null };
      if (!data?.href) return [];
      return fn({ data: match.data });
    }) // Remove duplicates based on href
    .filter(
      (link, index, self) =>
        index === self.findIndex((l) => l.href === link.href),
    );

  return (
    <React.Fragment>
      {links.map((link) => (
        <link {...link} key={link.integrity || JSON.stringify(link)} />
      ))}
    </React.Fragment>
  );
}
