import { useEnv } from "app/root";
import { useEffect } from "react";
import { useLocation } from "react-router";

export function CookieBanner() {
  const { GOODCOLLECT_ENV } = useEnv() || {};
  const isDev = GOODCOLLECT_ENV !== "production";
  const src = isDev
    ? "https://dash.cookiehub.com/dev/6e06ffc0.js"
    : "https://cdn.cookiehub.eu/c2/6e06ffc0.js";
  const disabledRoutes = ["/rechercher", "/admin"];
  const location = useLocation();
  const isDisabled = disabledRoutes.some((route) =>
    location.pathname.startsWith(route),
  );
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    if (typeof document === "undefined") {
      return;
    }
    if (isDisabled) {
      return;
    }
    if (GOODCOLLECT_ENV === "test" || GOODCOLLECT_ENV === "local") {
      return;
    }
    let scriptElement: HTMLScriptElement | null = null;

    try {
      scriptElement = document?.createElement("script");
      scriptElement.src = src;
      scriptElement.async = true;
      document.body?.appendChild(scriptElement);
    } catch (error) {
      console.error(`Error loading cookie script: ${error}`);
    }

    scriptElement.onload = () => {
      // biome-ignore lint/style/noVar: This is a special script from a third party
      var cpm = {};
      // @ts-ignore
      window.cookiehub.load(cpm);
    };

    return () => {
      try {
        if (typeof document === "undefined") {
          return;
        }
        if (scriptElement && document.body?.contains(scriptElement)) {
          document.body?.removeChild(scriptElement);
        }
      } catch (error) {
        console.error(`Error removing cookie script: ${error}`);
      }
    };
  }, [src, isDisabled]);

  return null;
}
