import { OptimizedImage } from "components/OptimizedImage";
import type { getCustomerPaymentMethods } from "server/api/customer/services/customer-billing.server";
import { cn } from "utils/utils";
import sepaIcon from "/images/logo-sepa.webp";

type PaymentMethodProps = Awaited<
  ReturnType<typeof getCustomerPaymentMethods>
>["sepaDebits"][number];

export const PaymentMethodSepa = ({
  className,
  sepaDebit: { isDefault, last4 },
}: { sepaDebit: PaymentMethodProps; className?: string }) => {
  return (
    <div className={cn("flex flex-col gap-1", className)}>
      {isDefault ? (
        <span className="text-xs text-gray-600">Compte par défaut</span>
      ) : null}

      <div
        className={cn(
          "bg-white border border-slate-500 px-2 py-3 flex flex-row gap-2 rounded-sm",
          {
            "border-primary": isDefault,
          },
        )}
      >
        <OptimizedImage
          source={sepaIcon}
          width={40}
          height={40}
          alt="SEPA icon"
          className="shrink-0"
          imageProps={{
            minHeight: "auto",
            objectFit: "contain",
          }}
          containerClassName="shrink-0"
        />

        <div className="flex flex-col">
          <p className="shrink-0 text-sm">••••{last4}</p>
          <p className="shrink-0 text-gray-600 text-xs">Prélèvement SEPA</p>
        </div>
      </div>
    </div>
  );
};
