import { useQueryStates } from "nuqs";
import { useNavigation, useSearchParams } from "react-router";
import { marketplaceSearchParams } from "routes/_public+/solutions+/rechercher.tsx";
export const useMarketplaceSearchBar = () => {
  const [selectedFilters, setSelectedFilters] = useQueryStates(
    marketplaceSearchParams,
  );
  const [searchParams] = useSearchParams();
  const transition = useNavigation();
  const isLoading = transition.state !== "idle";
  const missingWasteFilter = !selectedFilters.waste;
  const missingVolumeFilter = !selectedFilters.volume;
  const missingAddressFilter =
    !selectedFilters.address || !selectedFilters.placeId;
  const missingFilter =
    missingWasteFilter || missingVolumeFilter || missingAddressFilter;
  const isSearchDisabled =
    isLoading || missingFilter || !selectedFilters.service;

  const hasClicked = searchParams.get("clicked") === "1";
  return {
    isSearchDisabled,
    missingFilter,
    missingAddressFilter,
    missingVolumeFilter,
    missingWasteFilter,
    selectedFilters,
    setSelectedFilters,
    isLoading,
    hasClicked,
  };
};
