import z from "zod";

export const passwordSchema = z
  .string({
    required_error: "Le mot de passe est obligatoire",
  })
  .min(8, { message: "Le mot de passe doit contenir au moins 8 caractères" });

export const siretSchema = z
  .string({ required_error: "Le numéro SIRET est obligatoire" })
  // .length(17, {
  // 	message: 'Le numéro SIRET doit contenir 14 caractères',
  // })
  .min(14, {
    message: "Le numéro SIRET doit contenir au plus 14 caractèress",
  })
  .max(17, {
    message: "Le numéro SIRET doit contenir au plus 14 caractèress",
  });
// .transform((siret) => siret.replaceAll(' ', '')
export const tvaNumberSchema = z
  .string({
    required_error: "Le numéro de TVA est obligatoire",
  })
  .length(13, {
    message: "Le numéro de TVA doit contenir 13 caractères",
  });

export const emailSchema = z
  .string({ required_error: "L'adresse e-mail est obligatoire" })
  .email({ message: "Veuillez entrer une adresse email valide." });

export const firstNameSchema = z
  .string({
    required_error: "Le prénom est obligatoire",
  })
  .min(2, {
    message: "Le prénom doit contenir au moins 2 caractères",
  });

export const lastNameSchema = z
  .string({
    required_error: "Le nom est obligatoire",
  })
  .min(2, {
    message: "Le nom doit contenir au moins 2 caractères",
  });

export const phoneSchema = z
  .string({
    required_error: "Le numéro de téléphone est obligatoire",
  })
  .min(14, {
    message: "Le numéro de téléphone doit contenir au moins 14 caractères",
  })
  .max(18, {
    message: `Le numéro de téléphone doit contenir au plus 18 caractères`,
  });

export const RegisterIndividualCustomerSchema = z.object({
  firstname: firstNameSchema,
  lastname: lastNameSchema,

  address: z.string({
    required_error: "L'adresse est obligatoire",
  }),
  placeId: z.string({
    required_error: "L'adresse (placeId) est obligatoire",
  }),
  email: emailSchema,
  phone: phoneSchema,
  // phoneFormatted: phoneSchema.optional(),
  captcha: z
    .string({
      required_error: "Vous devez valider le captcha",
    })
    .optional(),
  customerType: z.literal("individual"),
});

export const RegisterProfessionalCustomerSchema =
  RegisterIndividualCustomerSchema.extend({
    siret: siretSchema,
    billingAddress: z
      .string({
        required_error: "L'adresse de facturation est obligatoire",
      })
      .min(8, {
        message: "L'adresse de facturation doit contenir au moins 8 caractères",
      }),
    billingPlaceId: z.string({
      required_error: "L'adresse de facturation (placeId) est obligatoire",
    }),
    tvaNumber: tvaNumberSchema.optional().nullable(),
    companyName: z.string({
      required_error: "Le nom de société est obligatoire",
    }),
    customerType: z.literal("professionnal"),
    billingEmail: emailSchema.optional().nullable(),
  });

export const RegisterCustomerSchema = z.discriminatedUnion("customerType", [
  RegisterIndividualCustomerSchema,
  RegisterProfessionalCustomerSchema,
]);

export const LeadRegisterProfessionalCustomerSchema =
  RegisterIndividualCustomerSchema.extend({
    siret: siretSchema.optional(),
    billingAddress: z
      .string({
        required_error: "L'adresse de facturation est obligatoire",
      })
      .min(8, {
        message: "L'adresse de facturation doit contenir au moins 8 caractères",
      })
      .optional(),
    billingPlaceId: z
      .string({
        required_error: "L'adresse de facturation (placeId) est obligatoire",
      })
      .optional(),
    tvaNumber: tvaNumberSchema.optional().nullable(),
    customerType: z.literal("professionnal"),
    companyName: z.string({
      required_error: "Le nom de société est obligatoire",
    }),
  });

export const GenerateIndividualQuoteSchema = z.object({
  startDate: z.string({
    required_error: "La date de début est obligatoire",
  }),
  endDate: z
    .string({
      required_error: "La date de fin est obligatoire",
    })
    .optional()
    .nullable(),
  providerId: z.string({
    required_error: "Le fournisseur est obligatoire",
  }),

  equipmentPriceRuleId: z.string({
    required_error: "La règle de prix est obligatoire",
  }),

  firstname: firstNameSchema,
  lastname: lastNameSchema,

  address: z.string({
    required_error: "L'adresse est obligatoire",
  }),
  placeId: z.string({
    required_error: "L'adresse (placeId) est obligatoire",
  }),
  email: emailSchema,
  phone: phoneSchema,

  customerType: z.literal("individual", {
    required_error: "Le type de client est obligatoire",
  }),
  action: z.literal("quote", {
    required_error: "L'action est obligatoire",
  }),
  billingAddress: z
    .string({
      required_error: "L'adresse de facturation est obligatoire",
    })
    .min(8, {
      message: "L'adresse de facturation doit contenir au moins 8 caractères",
    })
    .optional()
    .nullable(),
  billingPlaceId: z
    .string({
      required_error: "L'adresse de facturation (placeId) est obligatoire",
    })
    .optional()
    .nullable(),
});
export const GenerateProfessionalQuoteSchema =
  GenerateIndividualQuoteSchema.omit({
    customerType: true,
  }).extend({
    customerType: z.literal("professionnal", {
      required_error: "Le type de client est obligatoire",
    }),
    billingEmail: emailSchema.optional().nullable(),

    // ! Professional
    siret: siretSchema,

    tvaNumber: tvaNumberSchema.optional().nullable(),
    companyName: z
      .string({
        required_error: "Le nom de société est obligatoire",
      })
      .min(2, {
        message: "Le nom de société doit contenir au moins 2 caractères",
      }),
  });
export const GenerateLeadQuoteSchema = z.discriminatedUnion("customerType", [
  GenerateIndividualQuoteSchema,
  GenerateProfessionalQuoteSchema,
]);

export const ProfessionalCustomerQuoteSchema = z.object({
  // ! Professional
  siret: siretSchema,
  billingAddress: z
    .string({
      required_error: "L'adresse de facturation est obligatoire",
    })
    .min(8, {
      message: "L'adresse de facturation doit contenir au moins 8 caractères",
    }),
  billingPlaceId: z.string({
    required_error: "L'adresse de facturation (placeId) est obligatoire",
  }),
  tvaNumber: tvaNumberSchema,
  companyName: z
    .string({
      required_error: "Le nom de société est obligatoire",
    })
    .min(2, {
      message: "Le nom de société doit contenir au moins 2 caractères",
    }),
});

export const ForgotPasswordSchema = z.object({
  email: emailSchema,
});

export const PasswordResetSchema = z.object({
  password: passwordSchema,
  passwordConfirmation: passwordSchema,
  resetPasswordToken: z.string({
    required_error:
      "Le token de réinitialisation de votre mot de passe est requis.",
  }),
});
