import { z } from "zod";
import {
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneSchema,
  siretSchema,
  tvaNumberSchema,
} from "../user.schema";

export const RenewRecurringBookingSchema = z.object({
  startDate: z.string({
    required_error: "Veuillez sélectionner une date de début de prestation",
  }),
  purchaseOrder: z
    .string({
      required_error: "Le bon de commande est requis",
    })
    .optional(),
});

export const WithdrawRecurringBookingSchema = z.object({
  endDate: z.string({
    required_error: "Veuillez sélectionner une date de fin de prestation",
  }),
});

export const BookingPreFormSchema = z.object({
  phone: phoneSchema,
  comment: z
    .string({
      required_error: "Merci de préciser toute information utile",
    })
    .optional(),
  firstname: firstNameSchema,
  lastname: lastNameSchema,
  purchaseOrder: z
    .string({ required_error: "Le bon de commande est requis" })
    .optional(),
});

export const CheckoutCustomerAction = z.object({
  action: z.enum(["login", "register"]),
});

export const EditCustomerBookingValidateSchema = z.object({
  action: z.enum([
    "close",
    "message",
    "transform-to-recurring",
    "pay-down-payment",
  ]),
});

export const CreateIndividualCustomerSchema = z.object({
  firstname: firstNameSchema,
  lastname: lastNameSchema,

  email: emailSchema,
  phone: phoneSchema,

  customerType: z.literal("individual", {
    required_error: "Le type de client est obligatoire",
  }),
});

export const CreateProfessionalCustomerSchema =
  CreateIndividualCustomerSchema.extend({
    customerType: z.literal("professionnal", {
      required_error: "Le type de client est obligatoire",
    }),
    billingEmail: emailSchema.optional().nullable(),
    siret: siretSchema,
    companyName: z.string({
      required_error: "Le nom de société est obligatoire",
    }),
    tvaNumber: tvaNumberSchema.optional().nullable(),
  });

export const CreateCustomerSchema = z.discriminatedUnion("customerType", [
  CreateIndividualCustomerSchema,
  CreateProfessionalCustomerSchema,
]);

export const GenerateBookingLoggedInSchema = z.object({
  startDate: z.string({
    required_error: "La date de début est obligatoire",
  }),
  endDate: z
    .string({
      required_error: "La date de fin est obligatoire",
    })
    .optional()
    .nullable(),
  providerId: z.string({
    required_error: "Le fournisseur est obligatoire",
  }),

  equipmentPriceRuleId: z.string({
    required_error: "La règle de prix est obligatoire",
  }),

  comment: z
    .string({
      required_error: "Merci de préciser toute information utile",
    })
    .optional(),
  purchaseOrder: z
    .string({ required_error: "Le bon de commande est requis" })
    .optional(),
  hasAcceptedTerms: z
    .boolean({
      required_error: "Veuillez accepter les conditions générales de vente",
    })
    .default(false),

  contactPhone: phoneSchema,
  contactFirstName: firstNameSchema,
  contactLastName: lastNameSchema,

  address: z.string({
    required_error: "L'adresse est obligatoire",
  }),
  placeId: z.string({
    required_error: "L'adresse (placeId) est obligatoire",
  }),

  billingAddress: z
    .string({
      required_error: "L'adresse de facturation est obligatoire",
    })
    .min(8, {
      message: "L'adresse de facturation doit contenir au moins 8 caractères",
    }),
  billingPlaceId: z.string({
    required_error: "L'adresse de facturation (placeId) est obligatoire",
  }),

  action: z.literal("booking", {
    required_error: "Action is necessary to book",
  }),
  status: z.literal("logged-in", {
    required_error: "Status is necessary",
  }),
  automaticCharge: z.boolean().default(false),
});

export const GenerateBookingLoggedOutSchema =
  GenerateBookingLoggedInSchema.extend({
    customer: CreateCustomerSchema,
    status: z.literal("logged-out", {
      required_error: "Status is necessary",
    }),
  });
export const GenerateBookingSchema = z.discriminatedUnion("status", [
  GenerateBookingLoggedOutSchema,
  GenerateBookingLoggedInSchema,
]);
