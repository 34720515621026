import { CheckboxField } from "components/Interface/Forms/forms";

export const SideFilterCheckbox = ({
  isDisabled = false,
  checked,
  optionName,
  optionGcId,
  optionLabel,
  toggleOption,
}: {
  isDisabled?: boolean;
  checked: boolean;
  optionGcId: number | string;
  optionName: string;
  optionLabel?: string;
  toggleOption: (valueId: number | string) => void;
}) => {
  return (
    <CheckboxField
      key={`${optionName}-${optionGcId}${checked ? "-checked" : "-unchecked"}`}
      buttonProps={{
        form: "side-filters",
        id: `${optionName}-${optionGcId}`,
        name: `${optionName}-${optionGcId}`,
        defaultChecked: checked,
        onCheckedChange: () => {
          toggleOption(optionGcId);
        },
        disabled: isDisabled,
        value: optionGcId.toString(),
      }}
      labelProps={{
        children: (
          <div className="text-sm flex items-center gap-1 whitespace-nowrap">
            <span>{optionName}</span>
            {optionLabel ? (
              <span className="text-xs text-gray-500">{optionLabel}</span>
            ) : null}
          </div>
        ),
        htmlFor: `${optionName}-${optionGcId}`,
        // onClick: () => {
        //     toggleOption(optionGcId);
        // }
      }}
    />
  );
};
