import { useWasteTypes } from "app/root";
import { Truck } from "components/Icons/Truck";
import { Where } from "components/Icons/Where";
import { GeneralCombobox } from "components/Interface/Forms/Inputs/GeneralCombobox";
import { HiddenInputsArray } from "components/Interface/Forms/Inputs/HiddenInputsArray";
import { OptimizedImage } from "components/OptimizedImage";
import { Headline } from "components/UI/Headline";
import { Label } from "components/UI/Label";
import { Paragraph } from "components/UI/Paragraph";
import { Rating } from "components/UI/Rating";
import { SwitchBar } from "components/UI/SwitchBar";
import { ClintButton } from "components/shadcn-ui/button";
import { useMarketplaceSearchBar } from "hooks/useMarketplaceSearchBar";
import { Search } from "iconoir-react";
import React from "react";
import { Form, useNavigation } from "react-router";
import { AddressCombobox } from "routes/resources+/get-address";
import type { getLandingPageData } from "server/strapi.server";
import { useMediaQuery } from "usehooks-ts";
import { cn } from "utils/utils";

export const LogoGoogle = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.56 12.25C22.56 11.47 22.49 10.72 22.36 10H12V14.26H17.92C17.66 15.63 16.88 16.79 15.71 17.57V20.34H19.28C21.36 18.42 22.56 15.6 22.56 12.25Z"
      fill="#4285F4"
    />
    <path
      d="M11.9997 23.0016C14.9697 23.0016 17.4597 22.0216 19.2797 20.3416L15.7097 17.5716C14.7297 18.2316 13.4797 18.6316 11.9997 18.6316C9.13969 18.6316 6.70969 16.7016 5.83969 14.1016H2.17969V16.9416C3.98969 20.5316 7.69969 23.0016 11.9997 23.0016Z"
      fill="#34A853"
    />
    <path
      d="M5.84 14.0903C5.62 13.4303 5.49 12.7303 5.49 12.0003C5.49 11.2703 5.62 10.5703 5.84 9.91031V7.07031H2.18C1.43 8.55031 1 10.2203 1 12.0003C1 13.7803 1.43 15.4503 2.18 16.9303L5.03 14.7103L5.84 14.0903Z"
      fill="#FBBC05"
    />
    <path
      d="M11.9997 5.38C13.6197 5.38 15.0597 5.94 16.2097 7.02L19.3597 3.87C17.4497 2.09 14.9697 1 11.9997 1C7.69969 1 3.98969 3.47 2.17969 7.07L5.83969 9.91C6.70969 7.31 9.13969 5.38 11.9997 5.38Z"
      fill="#EA4335"
    />
  </svg>
);

export const HeroSection = ({
  title,
  description,
  brands,
  className,
}: {
  description: string;
  title: React.ReactNode;
  brands: Awaited<ReturnType<typeof getLandingPageData>>["brands"];
  className?: string;
}) => {
  const wasteTypes = useWasteTypes();
  const isSmallMedia = useMediaQuery("(max-width: 640px)");

  const { selectedFilters, setSelectedFilters } = useMarketplaceSearchBar();

  const canSearch =
    Boolean(selectedFilters.address) &&
    Boolean(selectedFilters.placeId) &&
    (selectedFilters.isProfessional === "1"
      ? Boolean(selectedFilters.waste)
      : true);

  const isLoading = useNavigation().state !== "idle";
  return (
    <div
      className={cn(
        "rounded-clint w-full lg:w-2 flex flex-1 flex-col gap-4 lg:gap-6 bg-white p-0 lg:p-[2.125rem]",
        className,
      )}
    >
      <div id="heading" className="flex flex-col gap-3 lg:pb-[18px]">
        <div className="flex items-center gap-2">
          <Rating percent={(4.9 / 5) * 100} />
          <Label size={"S"} className="text-gray-600">
            4.95/5
          </Label>
          {LogoGoogle}
        </div>
        <div className="flex flex-col gap-3 lg:gap-5">
          <Headline
            size={isSmallMedia ? "h2" : "h1"}
            as="h1"
            className="lg-custom:text-[6vh] lg-custom:leading-[7.5vh]"
          >
            {title}
          </Headline>

          <Label as="p" size={"M"} className="max-w-[519px]">
            {description}
          </Label>
        </div>
      </div>
      <div id="search" className="flex flex-col gap-3.5">
        <SwitchBar
          options={[
            { name: "Professionnel", value: "1" },
            { name: "Particulier", value: "0" },
          ]}
          defaultOption={selectedFilters.isProfessional}
          onSwitch={(newValue) => {
            setSelectedFilters({
              isProfessional: newValue === "1" ? "1" : "0",
            });
          }}
        />
        <div className="relative z-20 flex w-full flex-col gap-3.5">
          <AddressCombobox
            inputName="address"
            placeIdInputName="placeId"
            defaultAddressValue={selectedFilters.address}
            defaultPlaceIdValue={selectedFilters.placeId}
            onAddressChange={(newAddress) => {
              setSelectedFilters({
                address: newAddress.address,
                placeId: newAddress.placeId,
              });
            }}
            debounceMs={150}
            inputProps={{
              placeholder: "Votre adresse",
              className: "h-full",
            }}
            labelProps={{
              children: null,
            }}
            icon={<Where className="size-6" aria-hidden="true" />}
          />
          {selectedFilters.isProfessional === "1" ? (
            <GeneralCombobox
              type="single"
              icon={
                <Truck
                  aria-hidden="true"
                  className="text-gray-600 size-6 shrink-0"
                />
              }
              className="w-full "
              options={wasteTypes.map((o) => ({
                id: o.gcId.toString(),
                name: o.name,
                disabled: false,
                imageUrl: "",
                showImage: false,
              }))}
              placeholder="Typologie de déchet"
              selectedOptionId={selectedFilters.waste?.toString()}
              onChange={(event) => {
                setSelectedFilters({
                  waste: Number(event),
                });
              }}
            />
          ) : (
            <Paragraph
              size="md"
              variant="secondary"
              className="min-h-9 mb-0 pb-0 overflow-hidden self-center text-gray-800"
            >
              Goodcollect vous accompagne pour trouver la meilleure solution de
              recyclage.
            </Paragraph>
          )}
          <Form method="GET" action="/solutions/rechercher" className="w-full">
            <HiddenInputsArray
              inputs={[
                {
                  name: "service",
                  value: selectedFilters.service?.toString(),
                },

                {
                  name: "address",
                  value: selectedFilters.address,
                },
                {
                  name: "placeId",
                  value: selectedFilters.placeId,
                },
                {
                  name: "waste",
                  value: selectedFilters.waste?.toString(),
                },
                {
                  name: "isProfessional",
                  value: selectedFilters.isProfessional,
                },
                {
                  name: "step",
                  value: selectedFilters.isProfessional === "1" ? "2" : "1",
                },
              ]}
            />

            <ClintButton
              as="Button"
              type="submit"
              variant="green"
              Icon={Search}
              textAlign="text-left"
              className={`w-full ${selectedFilters.isProfessional === "1" ? "mb-0" : "mb-3"}`}
              disabled={!canSearch}
              isLoading={isLoading}
              labelSize="M"
            >
              {selectedFilters.isProfessional === "1"
                ? "Rechercher"
                : "Trouver ma solution"}
            </ClintButton>
          </Form>
        </div>
      </div>
      {brands.length > 0 ? (
        <div
          id="logos"
          className="relative w-full overflow-hidden flex flex-col"
        >
          <Label size={"S"} as="p" className="mb-3.5 text-center text-gray-500">
            Ils nous font confiance
          </Label>
          <LogoBanner brands={brands} />
        </div>
      ) : null}
    </div>
  );
};

export const LogoBanner = ({
  brands,
  colorGradient = "white",
}: {
  brands: Awaited<ReturnType<typeof getLandingPageData>>["brands"];
  colorGradient?: "white" | "grey";
}) => {
  return (
    <React.Fragment>
      <div
        className={`${colorGradient === "white" ? "gradientLogos" : "gradientLogosGrey"} absolute z-10 h-full w-full`}
      ></div>
      <div className="animate-slide-left flex h-full w-max items-center gap-10">
        {brands.map((brand) => (
          <OptimizedImage
            maxHeight={58}
            loading="eager"
            containerClassName="logo-filter"
            className="relative h-[58px] w-auto filter"
            key={brand.id}
            source={brand.logo.url}
            alt={brand.logo.alternativeText}
            width={brand.logo.width}
            height={brand.logo.height}
          />
        ))}
        {brands.map((brand) => (
          <OptimizedImage
            maxHeight={58}
            loading="eager"
            containerClassName="logo-filter"
            className="relative h-[58px] w-auto filter"
            key={`second-${brand.id}`}
            source={brand.logo.url}
            alt={brand.logo.alternativeText}
            width={brand.logo.width}
            height={brand.logo.height}
          />
        ))}
      </div>
    </React.Fragment>
  );
};
